import { produce } from 'immer';
import api from 'api';
import templateStates from 'shared/constants/contractTemplateStates';
import { reportingFormStates } from 'shared/ReportingForms/constants/reportingFormStates';
import { sortBy } from 'shared/utils/sortUtils';
import { reportingFormResourceTypes } from 'shared/ReportingForms/constants/reportingFormResourceTypes';
import { signatureTypes } from 'shared/constants/signatureTypes';

/**
 * Lomakkeet.
 */
export const reportingForms = {
    state: {
        template: {},
        templates: [],
        reportingForms: [],
        openReportingForms: [],
        reportingForm: {
            data: {},
            presetData: {},
        },
        reportingFormGroup: null,
        searchReportingForms: [],
        isSummaryView: false,
    },

    reducers: {
        setReportingFormTemplate: (state, template) => ({ ...state, template }),
        setReportingFormTemplates: (state, templates) => ({ ...state, templates }),
        setReportingForms: (state, reportingForms) => ({ ...state, reportingForms }),
        setSearchReportingForms: (state, searchReportingForms) => ({ ...state, searchReportingForms }),
        setReportingForm: (state, reportingForm) => produce(state, (draftState) => {
            draftState.reportingForm = Object.assign({}, reportingForm, {
                data: reportingForm?.decryptedData,
                hasSignatures: reportingForm.template?.presetData?.hasSignatures ?? false,
            });
            const index = draftState.reportingForms.findIndex((rf) => rf.id === reportingForm.id);

            // päivitetään/lisätään myös listale niin ei tarvi päivitellä erikseen koko listaa
            if (index === -1) {
                draftState.reportingForms.push(reportingForm);
            } else {
                draftState.reportingForms[index] = reportingForm;
            }
        }),
        setReportingFormState: (state, reportingFormState) => produce(state, (draftState) => {
            draftState.reportingForm.state = reportingFormState;
        }),
        setSignatures: (state, signatures) => produce(state, (draftState) => {
            draftState.reportingForm.signatures = signatures;
        }),
        clearReportingForm: (state) => ({ ...state, reportingForm: {} }),
        setReportingFormGroup: (state, reportingFormGroup) => ({ ...state, reportingFormGroup }),

        /**
         * Ollaanko yhteenvetonäkymässä.
         */
        setIsSummaryView: (state, isSummaryView) => ({ ...state, isSummaryView }),
    },

    selectors: {
        getReportingFormTemplate: (state) => state?.template ?? {},
        getReportingFormTemplates: (state) => state?.templates ?? [],
        getReportingForms: (state) => state.reportingForms ?? [],
        getSearchReportingForms: (state) => state.searchReportingForms ?? [],
        getReportingForm: (state) => state.reportingForm ?? {},
        getReportingFormId: (state) => state.reportingForm?.id,
        getReportingFormTemplateId: (state) => state.reportingForm?.template.id,
        getReportingFormGroup: (state) => state.reportingFormGroup ?? null,

        isDraftReportingForm: (state) => state.reportingForm?.state === reportingFormStates.DRAFT,
        isOpenReportingForm: (state) => state.reportingForm?.state === reportingFormStates.OPEN,
        isProposalReportingForm: (state) => state.reportingForm?.state === reportingFormStates.PROPOSAL,
        isConfirmedReportingForm: (state) => state.reportingForm?.state === reportingFormStates.CONFIRMED,
        isInvalidatedReportingForm: (state) => state.reportingForm?.state === reportingFormStates.INVALIDATED,

        isContractTypeReportingForm: (state) => state.reportingForm?.type === reportingFormResourceTypes.RELATION_CONTRACT,
        isUserTypeReportingForm: (state) => state.reportingForm?.type === reportingFormResourceTypes.RELATION_USER,
        hasSignatures: (state) => state.template?.presetData?.hasSignatures ?? false,

        getEmployeesNotUsingOima: (state) => state.reportingForm?.decryptedData?.isEmployeeNotUsingOima ?? [],
        getSignatures: (state) => state.reportingForm?.signatures ?? [],
        getEmployeeSignatures: (state) => (state.reportingForm?.signatures ?? []).filter(({ type }) => type === signatureTypes.TYPE_USER_SPECIFIC),
        hasEmployerSigned: (state) => (state.reportingForm?.signatures ?? [])
            .find((signature) => signature?.type === signatureTypes.TYPE_EMPLOYER_SPECIFIC && signature?.isSigned),
        hasCommuneUserSigned: (state) => (state.reportingForm?.signatures ?? [])
            .find((signature) => signature?.type === signatureTypes.TYPE_COMMUNE_USER_SPECIFIC && signature?.isSigned),
        hasCommuneUserSignature: (state) => (state.reportingForm?.signatures ?? [])
            .find((signature) => signature?.type === signatureTypes.TYPE_COMMUNE_USER_SPECIFIC),

        canUnconfirm: (state) => state.template?.canUnconfirm ?? false,
        canEmployeeFillForm: (state) => state.template?.canEmployeeFillForm ?? false,
        canEmployerFillForm: (state) => state.template?.canEmployerFillForm ?? false,

        // Ollaanko rakentelumoodissa.
        isEditMode: (state) => ! state.isSummaryView,
        isSummaryView: (state) => state.isSummaryView,
    },

    effects: (dispatch) => ({
        async fetchReportingForms({ resourceType, resourceId }) {
            try {
                const response = await api.get(`/api/v3/reporting-forms?resourceType=${resourceType}&resourceId=${resourceId}`);
                if (response.status === 'ok') {
                    this.setReportingForms(response?.data);
                } else {
                    dispatch.notifications.addError(_trans('Lomakkeita ei voitu hakea.', {}, 'reporting_forms'));
                }
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomakkeita ei voitu hakea.', {}, 'reporting_forms'));
            }
        },

        async fetchSearchReportingForms({ state }) {
            try {
                const response = await api.get(`/api/v3/reporting-forms/search?state=${state}`);
                if (response.status === 'ok') {
                    this.setSearchReportingForms(response?.data);
                } else {
                    dispatch.notifications.addError(_trans('Lomakkeita ei voitu hakea.', {}, 'reporting_forms'));
                }
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomakkeita ei voitu hakea.', {}, 'reporting_forms'));
            }
        },

        async fetchReportingFormTemplates({ subType, resourceId, resourceType } = {}) {
            try {
                const response = await api.get(`/api/v3/reporting-form/templates?state=${templateStates.STATE_ACTIVE}&subType=${subType}&resourceId=${resourceId}&resourceType=${resourceType}`);
                this.setReportingFormTemplates(sortBy(response.data ?? [], 'name'));
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomakepohjia ei voitu hakea.', {}, 'reporting_forms'));
            }
        },

        async fetchReportingForm(reportingFormId) {
            try {
                const response = await api.get(`/api/v3/reporting-form/${reportingFormId}`);
                if (response?.status === 'ok') {
                    this.setReportingForm(response?.data);
                }
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu hakea.', {}, 'reporting_forms'));
            }
        },

        async postReportingForm(values) {
            try {
                return await api.post(`/api/v3/reporting-form`, values);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu tallentaa.', {}, 'reporting_forms'));
            }
        },

        async putReportingForm({ reportingFormId, values }) {
            try {
                return await api.put(`/api/v3/reporting-form/${reportingFormId}`, values);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu päivittää.', {}, 'reporting_forms'));
            }
        },

        async confirmReportingForm(reportingFormId) {
            try {
                return await api.post(`/api/v3/reporting-form/${reportingFormId}/confirm`);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu vahvistaa.', {}, 'reporting_forms'));
            }
        },

        async archiveReportingForm(reportingFormId) {
            try {
                return await api.post(`/api/v3/reporting-form/${reportingFormId}/archive`);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(e?.message ?? _trans('Lomaketta ei voitu poistaa tuntemattomasta syystä.', {}, 'reporting_forms'));
            }
        },

        async proposeReportingForm(reportingFormId) {
            try {
                return await api.post(`/api/v3/reporting-form/${reportingFormId}/propose`);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu hyväksyä allekirjoitettavaksi.', {}, 'reporting_forms'));
            }
        },

        async unconfirmReportingForm(reportingFormId) {
            try {
                return await api.post(`/api/v3/reporting-form/${reportingFormId}/unconfirm`);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomakeen vahvistusta ei voitu perua.', {}, 'reporting_forms'));
            }
        },

        async deleteReportingForm(reportingFormId) {
            try {
                return await api.del(`/api/v3/reporting-form/${reportingFormId}`);
            } catch (e) {
                console.error(e);
                dispatch.notifications.addError(_trans('Lomaketta ei voitu poistaa.', {}, 'reporting_forms'));
            }
        },

        /**
         * Lomakkeen allekirjoittaminen.
         * @param reportingFormId
         * @param isSigningOnBehalf -
         *  false: TA osapuoli tai sähköinen TT
         *  true:  Hyvinvointialue allekirjoittaa TT:n tai sijais-TA:n puolesta (paperilla)
         * @param signatureType
         * @param signerId
         * @returns {Promise<void>}
         */
        async postSignature({ reportingFormId, isSigningOnBehalf = false, signatureType = signatureTypes.TYPE_USER_SPECIFIC, signerId }) {
            try {
                const response = await api.post(isSigningOnBehalf
                    ? `/api/v3/reporting-forms/${reportingFormId}/sign-behalf/${signatureType}?signerId=${signerId}`
                    : `/api/v3/reporting-forms/${reportingFormId}/sign`
                );
                if (response.status === 'ok') {
                    const data = response.data ?? {};
                    if (data?.isFullySigned) {
                        dispatch.notifications.addSuccess(_trans('Lomake on allekirjoitettu ja voimassa.', {}, 'reporting_forms'));
                        this.setReportingFormState(data?.state);
                    }
                    this.setSignatures(data?.signatures ?? []);
                } else {
                    dispatch.notifications.addError(response?.message ?? _trans('Lomaketta ei voitu allekirjoittaa.', {}, 'reporting_forms'));
                }
                return response;
            } catch (e) {
                console.log(e);
            }
        },
    })
};
