export const taxCardTypes = {
    TYPE_A: 1,
    TYPE_B: 2,
    EXTRA_INCOME_TYPE_A: 3, // Sivutuloverokortti (perus- ja lisäprosentti)
    EXTRA_INCOME_TYPE_B: 31, // Sivutuloverokortti (lisäprosentti)
    NO_TAX_CARD: 4,
    QUICK_PAYMENT: 5,
    THREE_STEP_PROGRESSIVE: 6,
    TAX_AT_SOURCE: 7,
    FREELANCE: 8,
    /**
     * Työkorvaus-verokortti. Käytössä perhehoidossa
     */
    COMPENSATION_TAX_CARD_FAMILY_CARE: 1000,

    COMPENSATION_NO_TAX_CARD_FAMILY_CARE: 1004,

    /**
     * Työkorvaus-verokortti. Käytössä omaishoidossa
     */
    COMPENSATION_TAX_CARD_RELATIVE_CARE: 1100,

    COMPENSATION_NO_TAX_CARD_RELATIVE_CARE: 1104,

    // Palkkiosopimuksen verokortti
    COMPENSATION_TAX_CARD_PERSONAL: 1200,

    COMPENSATION_NO_TAX_CARD_PERSONAL: 1204,
};

const taxCardTypeMap = {
    [taxCardTypes.TYPE_A]: _trans('tax_card.types.type_a'),
    [taxCardTypes.TYPE_B]: _trans('tax_card.types.type_b.label'),
    [taxCardTypes.EXTRA_INCOME_TYPE_A]: _trans('tax_card.types.extra_income_type_a'),
    [taxCardTypes.EXTRA_INCOME_TYPE_B]: _trans('tax_card.types.extra_income_type_b'),
    [taxCardTypes.NO_TAX_CARD]: _trans('tax_card.types.no_tax_card.label'),
    [taxCardTypes.COMPENSATION_NO_TAX_CARD_FAMILY_CARE]: _trans('tax_card.types.no_tax_card.label'),
    [taxCardTypes.COMPENSATION_NO_TAX_CARD_RELATIVE_CARE]: _trans('tax_card.types.no_tax_card.label'),
    [taxCardTypes.COMPENSATION_NO_TAX_CARD_PERSONAL]: _trans('tax_card.types.no_tax_card.label'),
    [taxCardTypes.QUICK_PAYMENT]: _trans('tax_card.types.noted_tax'),
    [taxCardTypes.THREE_STEP_PROGRESSIVE]: _trans('tax_card.types.three_step_progressive'),
    [taxCardTypes.TAX_AT_SOURCE]: _trans('tax_card.types.tax_at_source.label'),
    [taxCardTypes.FREELANCE]: _trans('tax_card.types.freelance'),
    [taxCardTypes.COMPENSATION_TAX_CARD_FAMILY_CARE]: _trans('Perhehoidon työkorvaus-verokortti', {}, 'extract'),
    [taxCardTypes.COMPENSATION_TAX_CARD_RELATIVE_CARE]: _trans('Omaishoidon työkorvaus-verokortti', {}, 'extract'),
    [taxCardTypes.COMPENSATION_TAX_CARD_PERSONAL]: _trans('Työkorvaus-verokortti', {}, 'extract'),
};

export const validationTaxCardTypes = [
    taxCardTypes.TYPE_B,
    taxCardTypes.COMPENSATION_TAX_CARD_FAMILY_CARE,
    taxCardTypes.COMPENSATION_TAX_CARD_RELATIVE_CARE,
    taxCardTypes.COMPENSATION_TAX_CARD_PERSONAL
];

export const noTaxCardTypes = [
    taxCardTypes.NO_TAX_CARD,
    taxCardTypes.COMPENSATION_NO_TAX_CARD_FAMILY_CARE,
    taxCardTypes.COMPENSATION_NO_TAX_CARD_RELATIVE_CARE,
    taxCardTypes.COMPENSATION_NO_TAX_CARD_PERSONAL
];

export function translateTaxCardType(taxCardType) {
    return taxCardType in taxCardTypeMap ? taxCardTypeMap[taxCardType] : '';
}

export function translateTaxCardBasicType(taxCardType) {
    if (taxCardType === taxCardTypes.TYPE_B) {
        return _trans('Palkkojen verokortti', {}, 'tax_card');
    }
    return taxCardType in taxCardTypeMap ? taxCardTypeMap[taxCardType] : '';
}


