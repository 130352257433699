import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { ActionBar, Button, Dialog, Feedback, MDSpinner } from 'shared/components';
import { convertToRangeObjects } from 'shared/utils/commonUtils';
import validators, { errorMessages } from 'shared/validators';
import { CheckboxField, DateInputField, Form, FormField } from 'shared/components/Formik';
import ForeclosurePayrollsFeedback from 'PaymentsCommune/components/ForeclosurePayrollsFeedback';

const PAYDAY_ASAP = 'payday_asap';
const PAYDAY_TOMORROW = 'payday_tomorrow';

// yritys yhdenmukaistaa ainaki osa palkkapäivän muutosdialogista kun alko olemaan 4 erilaista jo
const UpdatePayDayFrom = ({
    payDayData,
    buttonText,
    isAdmin,
    isOPH,
    isForeclosureFeedbackVisible,
    isLoading,
    isDisabled,
    getPaydayOptions,
    startPollingJobs,
    onSubmit,
    setASAPModeASAP,
    setASAPModeTomorrow,
    isExternalEditMode, // nämä kaks viimestä on täälä vain tunnitnäkymän takia, jos sinne keksii jotain parempaa niin
    setExternalEditMode, // voi nämäistä sitte hankkiutua eroon
    isPaymentOpen,
    foreclosurePayrolls,
}) => {
    const [isEditMode, setIsEditMode] = useState(isExternalEditMode);
    const [paydays, defaultPayday, globalDefaultPaydays, closestNonASAPPayday, asapDates] = payDayData;

    if (! isEditMode) {
        return (
            <Button
                disabled={isDisabled}
                size="small"
                modifierClass="u-margin-top-tiny"
                primary
                onClick={() => setIsEditMode(true)}
            >
                {buttonText}
            </Button>
        );
    }

    if (isLoading) {
        return <MDSpinner modifierClass="u-margin-top-small" wrap={false} />;
    }

    const payDayOptions = getPaydayOptions();
    const { missingRange } = convertToRangeObjects(paydays);

    return (
        <Dialog
            title={
                isOPH
                    ? _trans('Muokkaa palkkionmaksupäivää', {}, 'time_registration')
                    : _trans('Muokkaa palkanmaksupäivää', {}, 'time_registration')
            }
            initialFocus="#payday"
            isOpen={isEditMode}
            onClose={() => {
                setIsEditMode(false);
                setExternalEditMode(false);
            }}
            canUnderlayClickExit={true}
            maxWidth="large"
        >
            <Formik
                initialValues={{ payday: moment(defaultPayday).format('YYYY-MM-DD') }}
                onSubmit={(model) => {
                    onSubmit(model);
                    startPollingJobs();
                    setIsEditMode(false);
                    setExternalEditMode(false);
                }}
                validationSchema={Yup.object().shape({
                    payday: Yup.string().required(_trans('validation.required'))
                        .test('isDate', errorMessages.isDate, validators.isDate)
                        .test('isGTEDate', errorMessages.isGTDate, (value) => new Date(value) >= (new Date()).setHours(0, 0, 0, 0))
                })}
            >
                {({ handleSubmit, setFieldValue, values, isValid }) => (
                    <Form alignFields="vertical">
                        <span className="o-stack o-stack--justify o-stack--vertical o-stack--top">
                            <label htmlFor="default_payday">
                                {
                                    isOPH
                                        ? _trans('Seuraava valittava palkkionmaksupäivä', {}, 'time_registration')
                                        : _trans('Seuraava valittava palkanmaksupäivä', {}, 'time_registration')
                                }
                            </label>
                            <Button
                                id="default_payday"
                                primary
                                onClick={() => {
                                    setFieldValue('payday', moment(defaultPayday).format('YYYY-MM-DD'));
                                    handleSubmit();
                                }}
                            >
                                {moment(defaultPayday).format('DD.MM.YYYY')}
                            </Button>
                            {globalDefaultPaydays?.length > 0 && (
                                <>
                                    <label className="u-margin-top-small">
                                        {
                                            isOPH
                                                ? _trans('Hyvinvointialueen määrittämät yleiset palkkionmaksupäivät', {}, 'time_registration')
                                                : _trans('Hyvinvointialueen määrittämät yleiset palkanmaksupäivät', {}, 'time_registration')
                                        }
                                    </label>
                                    <span className="o-stack o-stack--justify">
                                        { (globalDefaultPaydays ?? []).map((paydayValue, index) => {
                                            const options = getPaydayOptions().sort();
                                            const dayIndex = options.findIndex((value) => moment(value.value).isSame(moment(paydayValue), 'day'));
                                            // vakiopäivissä jos päivää ei löydy valittavien listalta ei sitä vaan tarjota ollenkaan
                                            if (dayIndex === -1) {
                                                return null;
                                            }
                                            const selectedDay = options[dayIndex]?.value ?? options[0]?.value ?? moment();

                                            return (
                                                <span key={index}>
                                                    <Button
                                                        primary
                                                        modifierClass="u-margin-right-tiny"
                                                        onClick={() => {
                                                            setFieldValue('payday', moment(selectedDay).format('YYYY-MM-DD'));
                                                            handleSubmit();
                                                        }}
                                                    >
                                                        {moment(selectedDay).format('DD.MM.YYYY')}
                                                    </Button>
                                                </span>
                                            );
                                        })}
                                    </span>
                                </>
                            )}
                            <label className="u-margin-top-small">
                                {
                                    isOPH
                                        ? _trans('Aikaistetut palkkionmaksupäivät', {}, 'time_registration')
                                        : _trans('Aikaistetut palkanmaksupäivät', {}, 'time_registration')
                                }
                            </label>
                            { isAdmin && (
                                <CheckboxField modifierClass="u-color-admin" name="skip_surcharge" label={_trans('Ei lisämaksua (Admin only)', {}, 'time_registration')}/>
                            )}
                            <Button
                                outline
                                onClick={() => {
                                    setFieldValue('payday', moment(closestNonASAPPayday).format('YYYY-MM-DD'), false);
                                    handleSubmit();
                                }}
                            >
                                {moment(closestNonASAPPayday).format('DD.MM.YYYY')}
                            </Button>
                            <span className="o-stack o-stack--justify u-margin-top-small">
                                <Button
                                    outline
                                    modifierClass="u-margin-right-tiny"
                                    onClick={() => {
                                        setFieldValue('payday', moment(asapDates[PAYDAY_ASAP]).format('YYYY-MM-DD'));
                                        setASAPModeASAP(moment(asapDates[PAYDAY_ASAP]).format('YYYY-MM-DD'), PAYDAY_ASAP, values.skip_surcharge);
                                        setIsEditMode(false);
                                        setExternalEditMode(false);
                                    }}
                                >
                                    {_trans('Mahdollisimman pian', {}, 'time_registration')}
                                </Button>
                                <Button
                                    outline
                                    modifierClass="u-margin-right-tiny"
                                    onClick={() => {
                                        setFieldValue('payday', moment(asapDates[PAYDAY_TOMORROW]).format('YYYY-MM-DD'));
                                        setASAPModeTomorrow(moment(asapDates[PAYDAY_TOMORROW]).format('YYYY-MM-DD'), PAYDAY_TOMORROW, values.skip_surcharge);
                                        setIsEditMode(false);
                                        setExternalEditMode(false);
                                    }}
                                >
                                    {moment(asapDates[PAYDAY_TOMORROW]).format('DD.MM.YYYY')}
                                </Button>
                            </span>
                        </span>
                        <FormField
                            label={_trans('Itse valittu päivämäärä', {}, 'time_registration')}
                            name="payday"
                        >
                            <DateInputField
                                minimumDate={new Date(paydays[0])}
                                maximumDate={new Date(payDayOptions[payDayOptions?.length - 1]?.value)}
                                dateRanges={missingRange}
                                modifierClass="u-margin-top-small"
                            />
                        </FormField>
                        <div>
                            <Feedback type={isValid ? 'info' : 'error'}>
                                {_trans('Valittu maksupäivä')}: {moment(values?.payday ?? defaultPayday ?? moment()).format('DD.MM.YYYY') ?? _trans('Tuntematon', 'time_registration')}
                            </Feedback>
                        </div>
                        {isForeclosureFeedbackVisible && <ForeclosurePayrollsFeedback
                            foreclosurePayrolls={foreclosurePayrolls?.data}
                            newDueDate={moment(values?.payday ?? defaultPayday ?? moment())}
                            isPaymentOpen={isPaymentOpen}
                            defaultPaydayString={defaultPayday}
                            isOph={isOPH}
                        />}
                        <ActionBar modifierClass="u-margin-top-small" alignItems="justify">
                            <Button
                                onClick={() => {
                                    setIsEditMode(false);
                                    setExternalEditMode(false);
                                }}
                                flat
                            >
                                {_trans('button.cancel')}
                            </Button>
                            <Button type="submit" primary>
                                {_trans('button.save')}
                            </Button>
                        </ActionBar>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

UpdatePayDayFrom.propTypes = {
    //payDayData: PropTypes.array.isRequired,
    payDayData: PropTypes.shape([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
        PropTypes.object,
    ]).isRequired,
    buttonText: PropTypes.string,
    isAdmin: PropTypes.bool,
    isOPH: PropTypes.bool,
    isForeclosureFeedbackVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    getPaydayOptions: PropTypes.func,
    startPollingJobs: PropTypes.func,
    onSubmit: PropTypes.func,
    setASAPModeASAP: PropTypes.func,
    setASAPModeTomorrow: PropTypes.func,
    isExternalEditMode: PropTypes.bool,
    setExternalEditMode: PropTypes.func,
    isPaymentOpen: PropTypes.bool,
    foreclosurePayrolls: PropTypes.object,
};

UpdatePayDayFrom.defaultProps = {
    buttonText: _trans('payments.details.change_pay_days'),
    isAdmin: false,
    isOPH: false,
    isForeclosureFeedbackVisible: false,
    isLoading: false,
    isDisabled: false,
    getPaydayOptions: () => {},
    startPollingJobs: () => {},
    onSubmit: () => {},
    setASAPModeASAP: () => {},
    setASAPModeTomorrow: () => {},
    isExternalEditMode: false,
    setExternalEditMode: () => {},
    isPaymentOpen: false,
    foreclosurePayrolls: {},
};

export default UpdatePayDayFrom;
