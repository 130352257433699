/**
 * @deprecated Käytä DataTable7:aa
 */
import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import Highlighter from 'react-highlight-words';
import checkboxHOC from 'react-table-6/lib/hoc/selectTable';
import Button from 'shared/components/Button';
import EmptyState from 'shared/components/EmptyState';
import svgIconTypes from 'shared/constants/svgIconTypes';
import Avatar from 'shared/components/Avatar';
import { Pagination } from 'shared/components/Pagination';
import { ActionBar, Badge, Dropdown, MDSpinner, Popover, Separator } from 'shared/components';
import { get } from 'shared/utils/get';
import { DataTableExport } from 'shared/containers/DataTableExport';
import { CellFilter } from 'shared/components/DataTable/CellFilter';

// Tuetut kustomoidut tyypit
export const customTypes = {
    LINK: 'link',
    CURRENCY: 'currency',
    NUMBER: 'number',
    DATE: 'date',
    DATETIME: 'dateTime',
    AVATAR: 'avatar',
    PHONE_NUMBER: 'phoneNumber',
    BOOLEAN: 'boolean',
};

const customFilterTypes = {
    CHECKBOX: 'checkbox',
    DROPDOWN: 'dropdown',
};

const getValues = (event, filter) => {
    const value = event.target.value;
    const values = filter ? filter.value : [];
    if (event.target.checked) {
        return [ ...values, value ];
    }
    return _.filter(values, (filterValue) => filterValue !== value);
};

export const defaultFilterMethod = (filter, value) => value.toString().toLowerCase().includes(filter.value.toLowerCase());

const getColumnIdentifier = (col) => {
    // Jos accessori on funktio, niin palauta id, joka on pakko antaa
    if (typeof col.accessor === 'function') {
        return col.id;
    }
    return col.accessor;
};

/**
 * Oletusfiltteri checkbox-tyypillä.
 * Vertailee joko useampaa valittua filtteriarvoa yhteen solun arvoon tai taulukkoon.
 * @param filter
 * @param row
 * @returns {boolean|boolean|*}
 */
const defaultCheckboxFilterMethod = (filter, row) => {
    const values = _.get(filter, 'value', []);

    return (
        values.includes(String(row[filter.id]))
        || values.length === 0
        || (Array.isArray(row[filter.id]) && row[filter.id].some((item) => values.includes(String(item))))
    );
};

/**
 * Wrapperi React Data Tablelle.
 * Pääosin lisää meidän omat tyylit / komponentit ja asettaa oletusmetodeja komponentille.
 * Helpottaa mm. päivämäärien, linkkien ja valuuttojen formatointia.
 */
export default class DataTable extends Component {

    state = {
        columns: [],
        selection: [],
        selectAll: false,
        page: 0,
        pageSize: 20,
        filtered: [],
        visibleColumns: [],
        HocTable: ReactTable,
    };

    componentDidMount() {
        this.curryColumns(this.props);
        this.updateState(this.props);
        if (this.props.hasFilterableColumns) {
            this.resolveDefaultColumns(this.props);
        }

        if (this.props.isSelectable) {
            this.setState({ HocTable: checkboxHOC(ReactTable) });
        }
    }

    /**
     * Yrittää hakea localStoragesta käyttäjän tallenettamia defaultteja, jos ei löydy, niin käytetään ennalta asetettuja
     *
     * @param props
     */
    resolveDefaultColumns(props) {
        const savedDefaultRaw = localStorage.getItem(props.defaultColumnsKey);
        if (savedDefaultRaw) {
            try {
                // Varmistetaan, että on validia JSON, jos ei ole, niin defaultataan ennalta määriteltyihin
                const savedDefault = JSON.parse(savedDefaultRaw);
                this.setState({ visibleColumns: savedDefault });
            } catch (e) {
                console.error(e);
                this.setState({ visibleColumns: this.getDefaultColumns(props) });
            }
        } else {
            this.setState({ visibleColumns: this.getDefaultColumns(props) });
        }
    }

    /**
     * Defaultti sarakkeet column datasta, jossa isDefault: true
     *
     * @param props
     * @return {*}
     */
    getDefaultColumns(props) {
        return props.columns.filter((col) => col.isDefault).map((col) => ({ id: getColumnIdentifier(col), isActive: true }));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { selection, data, hasExternalSelection } = nextProps;
        this.curryColumns(nextProps);

        // SelectAll toggle päivityst: valitse kaikki yksitellen => valitaan toggle all
        if (hasExternalSelection) {
            console.log(selection);
            this.setState({
                selectAll: selection.length === data.length,
            });
        }
    }

    updateState = (props) => {
        const {
            defaultSorted,
            defaultPageSize,
            isSelectable,
            defaultSelection,
        } = props;

        this.setState({
            sorted: defaultSorted,
            page: 0,
            pageSize: defaultPageSize,
            selection: isSelectable ? defaultSelection : [],
        });
    };

    /**
     * Käydään sarakkeet läpi ja tutkitaan onko solun renderöintitapa jokin ennalta asetettu (currency, data...) ja
     * rikastetaan filtteröinti- ja sorttausmetodit niiden tyypin mukaisesti automaattisesti.
     * @param props
     */
    curryColumns = (props) => {
        const isResizable = _.get(props, 'isResizable', true);

        const columns = _.map(_.cloneDeep(props.columns), (column) => {
            const hasWidth = _.has(column, 'width');
            const columnAccessor = _.get(column, 'accessor', '');
            const columnType = _.get(column, 'type', null);
            const filterType = _.get(column, 'filterType', null);
            const filterOptions = _.get(column, 'filterOptions', []);
            const defaultValue = _.get(column, 'defaultValue', '');
            const optionValueKey = _.get(column, 'optionValueKey', 'value');
            const optionLabelKey = _.get(column, 'optionLabelKey', 'label');
            let customColumnData = null;
            let customFilterData = null;

            // Heitä noppaa sattuuko accessor tai id olemaan "hetumainen" ja tarkista onko leveyttä annettu.
            // Jos ei leveyttä, aseta hetulle sopiva leveys automaattisesti.
            if (_.includes(['socialSecurityNumber', 'ssn'], columnAccessor) && ! hasWidth) {
                column.width = 140;
            }

            // Rivittyykö teksti
            const wordWrap = _.get(column, 'wordWrap', false);
            // Tekstin tasaus
            const alignText = _.get(column, 'alignText', '');
            // Tekstin ylivuoto
            const overflow = _.get(column, 'overflow', true);

            column.className = classNames({
                'u-text-wrap': wordWrap,
                'u-text-left': alignText === 'left',
                'u-text-right': alignText === 'right',
                'u-text-center': alignText === 'center',
                'u-visibility-visible': !overflow,
            });

            if (filterType) {
                const hasFilterMethodFunction = typeof column.filterMethod === 'function';
                // Customi filter typet.
                switch (filterType) {
                    // Customi checkbox filtteröinti. Arvot taulukossa objektina muodossa { value: 123, label: lorem }
                    case customFilterTypes.CHECKBOX:
                        customFilterData = {
                            // Annettu oma filtteri funktio, käytetään sitä
                            filterMethod: hasFilterMethodFunction ? column.filterMethod : defaultCheckboxFilterMethod,
                            Filter: ({ filter, onChange }) => {
                                const selectedFilters = _.get(filter, 'value', []);
                                const selectedFilterCount = selectedFilters.length;

                                // Ei valittu mitään => Kaikki
                                let dropdownText = _trans('text.all');

                                // Jos vain yksi valinta => näytä se
                                if (selectedFilterCount === 1) {
                                    const selectedValue = filterOptions.find((filterOption) =>
                                        _.get(filterOption, 'value').toString() === selectedFilters[0].toString());
                                    if (selectedValue) {
                                        dropdownText = _.get(selectedValue, 'label', '-');
                                    }
                                } else if (selectedFilterCount > 1 && selectedFilterCount < filterOptions.length) {
                                    // Monta valintaa: kerro montako valittu
                                    dropdownText = _trans('dropdown.chosen', { count: selectedFilters.length });
                                }

                                return (
                                    <Popover minWidth={200} position="bottom-start" isArrowVisible={false}>
                                        <div className="u-position-relative">
                                            <button className="c-dropdown u-1/1 u-text-left u-text-truncate">
                                                {dropdownText}
                                            </button>
                                        </div>
                                        <div className="u-text-left">
                                            <Button
                                                mdIcon="clear"
                                                modifierClass="u-padding-horizontal-none"
                                                disabled={selectedFilterCount === 0}
                                                onClick={() => onChange([])}
                                                size="small"
                                                flat
                                                iconSize="small"
                                            >
                                                {_trans('multi_select.clear_selections')}
                                            </Button>
                                            <Separator />
                                            {filterOptions.map((option, i) => (
                                                <div key={i}>
                                                    <label className="u-text-no-wrap">
                                                        <input
                                                            type="checkbox"
                                                            value={option[optionValueKey]}
                                                            defaultChecked={filter && _.indexOf(filter.value, String(option.value)) > -1}
                                                            onChange={(event) => onChange(getValues(event, filter))}
                                                        />
                                                        {option[optionLabelKey]}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </Popover>
                                );
                            }
                        };
                        break;
                    // Dropdown-tyylinen filtteröinti. Optiot samalla tavalla kuin ylläolevassa checkboxissa
                    case customFilterTypes.DROPDOWN:
                        customFilterData = {
                            filterMethod: (filter, row) =>
                                String(_.get(filter, 'value')) === String(row[filter.id]),
                            Filter: ({ filter, onChange }) => (
                                <div>
                                    <Dropdown
                                        placeholder={_trans('text.all')}
                                        options={filterOptions}
                                        optionLabel={optionLabelKey}
                                        optionValue={optionValueKey}
                                        onChange={(value) => onChange(value)}
                                        value={_.get(filter, 'value')}
                                        isFullWidth
                                    />
                                </div>
                            )
                        };
                        break;
                }
            }

            if (columnType) {
                switch (columnType) {
                    // Päivämäärätyypin ollessa kyseessä lisätään päivämääräkohtaiset filtterit ja
                    // lokalisaationmukaiset formatoinnit
                    case customTypes.DATE:
                        customColumnData = {
                            filterMethod: (filter, row) => {
                                let value = row[filter.id];
                                if (_.isEmpty(value)) {
                                    value = defaultValue;
                                } else {
                                    value = _toLocaleDate(value);
                                }

                                return defaultFilterMethod(filter, value);
                            },
                            Cell: (props) => _toLocaleDate(props.value, defaultValue),
                        };

                        // Onko sarakkeen leveyttä annettu? Jos ei, aseta päivämäärälle sopiva leveys.
                        if (! hasWidth) {
                            column.width = 120;
                        }

                        break;

                    // Päivämäärätyypin ollessa kyseessä lisätään päivämääräkohtaiset filtterit ja
                    // lokalisaationmukaiset formatoinnit
                    case customTypes.DATETIME:
                        customColumnData = {
                            filterMethod: (filter, row) => {
                                let value = row[filter.id];
                                if (_.isEmpty(value)) {
                                    value = defaultValue;
                                } else {
                                    value = _toLocaleDate(value, '', _dateTimeFormat);
                                }

                                return defaultFilterMethod(filter, value, _dateTimeFormat);
                            },
                            Cell: (props) => _toLocaleDate(props.value, defaultValue, _dateTimeFormat),
                        };

                        // Onko sarakkeen leveyttä annettu? Jos ei, aseta päivämäärälle sopiva leveys.
                        if (! hasWidth) {
                            column.width = 175;
                        }

                        break;

                    // Valuuttatyypin ollessa kyseessä lisätään valuuttakohtaiset filtterit ja
                    // lokalisaationmukaiset formatoinnit
                    case customTypes.CURRENCY:
                        customColumnData = {
                            className: 'u-text-right',
                            filterMethod: (filter, row) => {
                                const value = _currency(row[filter.id]);

                                // Käyttäjä voi hakea joko formatoitua valuutta-arvoa tai alkuperäistä
                                return defaultFilterMethod(filter, value) || defaultFilterMethod(filter, row[filter.id].toString());
                            },
                            // Fallback 0, jos ei löydy, muuten tulisi epäluku.
                            Cell: (props) => _currency(_.get(props, 'value', 0)),
                        };
                        break;

                    // Lokalisaation mukainen numeron formatointi
                    case customTypes.NUMBER:
                        customColumnData = {
                            filterMethod: (filter, row) => {
                                const value = _numberFormat(row[filter.id]);

                                // Käyttäjä voi hakea joko formatoitua valuutta-arvoa tai alkuperäistä
                                return defaultFilterMethod(filter, value) || defaultFilterMethod(filter, row[filter.id].toString());
                            },
                            // Fallback 0, jos ei löydy, muuten tulisi epäluku.
                            Cell: (props) => _numberFormat(_.get(props, 'value', 0)),
                        };
                        break;

                    case customTypes.AVATAR:
                        customColumnData = {
                            filterable: false,
                            sortable: false,
                            Cell: (props) => <Avatar src={props.value} />,
                            width: 72,
                        };
                        break;
                }
            }

            if (_.get(column, 'resizable', null) !== false && isResizable) {
                column.className += ' rt-resizable-column';
            }

            return {
                ...column,
                ...customColumnData,
                ...customFilterData,
            };
        });

        // Jos actionsColumn-props on annettu tehdään siitä joka riville oma toimintosarake.
        const { actionsColumn, actionsColumnHeader, actionsColumnFooter } = props;

        if (actionsColumn !== null) {
            const column = {
                Header: actionsColumnHeader === null ? _trans('data_table.columns.actions') : actionsColumnHeader,
                filterable: false,
                sortable: false,
                Cell: (props) => React.cloneElement(actionsColumn, props),
                Footer: actionsColumnFooter,
                isLocked: true, // actionsColumn aina lukittu,
                // Tuskinpa halutaan nähdä exportatessa...
                hideInExport: true,
            };

            const actionsColumnWidth = _.get(props, 'actionsColumnWidth', false);
            if (actionsColumnWidth) {
                column.width = actionsColumnWidth;
            }

            columns.push(column);
        }

        this.setState({
            columns,
        });
    };

    toggleSelection = (key) => {
        const { hasExternalSelection } = this.props;
        /**
         * Eri toteutukset single ja multipe selectionille
         */
        if (this.props.selectType === 'radio') {

            // Nollataan selected ja lisätään valittu rivi.
            const selection = [];
            if (selection.indexOf(key) < 0) selection.push(key);

            //Ulospäin tieto mitä valittiin
            this.props.onSelect(selection);

            if (! hasExternalSelection) {
                //Talteen myös stateen.
                this.setState({ selection });
            }
        } else {
            let selection = [
                ...this.state.selection
            ];
            if (hasExternalSelection) {
                selection = [ ...this.props.selection ];
            }
            const keyIndex = selection.indexOf(key);
            // check to see if the key exists
            if (keyIndex >= 0) {
                // it does exist so we will remove it using destructing
                selection = [
                    ...selection.slice(0, keyIndex),
                    ...selection.slice(keyIndex + 1)
                ];
            } else {
                // it does not exist so add it
                selection.push(key);
            }
            this.props.onSelect(selection);

            if (! hasExternalSelection) {
                // update the state
                this.setState({ selection });
            }
        }

    };

    toggleAll = () => {
        /*
          'toggleAll' is a tricky concept with any filterable table
          do you just select ALL the records that are in your data?
          OR
          do you only select ALL the records that are in the current filtered data?

          The latter makes more sense because 'selection' is a visual thing for the user.
          This is especially true if you are going to implement a set of external functions
          that act on the selected information (you would not want to DELETE the wrong thing!).

          So, to that end, access to the internals of ReactTable are required to get what is
          currently visible in the table (either on the current page or any other page).

          The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
          ReactTable and then get the internal state and the 'sortedData'.
          That can then be iterrated to get all the currently visible records and set
          the selection state.
        */
        const selectAll = !this.state.selectAll;
        const selection = [];
        if (selectAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach((item) => {
                // Jos halutaan rajaa valintoja, esim. riviltä löytyy joku virhe, niin tällä voi estää
                if (
                    _.has(this.props, 'disableSelection') &&
                    typeof this.props.disableSelection === 'function'
                ) {
                    // löytyy ja on funktio, ajellaan läpi, jos ei mene läpi, niin ei lisäillä
                    if (! this.props.disableSelection(item._original)) {
                        selection.push(_.get(item._original, this.props.keyField, null));
                    }
                } else {
                    // Eipä ollut mitään filtteriä, niin eipä lisäillään vaan kaikki
                    selection.push(_.get(item._original, this.props.keyField, null));
                }
            });
        }
        /* *
        * Jos on valittu externalSelection ja valinnat ei muutu niin oletetaan, että käyttäjä haluaa valita rivit pois (toggle off).
        * Eli tässä tapauksessa palautetaan tyhjä lista. Muutoin palautetaan valitut valinnat (selection).
        * */
        this.props.onSelect(this.props.hasExternalSelection && JSON.stringify(selection) === JSON.stringify(this.props.selection) ? [] : selection);
        if (this.props.hasExternalSelection) {
            this.setState({ selectAll });
        } else {
            this.setState({ selectAll, selection });
        }
    };

    isSelected = (key) => {
        /*
          Instead of passing our external selection state we provide an 'isSelected'
          callback and detect the selection state ourselves. This allows any implementation
          for selection (either an array, object keys, or even a Javascript Set object).
        */
        if (this.props.hasExternalSelection) {
            return this.props.selection.includes(key);
        }
        return this.state.selection.includes(key);
    };

    /**
     * Kun valitaan sarake filttereistä, asetetaan stateen
     *
     * @param column
     * @param isActive
     */
    onColumnFilter(column, isActive) {
        const identifierKey = getColumnIdentifier(column);
        const newVisibleColumns = this.state.visibleColumns.filter((col) => col.id !== identifierKey);
        this.setState({ visibleColumns: [...newVisibleColumns, { id: identifierKey, isActive }] });
    }

    /**
     * Filteröidyt sarakkeet <ReactTable> komponentille, jos ei voida filtteröidä, annetaan suoraan statesta
     *
     * @returns {[]|*[]|T[]}
     */
    resolveFilteredColumns() {
        if (! this.props.hasFilterableColumns) {
            return this.state.columns;
        }
        return this.state.columns.filter((col) =>
            this.state.visibleColumns.some((visCol) => visCol.id === getColumnIdentifier(col) && visCol.isActive) || col.isLocked
        );
    }

    /**
     * Tallennetaan localStorageen tiedot mitkä sarakkeet on näkyvisdsä
     */
    onDefaultColumnSave = () => {
        if (! this.props.defaultColumnsKey) {
            console.error('defaultColumnsKey not set');
            return;
        }
        localStorage.setItem(this.props.defaultColumnsKey, JSON.stringify(this.state.visibleColumns));
    };

    /**
     * Resolvoi filtteröitävät sarakkeet, isLocked-propertyllä voidaan estää, myöskin actionsColumn on aina näkyvissä
     * Lisäksi sarakkeet, jotka ei näy show-lipulla on piilossa.
     */
    resolveFilterableColumns() {
        return this.state.columns.filter((col) => ! col.isLocked && _.get(col, 'show', true));
    }

    render() {
        const {
            data,
            isFilterable,
            isSelectable,
            isSortable,
            isResizable,
            isHoverable,
            defaultPageSize,
            emptyDataMessage,
            emptyDataSubText,
            emptyDataIcon,
            emptyDataCallToAction,
            isManual,
            isLoading,
            children,
            selectType,
            canExpandOnRowClick,
            layout,
            canExport,
            exportFileName,
            additionalExportColumns,
            exportColumnTotals,
            hasFilterableColumns,
        } = this.props;

        const { HocTable } = this.state;
        const dataSize = _.size(data);

        // Näytä paginaatio vain jos tuloksia on enemmän kuin valittu sivun koko tai datan haku hallitaan ulkoisesti.
        // TODO: Tähän bäkkäristä tuleva "total" tieto jos isManual käytössä
        const showPagination = dataSize > defaultPageSize || isManual;

        if (dataSize === 0 && !isManual) {
            // Ei oo dataa, mutta ladataa => näytetään spinneri
            if (isLoading) {
                return <MDSpinner wrapped/>;
            }
            return (
                <EmptyState
                    message={emptyDataMessage}
                    subText={emptyDataSubText}
                    callToAction={emptyDataCallToAction}
                    icon={emptyDataIcon}
                />
            );
        }

        let checkboxProps = null;

        if (isSelectable) {
            const { toggleSelection, toggleAll, isSelected } = this;
            const { selectAll, } = this.state;

            checkboxProps = {
                selectAll,
                isSelected,
                toggleSelection,
                toggleAll,
                selectType,

                // Korostetaan valitut rivit
                getTrProps: (state, rowInfo) => {
                    const isSelected = this.isSelected(rowInfo.original[this.props.keyField]);
                    if (isSelected) {
                        return {
                            className: 'is-selected',
                        };
                    } else return {};
                },

                getTdProps: (state) => {
                    console.log(state);
                    return {
                        width: 30,
                    };
                }
            };
        }

        const expandOnRowClickFunc = (state, rowInfo, col, instance) => (
            {
                className: 'rt-expandable',
                onClick: () => {
                    const { expanded } = state;
                    const path = rowInfo.nestingPath[0];
                    const diff = { [path]: !expanded[path] };

                    instance.setState({
                        expanded: {
                            ...expanded,
                            ...diff
                        }
                    });
                },
                role: 'row',
            }
        );
        // Lasketaan diffiä defaultti tilanteeseen, kuinka monta
        const columnFiltersDiff = _.sumBy(this.resolveFilteredColumns(), (col) => ! col.isLocked);
        /*const columnFiltersDiff = Math.abs(
            this.getDefaultColumns(this.props).length -
            this.resolveFilteredColumns().filter((col) => {
                // console.log(col);
                return ! col.isLocked && col.isDefault;
            }).length
        ) + filteredNonDefaultNorLockedColumnCount;
        console.log(this.resolveFilterableColumns(), this.getDefaultColumns(this.props), filteredNonDefaultNorLockedColumnCount);*/
        return (
            <div>
                <HocTable
                    {...checkboxProps}
                    {..._.omit(this.props, 'columns')}
                    minRows={0}
                    columns={this.resolveFilteredColumns()}
                    className={classNames(`-${layout}`, {
                        '-highlight': isHoverable,
                        '-sortable': isSortable,
                        '-resizable': isResizable,
                        '-selectable': isSelectable,
                    })}
                    ref={(r) => this.checkboxTable = r}
                    filterable={isFilterable && !isManual}
                    resizable={isResizable}
                    multiSort={!isManual && isSortable}
                    sortable={!isManual && isSortable}
                    defaultPageSize={defaultPageSize}

                    /*page={this.state.page}
                    pageSize={this.state.pageSize}
                    onPageChange={(page) =>
                        this.setState({ page: parseInt(page, 10) })}
                    onPageSizeChange={(pageSize, page) => {
                        console.log(pageSize, page);
                        this.setState({ page: parseInt(page, 10), pageSize: parseInt(pageSize, 10) })}
                    }*/

                    // Asetetaan oletusfiltteröintitapa
                    defaultFilterMethod={(filter, row) => {
                        const value = _.get(row, filter.id, '');
                        return value === '' ? null : defaultFilterMethod(filter, value.toString());
                    }}
                    previousText={_trans('data_table.pagination.previous')}
                    nextText={_trans('data_table.pagination.next')}
                    loadingText={_trans('data_table.loading')}
                    noDataText={_trans('data_table.no_data_text')}
                    pageText={_trans('data_table.pagination.page')}
                    ofText={_trans('data_table.pagination.of')}
                    rowsText={_trans('data_table.pagination.rows')}
                    /*getTableProps={(state) => ({
                        ...this.props.getTableProps
                        role: 'table',
                        className: _.get(state, 'pages', 0) === 0 ? 'rt-table--empty' : '',
                    })}*/

                    getTableProps={(state) => ({
                        role: 'table',
                        className: _.get(state, 'pages', 0) === 0 ? 'rt-table--empty' : '',
                    })}

                    // Oma kustomoitu paginaatiokomponentti ja sen navigaationuolet
                    PaginationComponent={(props) => (
                        showPagination && (
                            <div className="u-text-right u-margin-top-small u-margin-bottom">
                                <Pagination {...props} />
                            </div>
                        )
                    )}
                    PreviousComponent={(props) => {
                        const {
                            onClick,
                            children,
                            disabled,
                        } = props;

                        return (
                            <Button
                                flat
                                onClick={onClick}
                                modifierClass="u-1/1"
                                disabled={disabled}
                                mdIcon="keyboard_arrow_left"
                            >
                                {children}
                            </Button>
                        );
                    }}
                    NextComponent={(props) => {
                        const {
                            onClick,
                            children,
                            disabled,
                        } = props;

                        return (
                            <Button
                                flat
                                onClick={onClick}
                                modifierClass="u-1/1"
                                disabled={disabled}
                                mdIcon="keyboard_arrow_right"
                                iconAfterText
                            >
                                {children}
                            </Button>
                        );
                    }}

                    getTheadThProps={(state, rowInfo, column) => {
                        const alignText = _.get(column, 'alignText', '');
                        return {
                        // ...instance,
                            role: 'columnheader',
                            colSpan: 1,
                            className: classNames({
                                'u-text-left': alignText === 'left',
                                'u-text-center': alignText === 'center',
                                'u-text-right': alignText === 'right',
                            }),
                        };
                    }}
                    getTheadFilterThProps={() => ({
                        role: 'columnheader',
                        colSpan: 1,
                    })}

                    // Tekee koko rivistä klikattavan joka avaa rivin alakomponentin.
                    getTrProps={canExpandOnRowClick ? expandOnRowClickFunc : (state, rowInfo, col, instance) => ({ ...this.props.getTrProps(state, rowInfo, col, instance), role: 'row' })}
                    getTheadTrProps={() => ({ role: 'row' })}
                    getLoadingProps={() => ({
                        'aria-hidden': !isLoading,
                    })}

                    // Palauta sarakkeen tyyppi jos löytyi kustomoituja tyyppejä.
                    // Muutoin oletus muuttumattomana.
                    getTdProps={(state, rowInfo, column, instance) => {
                        const columnId = _.get(column, 'id', null);
                        const columnType = _.get(column, 'type', false);
                        const isMultiline = _.get(column, 'isMultiline', false);
                        const filtered = _.find(state.filtered, (filter) => filter.id === columnId);
                        let columnCustomData = null;

                        // Linkkien kohdalla luodaan linkkielementti.
                        if (columnType === customTypes.LINK) {
                            const row = _.get(rowInfo, 'row', null);

                            columnCustomData = {
                                type: columnType,
                                content: columnId && row ? row[columnId] : '',
                            };
                        }

                        let customStyles = {};
                        if (isMultiline) {
                            customStyles = {
                                whiteSpace: 'normal',
                            };
                        }

                        return {
                            ...instance,
                            ...columnCustomData,
                            filtered: isManual ? state.filtered : _.get(filtered, 'value', ''), // Onko filtteröinti annettu ulkopuolelta (backend-haku)
                            style: { ...instance.style, ...customStyles },
                        };
                    }}

                    // Renderöi kustomoidut saraketyypit jos niitä on, muutoin käytä oletusrenderöijää.
                    TdComponent={(props) => {
                        const filtered = props.filtered;

                        const columnType = _.get(props, 'type', false);
                        const tdClass = classNames('rt-td', props.className);

                        // Kustomoitujen solujen kohdalla itse sisältö on content-keyssä
                        const children = _.has(props, 'content') ? props.content : props.children;
                        let content = children;

                        // Jos käytettiin hakusanaa, korosta sanat jotka osuivat sisältöön
                        if (typeof filtered === 'string' && filtered.length > 0 && typeof children === 'string' && !_.isEmpty(children)) {
                            content = (
                                <Highlighter
                                    searchWords={[filtered]}
                                    autoEscape={true}
                                    textToHighlight={children}
                                />
                            );
                        }

                        // Linkkien kohdalla sisältö luodaan aiemmin generoidusta kustomoidusta sisällöstä koska
                        // filtterin haun on kohdistuttava tekstuaaliseen sisältöön (yllä).
                        if (columnType === customTypes.LINK) {
                            const url = props.children;

                            // Luo linkki vain jos url on string. Muutoin palauta vain sisältö.
                            if (typeof url === 'string') {
                                content = (
                                    <a href={url} rel="noopener">
                                        {content}
                                    </a>
                                );
                            }
                        }

                        return (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/no-noninteractive-element-interactions
                            <div className={tdClass} style={props.style} onClick={props.onClick} role="cell">
                                {content}
                                { _.has(props, 'SubComponent') ? props.SubComponent(props.data) : null }
                            </div>
                        );
                    }}
                    FilterComponent={({ filter, onChange, column }) => (
                        <CellFilter
                            column={column}
                            filter={filter}
                            onChange={onChange}
                        />
                    )}

                    NoDataComponent={(props) => isLoading ? null : (
                        <div className="rt-noData">
                            {props.children}
                        </div>
                    )}
                    manual={isManual}
                    loading={isLoading}
                >
                    {(state, makeTable, { onPageChange, onPageSizeChange }) => (
                        <div>
                            {children && children(state)}
                            <ActionBar hasMargin>
                                <div>
                                    {canExport && (
                                        <DataTableExport
                                            exportColumnTotals={exportColumnTotals}
                                            onGetData={() => {
                                                const resolvedState = this.checkboxTable.getResolvedState();
                                                const allDecoratedColumns = resolvedState.allDecoratedColumns;

                                                // Otsikot yhteen läjään.
                                                // Filtteröidään pois ne sarakkeet joita ei exporttiin haluta (hideInExport & show = false).
                                                const headers = [].concat(
                                                    allDecoratedColumns.filter((column) => (! column.hideInExport && (column?.show ?? true))).map((column) => column.Header),
                                                    additionalExportColumns.map((column) => column.Header),
                                                );

                                                // Sarakkeet yhteen
                                                const columns = []
                                                    .concat(resolvedState.allDecoratedColumns, additionalExportColumns)
                                                    .filter((column) => (! column.hideInExport && (column?.show ?? true)));

                                                // Yhdistetään kaikki
                                                const rows = resolvedState.sortedData.map((row) => (
                                                    columns.reduce((allColumns, column) => {
                                                        const { accessor } = column;
                                                        // Otetaan arvo joko accessori-funkkarilta tai käytetään sitä pathina
                                                        const value = typeof accessor === 'function'
                                                            ? accessor(row._original)
                                                            : get(row._original, accessor);

                                                        // Mahdollinen kustomformaatteri jos on.
                                                        const exportFormatter = column.exportFormatter ?? ((value) => value);

                                                        return [].concat(
                                                            allColumns,
                                                            {
                                                                ...column,
                                                                value: exportFormatter(value),
                                                            }
                                                        );
                                                    }, [])
                                                ));

                                                return { headers, rows, exportColumnTotals };
                                            }}
                                            fileName={exportFileName}
                                        />
                                    )}
                                </div>
                                <div>
                                    {hasFilterableColumns && (
                                        <Popover onClose={this.onDefaultColumnSave} position="bottom">
                                            <span className="u-position-relative u-display-inline-block">
                                                <Button mdIcon="filter_list">
                                                    {_trans('data_table.columns.columns')}
                                                </Button>
                                                {columnFiltersDiff > 0 && (
                                                    <Badge overlap size="small" value={columnFiltersDiff} />
                                                )}
                                            </span>
                                            <div>
                                                <Button
                                                    size="small"
                                                    modifierClass="u-text-center u-margin-bottom-small u-padding-horizontal-none"
                                                    flat
                                                    iconSize="small"
                                                    mdIcon="settings_backup_restore"
                                                    disabled={columnFiltersDiff < 1}
                                                    onClick={() => this.setState({ visibleColumns: this.getDefaultColumns(this.props) })}
                                                >
                                                    {_trans('data_table.columns.restore_defaults')}
                                                </Button>
                                                <ul className="o-list-bare u-margin-none">
                                                    {this.resolveFilterableColumns().map((col, index) => (
                                                        <li key={index}>
                                                            <label className="u-text-left">
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(ev) => this.onColumnFilter(col, ev.target.checked)}
                                                                    checked={this.state.visibleColumns.some((column) => column.id === getColumnIdentifier(col) && column.isActive)}
                                                                />
                                                                {col.Header}
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Popover>
                                    )}
                                    {showPagination && (
                                        <div className="u-margin-left u-display-inline-block">
                                            <Pagination
                                                page={state.page}
                                                pageSize={state.pageSize}
                                                pageSizeOptions={state.pageSizeOptions}
                                                pages={state.pages}
                                                onPageChange={onPageChange}
                                                onPageSizeChange={onPageSizeChange}
                                                canNext={state.canNext}
                                                canPrevious={state.canPrevious}
                                            />
                                        </div>
                                    )}
                                </div>
                            </ActionBar>
                            {makeTable()}
                        </div>
                    )}
                </HocTable>
            </div>
        );
    }
}

DataTable.defaultProps = {
    defaultColumnsKey: '',
    hasFilterableColumns: false,
    defaultPageSize: 20,
    defaultSorted: [],
    emptyDataMessage: _trans('data_table.empty_data_message'),
    emptyDataSubText: '',
    emptyDataIcon: 'inbox',
    emptyDataCallToAction: null,
    isManual: false,
    isLoading: false,
    isFilterable: true,
    isSelectable: false,
    isSortable: true,
    isResizable: true,
    isHoverable: true,
    actionsColumn: null,
    actionsColumnHeader: null,
    selectType: 'checkbox',
    keyField: '',
    defaultSelection: [],
    canExpandOnRowClick: false,
    layout: 'default',
    canExport: false,
    exportFileName: 'export',
    hasExternalSelection: false,
    selection: [],
    disableSelection: undefined,
    additionalExportColumns: [],
    exportColumnTotals: {},
    onSelect() {},
    children: null,
    getTrProps() { return {}; },

    // Otetaan nämä toistaiseksi pois
    /*    onRowClick: () => {},
        rowRoute: null,*/
};

DataTable.propTypes = {
    /**
     * Key jolla haetaan localStoragesta defaultti column filtteröitä
     */
    defaultColumnsKey: PropTypes.string,
    /**
     * Onko sarakkeet valittavissa
     */
    hasFilterableColumns: PropTypes.bool,
    /**
     * Sarakkeiden metadata
     */
    columns: PropTypes.array.isRequired,
    /**
     * Montako riviä näytetään.
     */
    defaultPageSize: PropTypes.number,

    /**
     * Oletusjärjestys. Voidaan järjestää useamman sarakkeen mukaankin.
     */
    defaultSorted: PropTypes.array,

    /**
     * Mikä viesti näytetään kun data on tyhjä.
     */
    emptyDataMessage: PropTypes.string,
    /**
     * Mikä alaviesti näytetään kun data on tyhjä.
     */
    emptyDataSubText: PropTypes.string,
    /**
     * Mikä ikoni näytetään kun data on tyhjä. Katso svgIconTypes.js
     */
    emptyDataIcon: PropTypes.oneOf(_.map(svgIconTypes)),

    /**
     * Haetaanko data bäkkäriltä.
     */
    isManual: PropTypes.bool,

    /**
     * Jos data haetaan bäkkäriltä ilmoitetaan tällä koska haku on käynnissä.
     */
    isLoading: PropTypes.bool,

    /**
     * Onko jokainen sarake oletuksena suodatettavana?
     */
    isFilterable: PropTypes.bool,

    /**
     * Onko jokainen rivi valittavana?
     */
    isSelectable: PropTypes.bool,

    /**
     * Valinnan tyyppi, voi olla checkboxi (multiple selection) tai radio (single selection)
     */
    selectType: PropTypes.oneOf(['checkbox', 'radio']),

    /**
     * Voiko sarakkeiden leveyttä muuttaa?
     */
    isResizable: PropTypes.bool,

    /**
     * Voiko käyttäjä muuttaa rivien järjestystä itse?
     */
    isSortable: PropTypes.bool,

    /**
     * Rivin taustaväri muuttuu hiirellä päällä hoveroitaessa. Pelkästään visuaalinen lisä.
     */
    isHoverable: PropTypes.bool,

    /**
     * Toiminnot jotka lisätään joka riville.
     */
    actionsColumn: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /*    onRowClick: PropTypes.func,
        rowRoute: PropTypes.string,*/

    /**
     * Kustomoitu otsikko toiminnot riville
     */
    actionsColumnHeader: PropTypes.string,
    /**
     * Jos valinta käytössä niin annetta mikä arvo valitaan riviltä
     */
    keyField: PropTypes.string,

    /**
     * Mitkä rivit on valittuna alussa. Toimii vain jos isSelectable on päällä.
     */
    defaultSelection: PropTypes.array,

    /**
     * Voiko alakomponentin näyttää koko riviä klikkaamalla.
     */
    canExpandOnRowClick: PropTypes.bool,

    /**
     * Leiskan tyyppi: kuinka tiivis taulukko on.
     */
    layout: PropTypes.oneOf(['compact', 'default']),

    /**
     * Näytetäänkö export-nappula.
     */
    canExport: PropTypes.bool,

    /**
     * Export-tiedoston nimi, ilman tiedostopäätettä.
     */
    exportFileName: PropTypes.string,

    /**
     * Mikäli halutaan controlloida täysin ulkoapäin rivin valintoja, niin annetaan tämä
     * Lisäksi annettava selection propsi
     */
    hasExternalSelection: PropTypes.bool,

    /**
     * Jos halutaan ulkoinen selection, tämä annettava
     */
    selection: PropTypes.array,

    /**
     * Funktio, jota vasten ajetaan jokainen rivi. Mahdollistaa rivin valinnan disabloiminen
     */
    disableSelection: PropTypes.func,

    /**
     *  Voi passata lisäsarakkeita exportille
     */
    additionalExportColumns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        Header: PropTypes.string,
        accessor: PropTypes.string.isRequired,
    })),

    /**
     * Jos halutaan yhteensä rivi lisätä pohjalle. Käytössä lähinnä vain pikarapsoilla.
     * Lisätään muodossa { key: total, sarake1: 2134, ... }, jossa key on sarakkeen id (tai accessor)
     */
    exportColumnTotals: PropTypes.object,

    /**
     * Taulukon data, annettava aina
     */
    data: PropTypes.array.isRequired,

    /**
     * Kun rivit valittavia, niin kutsutaan tätä funktiota
     */
    onSelect: PropTypes.func,

    /**
     * Mahdollinen tyhjän tilan action-nappi
     */
    emptyDataCallToAction: PropTypes.node,

    children: PropTypes.node,

    // eslint-disable-next-line
    getTrProps: PropTypes.func,
};
