import api from 'api';

export const userDataProposal = {
    state: {
        userDataProposal:  [],
        acceptedUserDataProposal: [],
        rejectedDataProposal: [],
        isUserDataUpdated: null,
    },

    reducers: {
        setUserDataProposal: (state, userDataProposal) => ({ ...state, userDataProposal }),
        setAcceptedUserDataProposal: (state, acceptedUserDataProposal) => ({ ...state, acceptedUserDataProposal }),
        setRejectedDataProposal: (state, rejectedDataProposal) => ({ ...state, rejectedDataProposal }),
        setIsUserDataUpdated: (state, isUserDataUpdated) => ({ ...state, isUserDataUpdated }),
    },

    selectors: {
        getUserDataProposal: (state) => state.userDataProposal ?? [],
        getAcceptedUserDataProposal: (state) => state.acceptedUserDataProposal ?? [],
        getRejectedDataProposal: (state) => state.rejectedDataProposal ?? [],
        getIsUserDataUpdated: (state) => state.isUserDataUpdated ?? false,
    },

    effects: {
        //Hae hyväksyntää odottava user data
        async fetchUserDataProposal(user) {
            try {
                const response = await api.get(`/api/v2/users/${user}/data-proposal`);

                if (response.status === 'ok') {
                    this.setUserDataProposal(response.data);
                    const dataHasUpdated = response.data ? Object.keys(response.data).length > 0 : false;
                    this.setIsUserDataUpdated(dataHasUpdated);
                }
                return response;

            } catch (error) {
                console.error(error);
                return { status: 'error', error };
            }
        },
        //Hyväksyy user_datan
        async acceptUserDataProposal(user) {
            try {
                const response = await api.post(`/api/v2/users/${user}/accept-data-proposal`);
                if (response.status === 'ok') {
                    this.setAcceptedUserDataProposal(response.data);
                }

                return response;
            } catch (error) {
                console.error(error);
                return { status: 'error', error };
            }
        },
        async rejectUserDataProposal(user) {
            try {
                const response = await api.post(`/api/v2/users/${user}/reject-data-proposal`);
                if (response.status === 'ok') {
                    this.setRejectedDataProposal(response.data);
                }

                return response;
            } catch (error) {
                console.error(error);
                return { status: 'error', error };
            }
        }
    },
};
