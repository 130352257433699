import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import types from 'shared/constants/collectiveAgreementVariableTypes';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { BooleanValue } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Summary/shared/BooleanValue';

const showValueByType = (value, type) => {
    switch (type) {
        case types.BOOLEAN:
            return <BooleanValue value={value} />;
        default:
            return value;
    }
};

const Variables = (props) => {
    const { values, attributes, templateFieldNames } = props;
    const fieldProps = getFieldProps(props);

    const isLoading = useSelector((state) => state.loading.effects.collectiveAgreement.fetchCollectiveAgreements);

    // Uuden sopparin ollessa kyseessä tes-variablet haetaan annetun category:n perusteella.
    // Tallennetun sopparin tapauksessa hyväksytään kaikki sille tallennetut tes-variablet.
    const isNewContract = useSelector(select.contract.isNewContract);
    const category = isNewContract
        ? attributes?.category
        : null;
    const variables = useSelector((state) => select.contract.getCollectiveAgreementVariables(state, category));

    // Tutkitaan löytyykö pohjasta jo yksittäisiä variableseja. Alkaa "collectiveAgreementVariables."
    const existingVariables = templateFieldNames
        .filter((fieldName) => fieldName.startsWith(`${fieldNames.COLLECTIVE_AGREEMENT_VARIABLES}.`))
        .map((fieldName) => fieldName.substring(`${fieldNames.COLLECTIVE_AGREEMENT_VARIABLES}.`.length));

    const variableList = useMemo(() => (Object.entries(variables ?? {}))
        .filter(([name]) => ! existingVariables.includes(name))
    , [variables]);

    if (isLoading) return null;

    const value = values[fieldNames.COLLECTIVE_AGREEMENT_VARIABLES];

    // Piilotetut TES-muuttujat
    const hiddenVariables = attributes?.hiddenVariables ?? [];

    return variableList
        .filter(([, { variable }]) => ! hiddenVariables.includes(variable.name))
        .map(([name, { variable }], key) => {
            const { type } = variable;
            // const formFieldLabel = fieldProps.label || _trans('contract.form.tes_variables.' + name + '.label');
            const label = fieldProps.label || _trans('contract.form.tes_variables.' + name + '.label');

            return (
                <SummaryListItem term={label} key={key}>
                    <Value ariaDescribedBy={name}>
                        {showValueByType(value[name], type)}
                    </Value>
                </SummaryListItem>
            );
        });
};

Variables.propTypes = {
    attributes: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    templateFieldNames: PropTypes.array.isRequired,
};

export default Variables;
