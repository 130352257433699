import _ from 'lodash';
import moment from 'moment';
import api from 'api';
import { noTaxCardTypes, taxCardTypes } from 'shared/constants/taxCardTypes';

// Tämä näkyy sen aikaa kun ladataan tietoja.
const placeholderTaxCards = [
    {
        taxCardId: 1,
        startDate: '2019-01-01',
        taxDeduction1: 10,
        incomeLimit1: 500,
    },
    {
        taxCardId: 2,
        startDate: '2019-01-01',
        taxDeduction1: 10,
        incomeLimit1: 500,
    },
];

export default {
    state: {
        taxCards: placeholderTaxCards,
        activeTaxCard: [],
        activeTaxCardId: null,
        usedTaxCards: [],
        veroFetchedTaxCards: [],
        relatedContracts: [],
        selectedTaxCardId: null,
        jobContractDataId: null,
        contractType: null,
        veroTaxCard: null,
        isEditMode: false,
        isVeroApiEnabled: false,
        isPrePaymentRegisterEmployee: false,
        openPayrollsWithOldTaxCard: [],
        isOverrideVeroFetch: false,
        taxCardTypeOverride: '',
        checkVeroTaxCardResults: [],
        contractTaxCardType: null,
        isCheckVeroTaxCardResultsSuccess: true
    },
    reducers: {
        setTaxCards: (state, payload) => ({ ...state, taxCards: payload }),
        setActiveTaxCard: (state, payload) => ({ ...state, activeTaxCard: payload }),
        setActiveTaxCardId: (state, payload) => ({ ...state, activeTaxCardId: payload }),
        setUsedTaxCards: (state, payload) => ({ ...state, usedTaxCards: payload }),
        setVeroFetchedTaxCards: (state, payload) => ({ ...state, veroFetchedTaxCards: payload }),
        setSelectedTaxCardId: (state, payload) => ({ ...state, selectedTaxCardId: payload }),
        setJobContractDataId: (state, payload) => ({ ...state, jobContractDataId: payload }),
        setContractType: (state, payload) => ({ ...state, contractType: payload }),
        setIsEditMode: (state, payload) => ({ ...state, isEditMode: payload }),
        setIsVeroApiEnables: (state, payload) => ({ ...state, isVeroApiEnabled: payload }),
        setRelatedContracts: (state, payload) => ({ ...state, relatedContracts: payload }),
        setIsPrePaymentRegisterEmployee: (state, payload) => ({ ...state, isPrePaymentRegisterEmployee: payload }),
        setOpenPayrollsWithOldTaxCard: (state, payload) => ({ ...state, openPayrollsWithOldTaxCard: payload }),
        setIsOverrideVeroFetch: (state, payload) => ({ ...state, isOverrideVeroFetch: payload }),
        setTaxCardTypeOverride: (state, taxCardTypeOverride) => ({ ...state, taxCardTypeOverride }),
        setContractTaxCardType: (state, contractTaxCardType) => ({ ...state, contractTaxCardType }),
        setCheckVeroTaxCardResults: (state, checkVeroTaxCardResults) => ({ ...state, checkVeroTaxCardResults }),
        setIsCheckVeroTaxCardResultsSuccess: (state, isCheckVeroTaxCardResultsSuccess) => ({ ...state, isCheckVeroTaxCardResultsSuccess }),
    },
    selectors: {
        isPrePaymentRegisterEmployee: (state) => state.isPrePaymentRegisterEmployee,
        getTaxCards: (state) => state.taxCards,
        getActiveTaxCard: (state) => state.activeTaxCard,
        getActiveTaxCardId: (state) => state.activeTaxCardId,
        isOverrideVeroFetch: (state) => state.isOverrideVeroFetch,
        getPendingTaxCards: (state) => state.taxCards.filter((taxCard) => filterTaxCard(state.activeTaxCard, taxCard, true)),
        getExpiredTaxCards: (state) => state.taxCards.filter((taxCard) => filterTaxCard(state.activeTaxCard, taxCard, false)),
        getPayrollIdsWithOldTaxCard: (state) => (state.openPayrollsWithOldTaxCard ?? []),
        getUsedTaxCards: (state) => state.usedTaxCards ?? [],
        getSelectedTaxCardId: (state) => state.selectedTaxCardId,
        getRelatedContracts: (state) => state.relatedContracts.map((relatedContract) => relatedContract.payer ),
        fetchVeroTaxCard: (state) => state.veroTaxCard,
        getTaxCardById: (state, taxCardId) => state.taxCards.find((taxCard) => taxCard?.taxCardId === taxCardId),
        isReadOnly: (state, taxCardId) => (state.usedTaxCards ?? []).includes(taxCardId),
        isVeroFetchedTaxCard: (state, taxCardId) => (state.veroFetchedTaxCards ?? []).includes(taxCardId),
        // lodash mäkeen tästä
        getLatestTaxCard: (state) => _.last(_.sortBy(state.taxCards, (taxCard) => moment(taxCard?.startDate ?? ''))),
        getContractType: (state) => state.contractType,
        isEditMode: (state) => state.isEditMode,
        isVeroApiEnabled: (state) => state.isVeroApiEnabled,
        getTaxCardTypeOverride: (state) => state.taxCardTypeOverride,
        getContractTaxCardType: (state) => state.contractTaxCardType,
        getCheckVeroTaxCardResults: (state) => state.checkVeroTaxCardResults,
        getIsCheckVeroTaxCardResultsSuccess: (state) => state.isCheckVeroTaxCardResultsSuccess,
    },
    effects: (dispatch) => ({
        /**
         * Haetaan verokortit ja jaotellaan ne omiin kategorioihin
         *
         * @param contractDataId
         * @returns {Promise<void>}
         */
        async fetchTaxCards(contractDataId) {
            try {
                const json = await api.get(
                    Routing.generate('api_1_get_contract_taxcards', { jobContractData: contractDataId, ...getCurrentTaxYear() })
                );
                // Eritellään tässä vaiheessa omiin lokeroihin.
                await this.setTaxCards(json?.taxcards ?? []);
                await this.setActiveTaxCard(json?.activeTaxCard ?? []);
                await this.setActiveTaxCardId(json?.activeTaxCardId);
                //Vähän muotoillaan dataa, koska bäkkäriltä se tulee objektina, jossa key ja value on samat.
                await this.setUsedTaxCards(Object.values(json?.usedTaxCards ?? {}));
                await this.setVeroFetchedTaxCards(Object.values(json?.veroFetched ?? {}));
                await this.setIsVeroApiEnables(json?.isVeroApiEnabled);
                await this.setRelatedContracts(json?.relatedContracts ?? []);
                await this.setIsPrePaymentRegisterEmployee(json.isPrePaymentRegisterEmployee);
                await this.setOpenPayrollsWithOldTaxCard(json.openPayrollsWithOldTaxCard);
                await this.setContractTaxCardType(json.contractTaxCardType);
                this.setTaxCardTypeOverride(json.taxCardTypeOverride ?? '');
            } catch (e) {
                // Nollataan kortit, jottei placeholder tavarat näy.
                await this.setTaxCards([]);
                dispatch.notifications.addError(_trans('tax_card.form.messages.fetch_error'));
                console.error(e);
            }
        },

        /**
         * Hakee Veroapista verokortin
         *
         * @param contractDataId
         * @param clearFetchOverRide
         * @returns {Promise<void>}
         */
        async fetchVeroTaxCard({ contractDataId: contractDataId, clearFetchOverRide: clearFetchOverRide }) {
            const response = await api.post(Routing.generate('api_1_post_contract_refresh_taxcard_veroapi', { jobContractData: contractDataId, clearFetchOverRide }));
            if (response.status === 'ok') {
                // ei dataa, eli ei päivitetty
                if (!response.data){
                    dispatch.notifications.addInfo(_trans('Haettu verokortti vastasi voimassa olevaa verokorttia. Muutoksia ei tehty.', {}, 'extract'));
                } else if (response.data === 'fetched_card_is_not_active') {
                    dispatch.notifications.addSuccess(_trans('Verokortti haettiin onnistuneesti OmaVerosta.', {}, 'extract'));
                } else {
                    dispatch.notifications.addSuccess(_trans('Verokortti haettiin onnistuneesti OmaVerosta ja otettiin käyttöön', {}, 'extract'));
                }
            }
            else {
                const message = response?.message ?? 'tax_card.form.messages.fetch_error';

                dispatch.notifications.addError(_trans(message));
            }

            await this.fetchTaxCards(contractDataId);
        },

        /**
         * Hakee Veroapista kaikki kortit, ei tallenna niitä. Käytetään tarkistamiseen mitä verokortteja tulonsaajalla on voimassa
         *
         * @param contractDataId
         * @param clearFetchOverRide
         * @returns {Promise<void>}
         */
        async checkVeroTaxCard({ contractDataId: contractDataId }) {
            const response = await api.post(Routing.generate('api_1_post_contract_check_taxcard_veroapi', { jobContractData: contractDataId }));
            if (response.status === 'ok') {
                this.setCheckVeroTaxCardResults(response.data);
                this.setIsCheckVeroTaxCardResultsSuccess(true);
            }
            else {
                this.setIsCheckVeroTaxCardResultsSuccess(false);
                const message = response?.message ?? 'tax_card.form.messages.fetch_error';

                dispatch.notifications.addError(_trans(message));
            }
        },

        /**
         * Uuden verokortin lisäys taikka muokkaus.
         * Melkein varmaa voisi refactoroida API:t, kun postilla lisätään sekä muokataan...
         *
         * @param model
         * @param rootState
         * @param taxCardId
         * @returns {Promise<void>}
         */
        async postTaxCard(model, rootState, taxCardId) {
            try {
                model = await resolvePostBody(model);
                const response = await api.post(Routing.generate('api_1_post_contract_taxcards', {
                    taxCard: taxCardId,
                    jobContractData: rootState.taxCards.jobContractDataId,
                }), model);
                if (response?.error) {
                    dispatch.notifications.addError(response?.message);
                    return;
                }
                await this.fetchTaxCards(rootState.taxCards.jobContractDataId);
                await this.setSelectedTaxCardId(null);
                await this.setIsOverrideVeroFetch(true);
                dispatch.notifications.addSuccess(_trans('tax_card.form.messages.save_success'));
            } catch (e) {
                dispatch.notifications.addError(_trans('tax_card.form.messages.save_error'));
                console.error(e);
            }
        },

        /**
         * Poistaa käyttämättömän verokortin
         *
         * @param taxCardId
         * @param rootState
         * @returns {Promise<void>}
         */
        async deleteTaxCard(taxCardId, rootState) {
            try {
                await api.del(Routing.generate('api_1_delete_taxcard', {
                    taxCard: taxCardId,
                    contractData: rootState.taxCards.jobContractDataId,
                }));
                await this.fetchTaxCards(rootState.taxCards.jobContractDataId);
                await this.setSelectedTaxCardId(null);
                await this.setIsOverrideVeroFetch(true);
                await dispatch.notifications.addSuccess(_trans('tax_card.form.messages.delete_success'));
            } catch (e) {
                console.error(e);
                await dispatch.notifications.addError(_trans('tax_card.form.messages.delete_error'));
            }
        },
    })
};

/**
 * Resolve valitun tyypin perusteella modellin, joka postataan.
 *
 * @param model
 * @returns {Promise<object>}
 */
const resolvePostBody = (model) => new Promise((resolve) => {
    switch (model.type) {
        case taxCardTypes.NO_TAX_CARD:
        case taxCardTypes.COMPENSATION_NO_TAX_CARD_FAMILY_CARE:
        case taxCardTypes.COMPENSATION_NO_TAX_CARD_RELATIVE_CARE:
        case taxCardTypes.COMPENSATION_NO_TAX_CARD_PERSONAL:
            resolve(_.pick(model, ['taxDeduction1', 'type']));
            break;
        case taxCardTypes.TYPE_B:
        case taxCardTypes.COMPENSATION_TAX_CARD_FAMILY_CARE:
        case taxCardTypes.COMPENSATION_TAX_CARD_RELATIVE_CARE:
            resolve(_.pick(model, ['type', 'startDate', 'incomeLimit1', 'taxDeduction1', 'taxDeduction2', 'previousSalary', 'isOverrideVeroFetch']));
            break;
        case taxCardTypes.TAX_AT_SOURCE:
            // Poistetaan kenttiä, joita ei tarvita
            resolve(_.omit(model, ['incomeLimit1', 'taxDeduction2', 'attributes', 'created', 'edited', 'isImported', 'taxCardId', 'isVeroFetched']));
            break;
        default:
            resolve(model);
            break;
    }
});

const filterTaxCard = (activeTaxCard, taxCard, isPending) => {
    if (activeTaxCard.length === 0) {
        return isPending ? taxCard.isPending : ! taxCard.isPending;
    }

    if (activeTaxCard.taxCardId === taxCard.taxCardId) {
        return false;
    }

    if (noTaxCardTypes.includes(taxCard.type) && ! noTaxCardTypes.includes(activeTaxCard.type)) {
        return ! isPending;
    }

    if (taxCard.isVeroFetched && taxCard.originalVeroTaxCard && ! taxCard.isActiveVeroTaxCard) {
        return !isPending;
    }

    // Jos hakua ei ole ohitettu, voimaan tulevat tulee olla apista tullu kortti
    if (! activeTaxCard.isOverrideVeroFetch && ! taxCard.isVeroFetched && ! taxCard.isOverrideVeroFetch) {
        if (new Date(activeTaxCard.startDate) < new Date(taxCard.startDate)) {
            return isPending;
        }
        return ! isPending;
    }

    // Näytetään tulossa olevan jos on seauraavalle vuodelle, kuin voimassa oleva
    if (new Date(activeTaxCard.startDate).getFullYear() < new Date(taxCard.startDate).getFullYear()) {
        return !!isPending;
    }

    // Jos kortti on edelliselle vuodelle kuin aktiivinen, ei näytetä tulossa olevana.
    if (new Date(activeTaxCard.startDate).getFullYear() > new Date(taxCard.startDate).getFullYear()) {
        return !isPending;
    }

    if (new Date(activeTaxCard.startDate) < new Date(taxCard.startDate)) {
        if (! activeTaxCard.isVeroFetched || ! taxCard.isVeroFetched || new Date(activeTaxCard.veroFetchDate) < new Date(taxCard.veroFetchDate)) {
            return !!isPending;
        }
    }

    if (new Date(activeTaxCard.startDate) < new Date(taxCard.startDate) && new Date(activeTaxCard.veroFetchDate) > new Date(taxCard.veroFetchDate)) {
        return ! isPending;
    }

    if (activeTaxCard.isOverrideVeroFetch && taxCard.isVeroFetched) {
        return !isPending;
    }

    if (taxCard.isOverrideVeroFetch && activeTaxCard.isVeroFetched) {
        return !isPending;
    }

    if (isPending) {
        // Kumpikaan ei ole veroapista tullut
        if (! activeTaxCard.isVeroFetched && ! taxCard.isVeroFetched) {
            return taxCard.isPending;
        }

        return taxCard.isPending;
    }

    // Kumpikaan ei ole veroapista tullut
    if (! activeTaxCard.isVeroFetched && ! taxCard.isVeroFetched) {
        return taxCard.taxCardId !== activeTaxCard.taxCardId && ! taxCard.isPending;
    }

    // Kumpikin on tullu apista
    if (activeTaxCard.isVeroFetched && taxCard.isVeroFetched) {
        // Jos haku päivä on suurempi kuin aktiivisen hakupäivä. Jos hakupäivä on aiempi kuin aktiivisen
        return new Date(taxCard.veroFetchDate) < new Date(activeTaxCard.veroFetchDate);
    }

    return ! taxCard.isPending;
};

/**
 * Palauttaa tämän hetkisen verovuoden alku- sekä loppupvm. (tämän vuoden alku - ens vuoden tammikuun loppu)
 *
 * @returns {{endDate: ISODate, startDate: ISODate}}
 */
const getCurrentTaxYear = () => ({
    startDate: moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD'),
    // endDate: moment().startOf('year').add(1, 'years').endOf('month').format('YYYY-MM-DD'),
});

