import { getBlockProps } from 'shared/ReForm/utils/getBlockProps';
import { choiceInputTypes, inputTypes } from 'shared/ReForm/constants/inputTypes';
import { blocks as blockTypes, isGroupBlock } from 'shared/ReForm/constants/blocks';
import { getCustomComponentByFieldName } from 'ContractV3/utils/getCustomComponentByFieldName';
import { ARRAY_INDICATOR } from 'shared/ReForm/utils/isBlockVisible';

/**
 * Kaivaa pohjasta käytetyt kentät.
 * Käytetään esim. TES:n Variables-palikalla selvittämään mitkä variablet vielä näytettävä.
 * @param json
 * @param isInsideArray
 */
export const getTemplateFieldNames = (json, isInsideArray = false) => json
    // block-tyyliset ryhmät (group, fieldset) pois jotta isInsideArray menee myöhemmässä reducessa oikein
    // (ei häviä seuraavassa iteraatiossa)
    .reduce((acc, item) => {
        const { blocks } = getBlockProps(item);
        return Array.isArray(blocks) ? acc.concat(blocks) : acc.concat(item);
    }, [])
    .reduce((acc, item) => {
        const { name, blocks } = getBlockProps(item);

        if (Array.isArray(blocks)) {
            return Object.assign({}, acc, getTemplateFieldNames(blocks, isGroupBlock(item.block)));
        }

        if (! name) return acc;

        const title = item.block === blockTypes.COMPONENT || item.type === inputTypes.COMPONENT
            ? getCustomComponentByFieldName(name)?.schema.title ?? name
            : item.label ? _transObj(item.label) : name;

        const isChoiceInputType = choiceInputTypes.includes(item?.type);

        const blockProps = getBlockProps(item);
        // Annetaan palikan avaimeksi oikealla etuliitteellä oleva name mikäli se on array-palikan sisällä
        const blockName = isInsideArray ? `${ARRAY_INDICATOR}${name}` : name;

        // Nimi löytyy eikä sitä ole annettu presetDatassa
        return name
            ? Object.assign({}, acc, {
                [blockName]: {
                    title,
                    name: blockName,
                    isInsideArray,
                    validationType: blockProps?.validationType,
                    type: item?.type ?? item?.block,
                    ...((item?.type === inputTypes.COMPONENT || item?.block === blockTypes.COMPONENT) && { component: item.component }),
                    ...(isChoiceInputType && { type: item.type }),
                    ...(isChoiceInputType && { options: item.options })
                }
            })
            : acc;
    }, {});
