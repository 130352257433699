import React, { useState, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { StField, StHelp, StLabel } from 'shared/components/StForm';
import { FormikErrors } from 'shared/components/Formik';
import { FormikDateRangeInput } from 'shared/components/Formik';
import FormikMaxTextArea from 'shared/components/Formik/FormikMaxTextArea';
import FormikAutoComplete from 'shared/components/Formik/FormikAutoComplete';
import Switch from 'shared/components/Switch';
import { Collapse } from 'shared/components/Collapse';
import { ContractOptionRenderer } from 'shared/containers/AutoCompleteVirtualized/components/ContractOptionRenderer';
import validators, { errorMessages } from 'shared/validators';
import ActionBar from 'shared/components/ActionBar';
import SubmitButton from 'shared/components/SubmitButton';
import Button from 'shared/components/Button';
import { leavePeriodTypes, translateLeavePeriodType } from 'shared/constants/leavePeriodTypes';

export const LeavePeriodForm = ({ initialState, onSubmit, onCancel }) => {
    const { id, type, startDate, endDate, description = '', jobContract = null } = initialState;

    // Oletuksena pois päältä.
    const [isAffectingAll, setIsAffectingAll] = useState(id && !jobContract);
    const isSaving = useSelector((state) => (
        state.loading.effects.leavePeriods.putLeavePeriod
        || state.loading.effects.leavePeriods.postLeavePeriod
    ));

    // Päivämäärät joita ei voi valita = aiemmat poissaolojaksot
    const unavailableDateRanges = useSelector((state) => select.leavePeriods.getLeavePeriodDateRanges(state, id));
    const employerContracts = useSelector(select.leavePeriods.getEmployeeContracts);
    const hasOnlyOneContract = employerContracts.length === 1;

    const getUnavailableRangeDates = useCallback((jobContract) => {
        if (jobContract) {
            return unavailableDateRanges.filter((range) => range.jobContract === jobContract || ! range.jobContract);
        }

        return unavailableDateRanges;
    }, [unavailableDateRanges]);

    return (
        <Formik
            onSubmit={(model) => onSubmit(model)}
            initialValues={{
                type,
                dateRange: {
                    start: startDate,
                    end: endDate,
                },
                description,
                // Jos löytyy vain yksi soppari valitaan se automaattisesti
                jobContract: !jobContract && employerContracts.length === 1
                    ? employerContracts[0].jobContractId
                    : jobContract,
            }}
            validationSchema={Yup.object().shape({
                type: Yup.number().required(_trans('validation.required')),
                dateRange: Yup.object()
                    .required(_trans('validation.required'))
                    .test('isDate', errorMessages.isDateRange, validators.isDateRange)
                    // Jos poissaolojakso kohdistuu vain yhteen soppariin, vertaillaan vain samaan tai kaikkiin
                    // soppareihin liittyvien poissaolojaksojen ajankohtia.
                    .when('jobContract', (jobContract, schema) => schema.test(
                        'isDateRangeUnavailable',
                        _trans('leave_period.error.validation.overlap'),
                        (value) => validators.isDateRangeUnavailable(value, getUnavailableRangeDates(jobContract))
                    )),
                description: Yup.string().max(160),
                jobContract: Yup
                    .mixed()
                    .test('isNumeric', _trans('leave_period.form.validations.job_contract_is_required'), (value) => validators.isRequired(value) || isAffectingAll),
            })}
        >
            {({ setFieldValue, values }) => (
                <Form className="o-form o-form--responsive">
                    <StLabel htmlFor="type" isRequired>
                        {_trans('leave_period.period_type.label')}
                    </StLabel>
                    <StField>
                        {(type && !id)
                            ? (
                                <b>{translateLeavePeriodType(parseInt(type, 10))}</b>
                            ) :
                            (
                                <Fragment>
                                    <Field as="select" name="type" id="type">
                                        <option selected="true" disabled="disabled" value={null}>
                                            {_trans('leave_period.period_type.select_type')}
                                        </option>
                                        {Object.values(leavePeriodTypes).map((leavePeriodType) => (
                                            <option value={leavePeriodType} key={leavePeriodType}>
                                                {translateLeavePeriodType(leavePeriodType)}
                                            </option>
                                        ))}
                                    </Field>
                                    <FormikErrors name="type" />
                                </Fragment>
                            )
                        }
                    </StField>
                    <StLabel isRequired>
                        {_trans('leave_period.affects_all.label')}
                    </StLabel>
                    <StField isLabeless>
                        {employerContracts.length > 1 && (
                            <Switch
                                id="allContractsSwitch"
                                modifierClass="u-margin-bottom-small"
                                defaultChecked={isAffectingAll}
                                onChange={(isOn) => {
                                    if (isOn) {
                                        setFieldValue('jobContract', null);
                                    }
                                    setIsAffectingAll(isOn);
                                }}
                            >
                                {_trans('leave_period.affects_all.checkbox_label')}
                            </Switch>
                        )}

                        <Collapse isOpen={! isAffectingAll}>
                            <label htmlFor="jobContract">
                                {_trans('leave_period.contract.label')}
                            </label>
                            <FormikAutoComplete
                                id="jobContract"
                                name="jobContract"
                                optionRenderer={ContractOptionRenderer}
                                labelKey="employerName"
                                valueKey="jobContractId"
                                items={employerContracts}
                                placeholder={_trans('leave_period.contract.placeholder')}
                                // filterOptions={this.filterUserList}
                                size="large"
                                hasSingleValue
                                isClearable={! hasOnlyOneContract}
                            />
                            <FormikErrors name="jobContract" />
                        </Collapse>
                    </StField>
                    <StLabel isRequired htmlFor="dateRange">
                        {_trans('leave_period.date_range.label')}
                    </StLabel>
                    <StField>
                        <FormikDateRangeInput
                            id="dateRange"
                            name="dateRange"
                            unavailableDates={getUnavailableRangeDates(values.jobContract)}
                        />
                        <FormikErrors name="dateRange" />
                    </StField>
                    <StLabel htmlFor="description">
                        {_trans('leave_period.description.label')}
                    </StLabel>
                    <StField>
                        <FormikMaxTextArea
                            name="description"
                            aria-describedby="descriptionHelp"
                            id="description"
                            rows={4}
                            maxLength={160}
                            modifierClass="u-1/1"
                        />
                        <StHelp id="descriptionHelp">
                            {_trans('leave_period.description.help')}
                        </StHelp>
                        <FormikErrors name="description" />
                    </StField>
                    <ActionBar>
                        <Button preventDefault onClick={onCancel}>{_trans('button.cancel')}</Button>
                        <SubmitButton isPending={isSaving}>{_trans('button.save')}</SubmitButton>
                    </ActionBar>
                </Form>
            )}
        </Formik>
    );
};

LeavePeriodForm.propTypes = {
    initialState: PropTypes.object,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    isSaving: PropTypes.bool,
};

LeavePeriodForm.defaultProps = {
    initialState: {},
    onSubmit() {},
    onCancel: null,
    isSaving: false,
};
