import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import userRoles from 'shared/constants/userRoles';
import eventKeys from 'shared/constants/eventKeys';
import validators, { errorMessages } from 'shared/validators';
import { AutoCompleteField } from 'shared/components/Formik';

/**
 * Osapuolen valitsin. Haku asynkronisesti.
 * Jos hetulla haettuna osapuolta ei löydy voidaan uusi osapuoli lisätä Enterillä.
 * @param name
 * @param isPending
 * @param placeholder
 * @param asyncEndPoint
 * @param asyncQueryString
 * @param filterResults
 * @param filterOptions
 * @param userRole
 * @param noResultsText
 * @param noResultsAddNewText
 * @param isSsnQuery
 * @param addNewPartyUrl
 * @returns {JSX.Element}
 * @constructor
 */
export const PartySelector = ({ name, isPending, placeholder, asyncEndPoint, isSsnQuery, filterResults, filterOptions, userRole, noResultsText, noResultsAddNewText, onSelectParty, addNewPartyUrl }) => {
    const dispatch = useDispatch();
    const [ssn, setSsn] = useState('');

    /**
     * Päivitetään hetu stateen. Jos käyttäjää ei löydy hetulla voidaan
     * luoda uusi käyttäjä annetulla hetulla.
     * @param ssn
     */
    const onAutoCompleteInputChange = (ssn) => {
        updateSsn(ssn);
    };

    /**
     * Jos syötetty ssn on validi ja painetaan enter siirrytään automattisesti uuden työntekijän
     * lisäyssivulle syötetty hetu esitäytettynä (jos ollaan yritys tai kunta).
     * @param event
     */
    const onAutoCompleteInputKeyDown = (event) => {
        if (event.key === eventKeys.ENTER
            && validators.isSsn(ssn)
            && [userRoles.COMPANY_OPERATOR, userRoles.COMMUNE_OPERATOR, userRoles.EMPLOYER].includes(userRole)) {
            console.log(ssn, addNewPartyUrl);
            dispatch.contract.setInitialPartySsn(ssn);
            dispatch(push(addNewPartyUrl));
        }
    };

    /**
     * Tyhjennetään uuden työntekijän esitiedot autocompletesta lähdettäessä.
     * Tyhjennys timeoutilla siitä syystä että jos käyttäjä päättääkin lisätä
     * uuden työntekijän annetulla (validilla) hetulla ei hetua poisteta
     * heti storesta autocompletesta lähdettäessä.
     */
    const onAutocompleteBlur = () => {
        setTimeout(() => {
            dispatch.contract.resetInitialPartySsn();
        }, 125);
    };

    /**
     * Päivitetään staten ssn jos käyttäjä on yritys- tai kunta-roolissa.
     * Kotitalousroolissa kysytään vielä erikseen Oima-tilistä joten suoraviivainen käyttäjän lisääminen
     * ei ole hyvä ratkaisu.
     * @param ssn
     */
    const updateSsn = (ssn = '') => {
        if (ssn !== '') {
            setSsn(ssn);
        }
    };

    // Onko hetumainen, mutta ei tarkista onko validi
    const isSsnLike = /^\d{6}([-A+])\d{3}[\dA-Z]$/gm.exec(ssn.toUpperCase());
    const isValidSsn = ssn !== '' && validators.isSsn(ssn);
    const ssnNoResultsText = isValidSsn
        ? `${noResultsText}. ${noResultsAddNewText} ${_trans('painamalla Enter.', {}, 'extract')}`
        : errorMessages.isSsn;

    return (
        <AutoCompleteField
            id={name}
            name={name}
            isAsync
            asyncEndPoint={asyncEndPoint}
            asyncQueryString={isSsnQuery ? 'ssn' : 'q'}
            filterResults={filterResults}
            filterOptions={filterOptions}
            placeholder={placeholder}
            labelKey={(isSsnLike || isSsnQuery) ? 'socialSecurityNumber' : 'fullName'}
            valueKey="userId"
            optionRenderer="employee"
            noResultsText={(isSsnLike || isSsnQuery) ? ssnNoResultsText : noResultsText}
            onInputChange={onAutoCompleteInputChange}
            onInputKeyDown={onAutoCompleteInputKeyDown}
            onBlur={onAutocompleteBlur}
            isDisabled={isPending}
            onSelectAsync={onSelectParty}
            isPostRequest={isSsnQuery}
        />
    );
};

PartySelector.propTypes = {
    name: PropTypes.string.isRequired,
    userRole: PropTypes.number.isRequired,
    placeholder: PropTypes.string.isRequired,
    isPending: PropTypes.bool.isRequired,
    noResultsText: PropTypes.string.isRequired,
    noResultsAddNewText: PropTypes.string.isRequired,
    asyncEndPoint: PropTypes.string,
    isSsnQuery: PropTypes.bool,
    onSelectParty: PropTypes.func.isRequired,
    /**
     * Mahdollinen haun jälkeen tehtävä filtteröinti tuloksille.
     */
    filterResults: PropTypes.func,
    filterOptions: PropTypes.func,
    addNewPartyUrl: PropTypes.string.isRequired,
};

PartySelector.defaultProps = {
    asyncEndPoint: '',
    isSsnQuery: false,
    filterResults: (results) => results,
    filterOptions: null,
};

