import api from 'api';
import { createQueryParams } from 'shared/utils/commonUtils';

export const documents = {
    state: {
        categories: [],
        documents: [],
        deletedDocuments: [],
        expiredDocuments: [],
    },
    reducers: {
        setCategories: (state, categories) => ({ ...state, categories }),
        setDocuments: (state, documents) => ({ ...state, documents }),
        setDeletedDocuments: (state, deletedDocuments) => ({ ...state, deletedDocuments }),
        setExpiredDocuments: (state, expiredDocuments) => ({ ...state, expiredDocuments }),
    },
    selectors: {
        getCategories: (state) => state.categories,
        getDocuments: (state) => state.documents,
        getDeletedDocuments: (state) => state.deletedDocuments,
        getDocumentCount: (state) => state.documents.length,
        getDeletedDocumentsCount: (state) => state.deletedDocuments.length,
        getExpiredDocuments: (state) => state.expiredDocuments,
        getExpiredDocumentsCount: (state) => state.expiredDocuments.length,
    },
    effects: (dispatch) => ({
        async fetchContractCategories(jobContract) {
            try {
                const json = await api.get(`/api/v3/categories/contracts/${jobContract}`);
                this.setCategories(json.data ?? []);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
        async fetchPayPeriodCategories(payPeriod) {
            try {
                const json = await api.get(`/api/v3/categories/pay-periods/${payPeriod}`);
                this.setCategories(json.data ?? []);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
        async fetchCategories(types) {
            try {
                const json = await api.get('/api/v3/categories?' + createQueryParams({ types }, { omitEmptyValues: true }));
                this.setCategories(json.data ?? []);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
        async postCategories(model) {
            try {
                return await api.post('/api/v3/categories', model);
            } catch (error) {
                return { status: 'error' };
            }
        },
        async fetchDocuments({ categoryType = null, relatedId = null, ssn = null } = {}) {
            try {
                const queryParams = createQueryParams(
                    { categoryType, relatedId },
                    { omitEmptyValues: true }
                );

                // Sama endpoint mutta ssn bodyssä mikäli se osa hakua
                const route = ssn ? '/api/v3/documents-by-ssn?' : '/api/v3/documents?';
                const json = await (ssn ? api.post(route + queryParams, { ssn }) : api.get(route + queryParams));
                this.setDocuments(json.data ?? []);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
        async fetchDeletedDocuments() {
            try {
                const json = await api.get('/api/v3/documents?onlyDeleted=1');
                this.setDeletedDocuments(json.data ?? []);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
        async fetchExpiredDocuments(expiresBeforeDate) {
            try {
                const json = await api.get(`/api/v3/documents?expiresBefore=${expiresBeforeDate}`);
                this.setExpiredDocuments(json.data ?? []);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
        async deleteDocument(uuid) {
            try {
                return await api.del(`/api/v3/documents/${uuid}`);
            } catch (error) {
                return { status: 'error' };
            }
        },
        async deleteMultipleDocuments(documents) {
            try {
                return await api.del(`/api/v3/documents/multiple`, documents);
            } catch (error) {
                return { status: 'error' };
            }
        },
        async deleteCategory(uuid) {
            try {
                return await api.del(`/api/v3/categories/${uuid}`);
            } catch (error) {
                return { status: 'error' };
            }
        },

        async updateCategory(uuid, rootState, model) {
            try {
                return await api.put(`/api/v3/categories/${uuid}`, model );
            } catch (error) {
                return { status: 'error' };
            }
        },
        async undoDeleteDocument(uuid) {
            try {
                return await api.patch(`/api/v3/documents/${uuid}/undo-deletion`);
            } catch (error) {
                return { status: 'error' };
            }
        },
        async putDocument(document) {
            try {
                const { uuid, ...values } = document;
                return await api.put(`/api/v3/documents/${uuid}`, values);
            } catch (error) {
                dispatch.notifications.addError(`${_trans('Dokumentin päivittäminen ei onnistunut.', {}, 'documents')} ${error ?? ''}`);
            }
        },
    })

};
