export const userSelfEditDataSettingTypes = {
    first_name: _trans('Etunimi', {}, 'common'),
    last_name: _trans('Sukunimi', {}, 'common'),
    street_address: _trans('Katuosoite', {}, 'common'),
    post_code: _trans('Postinumero', {}, 'common'),
    town: _trans('Postitoimipaikka', {}, 'common'),
    phone_number: _trans('Puhelinnumero', {}, 'common'),
    email: _trans('Sähköpostiosoite', {}, 'common'),
    account_number: _trans('Tilinumero', {}, 'common')
};

export const userSelfEditDataSettingField = {
    nothing : 'Ei mitään',
    confirm: 'Vaadi vahvistus',
    notify: 'Huomautus',
};

export const translateUserSelfEditDataSettingTypes = Object.entries(userSelfEditDataSettingTypes);
export const translatedUserSelfEditDataSettingFields= Object.entries(userSelfEditDataSettingField);
