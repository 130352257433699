import React, { Component } from 'react';
import selectorsPlugin from '@rematch/select';
import createLoadingPlugin from '@rematch/loading';
import { createForms, actions } from 'react-redux-form/lib/immutable';
import { init } from '@rematch/core';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import ui from './models/ui';
import { userActions } from './stores/user';
import { userMetadata, tradeUnion, employeeWorkExperience, dimensions, notifications } from 'shared/models/index';
import initialUser from 'shared/UserDetails/stores/user';
import ThunkNotifications from 'shared/containers/ThunkNotifications';
import ModuleManager from 'shared/UserDetails/containers/ModuleManager';
import notificationReducer from 'shared/stores/notifications';
import correctionDebt from 'PayrollCorrection/models/correctionDebt';
import Notifications from 'shared/containers/Notifications';
import { documents } from 'shared/models/documents';
import { userDataProposal } from 'shared/models/userDataProposal';

const store = init({
    name: 'UserDetails',
    redux: {

        reducers: {
            ...createForms({
                ...initialUser,
            }),
            thunkNotifications: notificationReducer,
        },

        middlewares: [
            thunk,
        ],
    },

    models: {
        ui,
        userMetadata,
        tradeUnion,
        employeeWorkExperience,
        correctionDebt,
        dimensions,
        notifications,
        documents,
        userDataProposal,
    },

    plugins: [
        selectorsPlugin(),
        createLoadingPlugin(),
    ]
});

class UserDetailsContainer extends Component {

    getUserIdFromURL() {
        //Vähän purkkanen kun webpack + hmr ei tue twigiltä propsien passailua
        const id = /\/(\d+)$/gm.exec(document.location.pathname);
        if (id) {
            return id[1];
        }
        return null;
    }

    componentDidMount() {
        const userId = +this.getUserIdFromURL();
        //Asetetaan userId samantien storeen, jotta mahdolliset muut komponentit ehtivät saada userId:n
        if (userId) {
            store.dispatch(actions.change('user.userId', +userId));
        }
        store.dispatch(userActions.fetchSelfUserData());
        store.dispatch(userActions.fetchAllowedUserDataSelfUpdateCustomerRegistryHyveNames());
        store.dispatch.userMetadata.fetchUserMetadata();
    }

    render() {
        return (
            <Provider store={store}>
                <div>
                    <ModuleManager isPrivateUserDetails />
                    <ThunkNotifications />
                    <Notifications />
                </div>
            </Provider>
        );
    }
}

app.render(UserDetailsContainer);

export default hot(UserDetailsContainer);
