import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { ChoiceField } from 'shared/components/Formik/ChoiceField';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { FormField } from 'shared/components/Formik';
import { Value } from 'shared/components/Value';

/**
 * TES-palikka jota muut palikat (SalaryType, PayPeriodLength, PaydayMode ym.) käyttävät.
 * @param primaryType
 * @param restrictionKey
 * @param translator
 * @param fieldProps
 * @param filteredValues
 * @returns {JSX.Element|null}
 * @constructor
 */
export const CollectiveAgreementChoiceField = ({ primaryType, restrictionKey, translator, fieldProps, filteredValues }) => {
    const { name, defaultValue, isValueFixed } = fieldProps;
    const [,,helpers] = useField(name);
    // Onko OPH
    const isAssignmentContract = useSelector(select.contract.isAssignmentContract);
    // Haetaan rajoitteet
    const restrictions = useSelector((state) => select.contract.getCollectiveAgreementRestrictionsByKey(state, restrictionKey));

    const primaryTypeValue = parseInt(primaryType, 10);
    const primaryRestriction = restrictions.find((restriction) => parseInt(restriction?.primaryType, 10) === primaryTypeValue);
    const translationDomain = isAssignmentContract ? 'assignment_contract' : 'contract';

    // Jos valintoja vain yksi eikä mitään ole tälle kentälle vielä valittu => valitaan suoraan se ainut vaihtoehto
    const allowedSubtypes = useMemo(() => primaryRestriction?.allowedSubtypes ?? [], [primaryRestriction]);

    useEffect(() => {
        if (allowedSubtypes.length === 1 && ! isValueFixed) {
            helpers.setValue(allowedSubtypes[0]?.toString());
        }
        if (isValueFixed) {
            helpers.setValue(defaultValue?.toString());
        }
    }, [allowedSubtypes, isValueFixed, defaultValue]);

    const options = useMemo(() => (
        allowedSubtypes
            .filter((allowedSubType) => ! filteredValues.includes(parseInt(allowedSubType, 10)))
            .map((allowedSubType) => ({
                value: allowedSubType.toString(),
                label: translator(allowedSubType, translationDomain),
            }))
    ), [allowedSubtypes, filteredValues, translationDomain, translator]);

    return (
        <FormField {...getFieldProps(fieldProps)} isContentFormField={allowedSubtypes.length > 1 && ! isValueFixed}>
            {/*Löytyi vain yksi. Implikoidaan se valituksi suoraan.*/}
            {allowedSubtypes.length === 1 || isValueFixed
                ? (
                    <Value>
                        {isValueFixed
                            ? translator(parseInt(defaultValue, 10), translationDomain)
                            : translator(allowedSubtypes[0], translationDomain)}
                    </Value>
                )
                : (
                    <ChoiceField
                        name={name}
                        options={options}
                    />
                )}
        </FormField>
    );
};

CollectiveAgreementChoiceField.propTypes = {
    primaryType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    restrictionKey: PropTypes.string.isRequired,
    /**
     * Minkä kääntäjän läpi valinnat runtataan.
     */
    translator: PropTypes.func.isRequired,
    /**
     * Kentälle menevät propit
     */
    fieldProps: PropTypes.object.isRequired,
    /**
     * Filtteröitävät arvot. Esim. palkkioperusteesta voidaan haluta poistaa kuukausiliksa.
     */
    filteredValues: PropTypes.array,
};

CollectiveAgreementChoiceField.defaultProps = {
    filteredValues: [],
};
