import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import Button from 'shared/components/Button';
import MDIcon from 'shared/components/MDIcon';
import { signatureTypes } from 'shared/constants/signatureTypes';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { Value } from 'shared/components/Value';
import { useGet } from 'shared/hooks/useApi';
import { Placeholder } from 'shared/components';


const findSignatureByUserId = ({ signatures, signerId, employerSignatureType }) => (
    signatures.find((signer) => signer?.type === employerSignatureType && signer.signerId === signerId)
);

/**
 * Työnantajan allekirjoitus.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const ReportingFormsSignaturesEmployer = (props) => {
    const { value, apiEndPoint, employerSignatureType } = props;
    const [hasFetched, setHasFetched] = useState(false);
    const dispatch = useDispatch();

    const loggedUserId = useSelector(select.userMetadata.getUserId);
    const template = useSelector(select.reportingForms.getReportingFormTemplate);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isSigning = useSelector((state) => state.loading.effects.postSignature);
    const reportingFormId = useSelector(select.reportingForms.getReportingFormId);
    const isOperator = useSelector(select.userMetadata.isOperator);
    const isEmployee = useSelector(select.userMetadata.isEmployee);
    const canEmployeeFillForm = useSelector(select.reportingForms.canEmployeeFillForm);

    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const isProposalReportingForm = useSelector(select.reportingForms.isProposalReportingForm);
    const isBuildMode = useSelector((state) => state.hasOwnProperty('contractBuilder'));
    const signatures = useSelector(select.reportingForms.getSignatures);

    const [currentSignerId, setCurrentSignerId] = useState(parseInt(value, 10));
    const signature = findSignatureByUserId({ signatures, signerId: currentSignerId, employerSignatureType });
    const signTime = signature?.signTime;

    const summaryLabel = _trans('Hyvinvointialueen edustaja', {}, 'jobContract');

    // Allekirjoitettu
    const isSigned = signature?.isSigned ?? false;

    // Pääkäyttäjä tahi prokura
    const canAuthorizeUsers = useSelector(select.userMetadata.canAuthorizeUsers);
    const [{ isLoading, data }, makeRequest] = useGet(`${apiEndPoint}/${template?.id}`, { lazy: true });

    useEffect(() => {
        if (isOperator && ! hasFetched) {
            makeRequest().then(() => {
                setHasFetched(true);
            });
        }
    }, [hasFetched, isOperator]);

    // Jos TA-allekirjoittajaa ei valittuna vedetään kirjautuneesta käyttäjästä.
    const employerUserId = parseInt(loggedUserId, 10);
    const employers = useMemo(() => {
        const employerDataKey = employerSignatureType === signatureTypes.TYPE_COMMUNE_USER_SPECIFIC
            ? 'customer'
            : 'userData';
        return (data?.data ?? []).map((employer) => employer[employerDataKey] ?? {});
    }, [data?.data, employerSignatureType]);

    // Täyttä skeidaa näin tarkistella.
    // Tässä siis hoitaja/työntekijä on luonut lomakkeen (oikeus luoda lomakkeita päällä) jolloin operaattoriksi
    // on merkattu ei-kukaan (userId -1).
    const hasOperatorBeenSelected = value !== '-1';
    const hasSignatureRight = employers.some(({ userId }) => [currentSignerId, loggedUserId].includes(userId));

    const signerDetails = useMemo(() => {
        if (hasOperatorBeenSelected) {
            return signature?.signerDetails;
        }

        return employers.find(({ userId }) => userId === employerUserId);
    }, [value, employers, canEmployeeFillForm, employerUserId, signature]);

    useEffect(() => {
        if (! hasFetched) return;

        if (! hasSignatureRight && isOperator && isProposalReportingForm && hasOperatorBeenSelected) {
            dispatch.notifications.addWarning(_trans('Hyvinvointialueen allekirjoittavaa edustajaa ei löydy. Muokkaa lomaketta ja valitse uusi allekirjoittaja.', {}, 'jobContract'));
        }
    }, [hasFetched, hasSignatureRight, isOperator, isProposalReportingForm, isLoading, hasOperatorBeenSelected]);

    // Onko samat käyttäjäid:t.
    const isAllowedToSign = (loggedUserId === currentSignerId) || (loggedUserId === signerDetails?.userId);

    if (isSigned) {
        return (
            <SummaryListItem term={summaryLabel}>
                <MDIcon icon="check_circle" size="small" modifierClass="u-color-positive u-align-middle u-margin-right-tiny" isFilled />
                <span className="u-align-middle">
                    {_transMd(_trans('**%signer%** allekirjoitti **%signTime%**', {
                        signer: (isEmployer || isEmployee)
                            ? _trans('Hyvinvointialue', {}, 'common')
                            : signerDetails?.fullName ?? '-',
                        signTime: _toLocaleDate(signTime, '', _dateTimeFormat)
                    }, 'common'))}
                </span>
            </SummaryListItem>
        );
    }

    if (! isOperator) {
        // CASE: hoitaja luonut lomakkeen ja allekirjoittanut. Odottaa HA:n allekirjoitusta => allekirjoittajan
        // valitsemista.
        if (! signature) {
            return (
                <SummaryListItem term={summaryLabel}>
                    {isProposalReportingForm
                        ? (
                            <div className="u-muted">
                                {_trans('Odottaa hyvinvointialueen allekirjoitusta.', {}, 'reporting_forms')}
                            </div>
                        )
                        : (
                            <Value>
                                {_trans('Hyvinvointialue valitsee', {}, 'reporting_forms')}
                            </Value>
                        )
                    }
                </SummaryListItem>
            );
        }

        return (
            <SummaryListItem term={summaryLabel}>
                <Value>
                    {isProposalReportingForm
                        ? signerDetails?.fullName ?? _trans('Odottaa hyvinvointialueen allekirjoitusta.', {}, 'reporting_forms')
                        : _trans('Hyvinvointialue valitsee', {}, 'reporting_forms')}
                </Value>
            </SummaryListItem>
        );
    }

    if (! signerDetails && canEmployeeFillForm) {
        if (! isOperator) {
            return (
                <SummaryListItem term={summaryLabel}>
                    <div className="u-muted">
                        {_trans('Odottaa hyvinvointialueen allekirjoitusta.', {}, 'reporting_forms')}
                    </div>
                </SummaryListItem>
            );
        }

        if (employers.length === 0 && ! isLoading) {
            return (
                <SummaryListItem term={summaryLabel}>
                    {isOperator && _trans('Allekirjoitusoikeudellisia edustajia ei löytynyt.', {}, 'jobContract')}{' '}

                    {/*Ohjataan yrityksen asetuksiin vain jos sinne on oikeudet*/}
                    {(canAuthorizeUsers || isCommune) && (
                        <Fragment>
                            {isCommune
                                ? _transMd('Voit lisätä allekirjoitusoikeuksia [käyttäjätilien hallinnasta](%url%).', { url: '/commune/user-accounts-manager' }, {
                                    targetBlank: true,
                                    useParagraphs: true,
                                })
                                : _transMd('Voit lisätä allekirjoitusoikeuksia [yrityksen asetuksista](%url%).', { url: '/company/settings/users' }, {
                                    targetBlank: true,
                                    useParagraphs: true,
                                })}
                            {_trans('Lisättyäsi oikeudet ', {}, 'jobContract')}<Button mdIcon="refresh" size="small" iconSize="small" inProgress={isLoading} onClick={() => makeRequest()}>{_trans('päivitä lista', {}, 'common')}</Button>
                        </Fragment>
                    )}
                </SummaryListItem>
            );
        }
    }

    return (
        <SummaryListItem term={summaryLabel}>
            <div className="u-visibility-screen">
                <Placeholder type="text" rows={2} isPending={canEmployeeFillForm && isLoading}>
                    <strong className="u-display-block">
                        {(isEmployer || isEmployee) && ! isCompany
                            ? _trans('Hyvinvointialueen edustaja', {}, 'jobContract')
                            : signerDetails?.fullName ?? '-'}
                    </strong>
                    {/* TT:lle turha näyttää allekirjoittajan spostia */}
                    {! isEmployee && <span>{signerDetails?.email ?? '-'}</span>}
                </Placeholder>
            </div>
            <strong className="u-visibility-print">
                {signerDetails?.fullName ?? '-'}
            </strong>
            {(isProposalReportingForm && isAllowedToSign) && (
                <Button
                    inProgress={isSigning}
                    positive={! isBuildMode}
                    mdIcon="check"
                    disabled={isBuildMode || (canEmployeeFillForm && isLoading) || ! hasSignatureRight}
                    modifierClass="u-1/1 u-margin-top-small"
                    onClick={() => {
                        dispatch.reportingForms.postSignature({ reportingFormId }).then((response) => {
                            if (response?.status === 'ok') {
                                setCurrentSignerId(loggedUserId);
                            }
                        });
                    }}
                >
                    {_trans('Allekirjoita', {}, 'common')}
                </Button>
            )}
            {(isProposalReportingForm && ! isAllowedToSign) && (
                <Fragment>
                    <div className="u-muted u-visibility-screen">
                        {_trans('Odottaa allekirjoitusta.', {}, 'common')}
                    </div>
                    <div className="u-visibility-print">
                        {_trans('Odottaa allekirjoitusta.', {}, 'common')}
                    </div>
                </Fragment>
            )}
        </SummaryListItem>
    );
};

ReportingFormsSignaturesEmployer.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    employerSignatureType: PropTypes.string,
    apiEndPoint: PropTypes.string,
};

ReportingFormsSignaturesEmployer.defaultProps = {
    employerSignatureType: signatureTypes.TYPE_EMPLOYER_SPECIFIC,
    apiEndPoint: '/company/api/v1/operators/reporting-form-signing-access',
};

export default ReportingFormsSignaturesEmployer;
