const relationTypes = {
    /**
     * Yrityksen työntekijä. HUOM: Oltava sama kuin kunnan työntekijän CommuneCustomerRelation::RELATION_EMPLOYEE!
     */
    RELATION_EMPLOYEE: 3,

    /**
     * Käyttäjällä on oikeus allekirjoittaa yrityksen nimissä. Käytetään ennen yrityksen lisäämistä palveluun
     * käyttäjän ja yritysten linkittämiseen kun yrityksen tiedot on haettu valtion tietojärjestelmistä, mutta
     * ei vielä otettu aktiiviseen käyttöön.
     */
    RELATION_PROCURIST: 50,

    /**
     * Yrityksen pääkäyttäjä. Yrityksen allekirjoittaja joka voi antaa muille käyttöoikeksia.
     */
    RELATION_MASTER_USER: 51,

    /**
     * Yrityksen käyttäjä. Käyttäjä joka operoi yrityksen palkanmaksua.
     */
    RELATION_OPERATOR: 52,

    /**
     * Yrityksen hyväksyjä. Käyttäjä joka pystyy hyväksymään kustannusdimensioiden tuntikirjauksia.
     */
    RELATION_APPROVER: 63,

    /**
     * Hoidettava/sijoitettava henkilö, OPH liityen
     */
    RELATION_CARED: 1,
};

export default relationTypes;

export const translateRelationType = (rType) => {
    switch (rType) {
        case relationTypes.RELATION_EMPLOYEE: return _trans('Hoitaja' , {}, 'common');
        case relationTypes.RELATION_CARED: return _trans('Hoidettava' , {}, 'common');
    }
};
export const isOperatorType = (type) =>
    [relationTypes.RELATION_OPERATOR, relationTypes.RELATION_MASTER_USER, relationTypes.RELATION_PROCURIST]
        .includes(parseInt(type, 10));

export const expireTypes = {
    INDEFINITELY: 1,
    FIXED_TERM: 2,
};

export const operatorTypes = [
    relationTypes.RELATION_OPERATOR,
    relationTypes.RELATION_MASTER_USER,
    relationTypes.RELATION_PROCURIST,
];

export const operatorAccessRoles = {
    ACCESS_ROLE_SIGN_CONTRACTS: 'accessRoleSignContracts',
    ACCESS_ROLE_SIGN_REPORTING_FORMS: 'accessRoleSignReportingForms',
    ACCESS_ROLE_COMMUNICATION_TOOL: 'accessRoleCommunicationTool',
    ACCESS_ROLE_SET_OFFLINE_PAYMENT_PAID: 'accessRoleSetOfflinePaymentPaid',
    ACCESS_ROLE_CONFIRM_OFFLINE_PAYMENT: 'accessRoleConfirmOfflinePayment',
    ACCESS_ROLE_BANK_TRANSACTIONS: 'accessRoleBankTransactions',
    //ACCESS_ROLE_TRANSACTION_DETAILS: 'accessRoleTransactionDetails'
};

export const selectableRelationTypes = [
    {
        value: relationTypes.RELATION_OPERATOR,
        label: _trans('companies.users.operator_role'),
    },
    {
        value: relationTypes.RELATION_MASTER_USER,
        label: _trans('companies.users.master_role'),
    },
];

const companyUserRelationTypeMap = {
    [relationTypes.RELATION_PROCURIST]: 'Prokuristi',
    [relationTypes.RELATION_MASTER_USER]: 'Pääkäyttäjä',
    [relationTypes.RELATION_OPERATOR]: 'Yrityksen operaattori',
    [relationTypes.RELATION_EMPLOYEE]: 'Työntekijä',
    [relationTypes.RELATION_CARED]: 'Hoidettava',
};

export const operatorAccessRoleLabelMap = {
    [operatorAccessRoles.ACCESS_ROLE_SIGN_CONTRACTS]: _trans('Oikeus allekirjoittaa sopimuksia', {}, 'jobContract'),
    [operatorAccessRoles.ACCESS_ROLE_SIGN_REPORTING_FORMS]: _trans('Oikeus allekirjoittaa lomakkeita', {}, 'jobContract'),
    [operatorAccessRoles.ACCESS_ROLE_SET_OFFLINE_PAYMENT_PAID]: _trans('Oikeus asettaa maksuerä maksetuksi', {}, 'company_settings'),
    [operatorAccessRoles.ACCESS_ROLE_COMMUNICATION_TOOL]: _trans('Oikeus lähettää kirjeitä tai sähköposteja viestintä-toiminnolla ', {}, 'company_settings'),
    [operatorAccessRoles.ACCESS_ROLE_BANK_TRANSACTIONS]: _trans('Oikeus nähdä maksuliikenteen maksuaineistot', {}, 'company_settings'),
    [operatorAccessRoles.ACCESS_ROLE_CONFIRM_OFFLINE_PAYMENT]: _trans('Oikeus hyväksyä maksuerä maksuun', {}, 'company_settings'),
    //[operatorAccessRoles.ACCESS_ROLE_TRANSACTION_DETAILS]: _trans('Oikeus nähdä pankkitilin tapahtumat ja käsitellä kohdistuksia Oima-palvelussa', {}, 'company_settings'),
};

export function translateCompanyUserRelationType(companyUserRelationType) {
    return companyUserRelationType in companyUserRelationTypeMap ? companyUserRelationTypeMap[companyUserRelationType] : 'Tuntematon!';
}
