import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Rajoitetun tekstisisällön syöttö. Näyttää kuinka paljon merkkejä vielä voidaan syöttää.
 * Pätkäisee esim. pastetetun tekstin ja jos annettu alkuteksti on pidempi kuin sallittu, pätkäisee senkin.
 */
export const MaxInput = ({ value, maxLength, onChange, modifierClass, ...rest }) => {
    const [currentValue, setCurrentValue] = useState('');
    const [hasHitBoundary, setHasHitBoundary] = useState(false);

    useEffect(() => {
        // Rajoitetaan alussa annettu arvo jos se onkin pidempi kuin mitä kenttä sallii.
        const truncatedValue = value.substring(0, maxLength);
        setCurrentValue(truncatedValue);
        onChange(truncatedValue);
    }, [maxLength, value]);

    return (
        <Fragment>
            <div
                className={classNames(modifierClass, {
                    'u-display-inline-block': !modifierClass,
                })}
            >
                <input
                    type="text"
                    {...rest}
                    onChange={(event) => {
                        const value = event.target.value;
                        const valueLength = value.length || 0;

                        if (valueLength <= maxLength) {
                            setCurrentValue(value);
                            onChange(value);
                        } else {
                            const truncatedValue = value.substring(0, maxLength);

                            setCurrentValue(truncatedValue);
                            setHasHitBoundary(true);
                            onChange(truncatedValue);

                            setTimeout(() => {
                                setHasHitBoundary(false);
                            }, 500);
                        }
                    }}
                    value={currentValue}
                    className="u-1/1"
                />
                <div
                    className={classNames('u-text-right', {
                        'u-animation-shake u-color-negative': hasHitBoundary,
                    })}
                >
                    {`${currentValue.length} / ${maxLength}`}
                </div>
            </div>
        </Fragment>
    );
};

MaxInput.propTypes = {
    value: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    modifierClass: PropTypes.string,
};

MaxInput.defaultProps = {
    value: '',
    maxLength: 250,
    onChange() {},
    modifierClass: null,
};
