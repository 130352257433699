import { getBlockProps } from 'shared/ReForm/utils/getBlockProps';
import { blocks as blockTypes } from 'shared/ReForm/constants/blocks';

export const getTemplateBlockNames = (json) => json.reduce((acc, item) => {
    const { blocks } = item;
    const { name, block, component } = getBlockProps(item);

    if (! Array.isArray(blocks) && ! component && block !== blockTypes.ARRAY) return acc;

    if (Array.isArray(blocks)) {
        return acc
            // Array-tyyppinen palikka. Tällainen on ainakin salaries.
            .concat(block === blockTypes.ARRAY && name ? name : null)
            .concat(getTemplateBlockNames(blocks))
            .filter(Boolean);
    }

    return acc.concat(component);
}, []);
