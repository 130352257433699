import { produce } from 'immer';
import api from 'api';
import violationTypes from 'Commune/constants/violationTypes';
import userRoles from 'shared/constants/userRoles';
import { todoTypes } from 'shared/constants/todoTypes';
import { isCommune } from 'shared/utils/commonUtils';

/**
 * Päällekkäisten työaikojen pikarapsan linkki-slug
 */
const WORK_TIME_OVERLAP_QUICK_REPORT_URL = '/commune/quick-report-by-slug/work-time-overlaps';

const resolveCommuneUrl = (violationType) => {
    //TYPE_BENEFIT_HISTORY_EXCESS_HOURS
    if (Object.values(violationTypes.BENEFIT_HISTORY_TYPES).includes(violationType)) {
        return '/commune/benefits/#15';//`${Routing.generate('suoratyo_benefits')}#15`;
    }

    if (Object.values(violationTypes.PAY_PERIOD_TYPES).includes(violationType)) {
        return '/commune/work_time';
    }

    if (Object.values(violationTypes.WORK_TIME_OVERLAP_TYPES).includes(violationType)) {
        return '/commune/work_time';
    }
};

const fetchCommuneNotifications = async () => {
    const response = await api.get('/commune/api/v1/commune/violation/list');
    if (response?.status !== 'ok' || !response.data) {
        return [];
    }

    // TODO: Bäkkäriin näiden käpistely roolin mukaan
    return Object.keys(response.data).map((type) => {
        const count = response.data[type];
        const violationType = parseInt(type, 10);

        return ({
            message: _transChoice('violation.violation_type_short', type),
            count,
            url: resolveCommuneUrl(violationType),
        });
    });
};

// Varmistetaan välilyönnit enkoodatuksi.
const decodeUrl = (url) => decodeURIComponent((url+'').replace(/\+/g, '%20'));

const resolveUrl = (notification) => {
    const { url, type } = notification;
    const prefix = isCommune() ? '/commune' : '/home';

    switch (type) {
        // Koska nämä groupataan tässä, ei voida näyttää mitään yksittäistä linkkiä tiettyyn työajanseurantaan
        case todoTypes.TYPE_OVERLAPPING_WORK_TIME_PERIODS_FOR_SINGLE_EMPLOYEE:
        case todoTypes.TYPE_OVERLAPPING_WORK_TIME_PERIODS_FOR_SINGLE_BENEFICIARY:
            return WORK_TIME_OVERLAP_QUICK_REPORT_URL;
        case todoTypes.TYPE_OWN_INSTANT_MESSAGES:
            return `${prefix}/instant-messages#tab-own`;
        case todoTypes.TYPE_WELLBEING_AREA_INSTANT_MESSAGES:
            return `${prefix}/instant-messages#tab-other`;
        case todoTypes.TAX_CARD_UPDATE_NEEDS_ACTIONS:
            return `${prefix}/todos#tab-taxCard`;
        case todoTypes.AGE_CHANGE_COST_REIMBURSEMENT_UPDATE:
        case todoTypes.WORK_TIME_COPIER_ERROR:
            return `${prefix}/todos#tab-contract`;
        case todoTypes.TYPE_BENEFIT_HISTORY_EXCESS_HOURS:
            return '/commune/work_time';
        case todoTypes.TYPE_BENEFIT_ALLOWANCE_RULE_EXCESS_HOURS:
            return '/commune/work_time';
        case todoTypes.USER_SELF_EDIT_DATA:
        case todoTypes.USER_SELF_EDIT_DATA_REQUIRE_CONFIRMATION:
            return `${prefix}/todos#tab-userDataProposal`;

        default:
            return decodeUrl(url);
    }
};

/**
 * Yläpalkin ilmoitukset.
 */
export const notificationHub = {
    // Notifikaatiot muotoa
    // title (ei pakollinen)
    // message
    // count (ei pakollinen)
    // route (ei pakollinen)
    state: [],

    reducers: {
        add: (state, payload) => produce(state, (draftState) => {
            draftState.unshift(payload);
        }),
        setNotifications: (state, payload) => payload,
        delete: (state, payload) => produce(state, (draftState) => {
            draftState.splice(draftState.findIndex(({ id }) => id === payload), 1);
        }),
    },

    selectors: {
        getAll: (state) => state,
    },

    effects: () => ({
        /**
         * Hakee ilmoitusten metadatan yläpalkkiin.
         */
        async fetchNotificationMetadata(userRole) {
            try {
                const notifications = userRole === userRoles.COMMUNE_OPERATOR
                    ? await fetchCommuneNotifications()
                    : [];

                const response = await api.get('/api/v2/todo-metadata');
                if (response.status === 'ok' && Array.isArray(response.data)) {
                    // TODO: Toistaiseksi tässä samassa läntissä mukana myös todot
                    const todosMap = response.data
                        .map((todo) => ({
                            message: todo.message,
                            count: todo.count,
                            type: todo.type,
                            url: resolveUrl(todo),
                        }))
                        .concat(notifications)
                        .sort((todo) => {
                            if ([todoTypes.TYPE_OWN_INSTANT_MESSAGES, todoTypes.TYPE_WELLBEING_AREA_INSTANT_MESSAGES].includes(todo.type)) {
                                return -1;
                            }

                            return 0;
                        });

                    this.setNotifications(todosMap);
                }
            } catch (error) {
                console.log(error);
            }
        }

    })
};
