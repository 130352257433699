import { getTemplateBlockNames } from 'shared/ReForm/utils/getTemplateBlockNames';
import { signatureBlockNames } from 'ContractV3/constants/blockNames';

/**
 * Kertoo löytyykö pohjalta allekirjoituspalikoita.
 * Ei ota kantaa löytyvätkö kaikki palikat joita vaaditaan toimiviin allekirjoituksiin.
 * @param json
 * @returns {*}
 */
export const hasSignatureBlocksOnTemplate = (json) => {
    const templateBlockNames = getTemplateBlockNames(json);
    return templateBlockNames.some((blockName) => signatureBlockNames.includes(blockName));
};
