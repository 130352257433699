/**
 * Yleiskäyttöiset validaatorit. Käytössä esim. React Redux Formin kanssa.
 * Lisää uusien validaattorien nimeen etuliite "validate".
 * Sisällytä myös käännökset.
 */
import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

// Lisää validaattoreita: https://github.com/chriso/validator.js
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';
import utils from 'shared/utils/commonUtils';

const dateInputFormat = 'DD MM YYYY';

/**
 * Yrittää parsia kuukauden ja vuoden stringistä. Oltava muodossa MM/YYYY
 * @param value
 */
const parseMonthAndYear = (value) => {
    if (typeof value !== 'string') return [];

    const [monthStr, yearStr] = value.split('/');
    const month = parseInt(monthStr, 10);
    const year = parseInt(yearStr, 10);

    return [month, year];
};

/**
 * Yrittää parsia kuukauden ja vuoden stringistä. Oltava muodossa YYYY-MM
 * @param value
 */
const parseYearAndMonth = (value) => {
    if (typeof value !== 'string') return [];

    const [yearStr, monthStr] = value.split('-');
    const year = parseInt(yearStr, 10);
    const month = parseInt(monthStr, 10);

    return [year, month];
};

export const validators = {

    atLeastOne(value) {
        return _.size(value) > 0;
    },

    isRequired(value) {
        // Ei "arvoton" (null == undefined)
        if (value == null) return false;

        // Jos objekti, niin aleta arvuuttelemaan tarkemmin, vaan oletetaan, että ok
        if (typeof value === 'object' && Object.keys(value).length > 0) {
            return true;
        }

        return (
            // Jos numero, muutetaan stringiksi
            (! isNaN(value) && value.toString().trim() !== '') ||
            // Muuten trimmataan normaalisti
            (typeof value === 'string' && value.trim() !== '')
        );
    },

    /**
     * Arvo ei ole sallittu.
     * @param value
     * @returns {boolean}
     */
    isNotRequired(value) {
        return !value;
    },

    isRequiredCheckbox(value) {
        return !_.isUndefined(value) && value !== false;
    },

    /**
     * Accepts value if value < max.
     *
     * @param value
     * @param max
     * @returns {boolean}
     */
    isLT(value, max) {
        value = String(value).replace(',', '.');
        max = String(max).replace(',', '.');

        return +value < +max;
    },

    /**
     * Accepts value if value <= max.
     *
     * @param value
     * @param max
     * @returns {boolean}
     */
    isLTE(value, max) {
        value = String(value).replace(',', '.');
        max = String(max).replace(',', '.');

        return +value <= +max;
    },

    /**
     * Accepts value if value > min.
     *
     * @param value
     * @param min
     * @returns {boolean}
     */
    isGT(value, min) {
        value = String(value).replace(',', '.');
        min = String(min).replace(',', '.');

        return +value > +min;
    },

    /**
     * Accepts value if value >= min.
     * @param value
     * @param min
     * @returns {boolean}
     */
    isGTE(value, min) {
        value = String(value).replace(',', '.');
        min = String(min).replace(',', '.');

        return +value >= +min;
    },

    /**
     * Arvo on rajojen sisällä.
     * @param value
     * @param min
     * @param max
     * @returns {boolean}
     */
    inRange(value, min, max) {
        return (value >= min) && (value <= max);
    },

    /**
     * Accepts value1 if value1 == value2
     * @param value1
     * @param value2
     * @returns {boolean}
     */
    isEqual(value1, value2) {
        value1 = String(value1).replace(',', '.');
        value2 = String(value2).replace(',', '.');

        return +value1 === +value2;
    },

    /**
     * Accepts integers with optional spaces between groups.
     *
     * @param integer
     * @returns {boolean}
     */
    isInteger(integer) {
        if (_.isEmpty(integer)) return true;
        // eslint-disable-next-line security/detect-unsafe-regex
        return /^-?\d+( \d+)*$/.test(integer);
    },

    /**
     * Accepts integers with optional spaces between groups and optional
     * comma/dot followed by numbers.
     *
     * @param decimal
     * @returns {boolean}
     */
    isDecimal(decimal) {
        // eslint-disable-next-line security/detect-unsafe-regex
        return /^-?\d+( \d+)*([.,]\d+)?$/.test(decimal);
    },

    isPositiveDecimal(decimal) {
        // eslint-disable-next-line security/detect-unsafe-regex
        return /^(?!-)?\d+( \d+)*([.,]\d+)?$/.test(decimal);
    },

    /**
     * Accepts integers or empty string
     *
     * @param decimal
     * @returns {boolean}
     */
    isDecimalAndAllowEmptyString(decimal) {
        if (_.isEmpty(decimal)) {
            return true;
        }
        // eslint-disable-next-line security/detect-unsafe-regex
        return /^-?\d+( \d+)*([.,]\d+)?$/.test(decimal);
    },

    isNumeric(value) {
        return _.isUndefined(value) || _.isEmpty(value) || isNumeric(value);
    },

    /**
     * Accepts non-negative values.
     *
     * @param number
     * @returns {boolean}
     */
    isNonNegative(number) {
        return /^[^-]/.test(number);
    },

    /**
     * Accepts negative values.
     *
     * @param number
     * @returns {boolean}
     */
    isNegative(number) {
        return /^-/.test(number);
    },

    minLength(value, minLength) {
        if (_.isUndefined(value)) return false;

        return _.isArrayLike(value) ? value.length >= minLength : value.toString().length >= minLength;
    },

    maxLength(value, maxLength) {
        if (_.isUndefined(value)) return false;

        return _.isArrayLike(value) ? value.length <= maxLength : value.toString().length <= maxLength;
    },

    isSsn(ssn) {
        // Just skip empty field
        if (_.isUndefined(ssn) || ssn == '') return true;

        ssn = ssn.toUpperCase();
        // Matches years 1850-2029
        const matchRegexp = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d[-UVWXY]|[012]\d[ABCDEF])\d{3}[\dA-Z]$/i;
        if (ssn.search(matchRegexp) === -1) {
            return false;
        }
        const checks = '0123456789ABCDEFHJKLMNPRSTUVWXY';
        const checkSum = checks.charAt(parseInt(ssn.substr(0, 6) + ssn.substr(7, 3), 10) % 31);

        return checkSum == ssn.charAt(10);
    },

    /**
     * isSsn v2
     * Typerät "onko tyhjä, jos on niin validoituu" checkit poistettu.
     * @param value
     * @returns {boolean}
     */
    isSocialSecurityNumber(value) {
        const ssn = value.toUpperCase();
        // Matches years 1850-2029
        const matchRegexp = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d[-UVWXY]|[012]\d[ABCDEF])\d{3}[\dA-Z]$/i;
        if (ssn.search(matchRegexp) === -1) {
            return false;
        }
        const checks = '0123456789ABCDEFHJKLMNPRSTUVWXY';
        const checkSum = checks.charAt(parseInt(ssn.substr(0, 6) + ssn.substr(7, 3), 10) % 31);

        return checkSum === ssn.charAt(10);
    },

    // From pre-settings.js
    isIban(accountNumber) {
        if (!accountNumber) return true;

        accountNumber = accountNumber.trim().toLowerCase().replace(/\s/g,'');

        const countries = {
            'al': 28,
            'ad': 24,
            'at': 20,
            'az': 28,
            'bh': 22,
            'be': 16,
            'ba': 20,
            'br': 29,
            'bg': 22,
            'cr': 21,
            'hr': 21,
            'cy': 28,
            'cz': 24,
            'dk': 18,
            'do': 28,
            'ee': 20,
            'fo': 18,
            'fi': 18,
            'fr': 27,
            'ge': 22,
            'de': 22,
            'gi': 23,
            'gr': 27,
            'gl': 18,
            'gt': 28,
            'hu': 28,
            'is': 26,
            'ie': 22,
            'il': 23,
            'it': 27,
            'jo': 30,
            'kz': 20,
            'kw': 30,
            'lv': 21,
            'lb': 28,
            'li': 21,
            'lt': 20,
            'lu': 20,
            'mk': 19,
            'mt': 31,
            'mr': 27,
            'mu': 30,
            'mc': 27,
            'md': 24,
            'me': 22,
            'nl': 18,
            'no': 15,
            'pk': 24,
            'ps': 29,
            'pl': 28,
            'pt': 25,
            'qa': 29,
            'ro': 24,
            'sm': 27,
            'sa': 24,
            'rs': 22,
            'sk': 24,
            'si': 19,
            'es': 24,
            'se': 24,
            'ch': 21,
            'tn': 24,
            'tr': 26,
            'ae': 23,
            'gb': 22,
            'vg': 24
        };

        const chars = {
            'a': 10, 'b': 11, 'c': 12, 'd': 13, 'e': 14, 'f': 15, 'g': 16, 'h': 17, 'i': 18, 'j': 19, 'k': 20, 'l': 21,
            'm': 22, 'n': 23, 'o': 24, 'p': 25, 'q': 26, 'r': 27, 's': 28, 't': 29, 'u': 30, 'v': 31, 'w': 32, 'x': 33,
            'y': 34, 'z': 35
        };

        const countryCode = accountNumber.substr(0, 2);

        // Known country code
        if (!(countries[countryCode])) {
            return false;
        }

        // Correct length for country code
        if (accountNumber.length != countries[countryCode]) {
            return false;
        }

        // Checksum must be numeric
        let checkSum = accountNumber.substr(2, 2);
        if (/\D/.test(checkSum)) {
            return false;
        }

        const bigIntegerModulo = function (divident, divisor) {
            const partLength = 10;

            while (divident.length > partLength) {
                const part = divident.substring(0, partLength);
                divident = (part % divisor) + divident.substring(partLength);
            }

            return divident % divisor;
        };

        // Checksum validation
        const ibanForChecking = (accountNumber.substr(4) + accountNumber.substr(0, 4)).replace(/[a-z]/g, function (p1) {
            return chars[p1];
        });
        checkSum = bigIntegerModulo(ibanForChecking, 97);

        return (checkSum === 1);
    },

    // From pre-settings.js
    isRf(rfNumber)
    {
        if (! rfNumber) {
            return false;
        }
        rfNumber = rfNumber.replace(/ /g, '').toLowerCase();

        const chars = {
            'a': 10, 'b': 11, 'c': 12, 'd': 13, 'e': 14, 'f': 15, 'g': 16, 'h': 17, 'i': 18, 'j': 19, 'k': 20, 'l': 21,
            'm': 22, 'n': 23, 'o': 24, 'p': 25, 'q': 26, 'r': 27, 's': 28, 't': 29, 'u': 30, 'v': 31, 'w': 32, 'x': 33,
            'y': 34, 'z': 35
        };

        // Checksum must be numeric
        let checkSum = rfNumber.substr(2, 2);
        if (/\D/.test(checkSum)) {
            return false;
        }
        const bigIntegerModulo = function (divident, divisor) {
            const partLength = 10;

            while (divident.length > partLength) {
                const part = divident.substring(0, partLength);
                divident = (part % divisor) + divident.substring(partLength);
            }

            return divident % divisor;
        };

        // Checksum validation
        const rfForChecking = (rfNumber.substr(4) + rfNumber.substr(0, 4)).replace(/[a-z]/g, function (p1) {
            return chars[p1];
        });
        checkSum = bigIntegerModulo(rfForChecking, 97);

        return (checkSum === 1);
    },

    isReferenceNumber(refNumber)
    {
        if (! +refNumber) {
            return false;
        }
        if (refNumber.length < 4) {
            return false;
        }
        const refString = String(refNumber);
        let ki = 0;
        let total = 0;
        const multiplier = [7, 3, 1];
        const ref = refString.slice(0, -1);

        for (let i = ref.length; i > 0; i--) {
            total += ref.charAt(i - 1) * multiplier[ki++ % 3];
        }
        return ((10 - (total % 10)) % 10) === Number(refString[refString.length - 1]);
    },

    isTradeUnionReferenceNumber(refNumber)
    {
        // Pitää pystyä syöttämään myös osan vuodesta viitteet
        // joten aloitetaan tarkistus vasta, jos viitenumero on syötetty
        if (refNumber) {
            if (! +refNumber) {
                return false;
            }
            if (refNumber.length < 4) {
                return false;
            }
            const refString = String(refNumber);
            let ki = 0;
            let total = 0;
            const multiplier = [7, 3, 1];
            const ref = refString.slice(0, -1);

            for (let i = ref.length; i > 0; i--) {
                total += ref.charAt(i - 1) * multiplier[ki++ % 3];
            }
            return ((10 - (total % 10)) % 10) === Number(refString[refString.length - 1]);
        } else {
            return true;
        }
    },

    noUmlauts(value) {
        return !/[\u00C0-\u02AF]/.test(value);
    },

    isYTunnus(code) {
        if (code === undefined || code === '') return true;

        const matchRegexp = /^\d{7}-\d$/;
        if (code.search(matchRegexp) === -1) {
            return false;
        }

        let temp = 0;
        const multipliers = [7, 9, 10, 5, 8, 4, 2];

        for (let i = 0; i <= 6; i++) {
            temp += multipliers[i] * (code.charCodeAt(i) - 48);
        }
        temp %= 11;
        if (temp === 0) return code.charAt(8) === '0';
        if (temp === 1) return false; // Should never happen

        return code.charCodeAt(8) - 48 === 11 - temp;
    },

    isEmail(email) {
        return _.isUndefined(email) || _.isEmpty(email) || isEmail(email, { allow_utf8_local_part: false });
    },

    /**
     * Postinumero. Tarkistaa pituuden ja arvon.
     *
     * @param postcode
     * @returns {boolean}
     */
    isPostCode(postcode) {
        // eslint-disable-next-line security/detect-unsafe-regex
        return !_.isUndefined(postcode) && /^-?\d+( \d+)*$/.test(postcode) && _.size(postcode) === 5;
    },

    isDate(value) {
        return moment(value, 'DD.MM.YYYY', true).isValid()
            || moment(value, 'D.M.YYYY', true).isValid()
            || moment(value, 'YYYY-MM-DD', true).isValid()
            || moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
            || _.isUndefined(value);
    },

    /**
     * Tarkistaa onko annettu päivämäärä ISO-formaatissa YYYY-MM-DD.
     * Käytetään myös DateRangePickerin kanssa jolloin on tarkistettava
     * löytyykö arvoista value sillä muita arvoja (minimumDate, maximumDate...) käytetään
     * päivämäärän raja-arvojen tarkistukseen.
     * @param values
     * @returns {*}
     */
    isISODate(values) {
        if (_.has(values, 'value')) {
            return moment(values.value, moment.ISO_8601).isValid();
        } else {
            return moment(values, moment.ISO_8601).isValid();
        }
    },

    /**
     * Olettaa valuen olevan objekti jonka sisällä keyt start ja end
     * @param values
     */
    isDateRange(values = {}) {

        console.log('values inside validator', values);
        let startDate = '';
        const start = values.start ?? '';
        const end = values.end;

        // Ilman starttia ei voi olla validi
        // DateRangeInputin isFixedEndDate valinnalla endDate on aina Infinity, mutta start pitää aina olla.
        if (start === '') {
            return false;
        }

        if (start !== '') {
            startDate = utils.convertISOToMoment(start);
            if (startDate === '') return false;
        }

        // Infinity == toistaiseksi
        if (end === Infinity) {
            return true;
        }

        const endDate = moment(end, 'YYYY-MM-DD');

        return endDate.isValid() && startDate.isSameOrBefore(endDate);
    },

    /**
     * Tarkistaa yksittäisen päivämääräarvon raja-arvot.
     * @param date
     * @param minimumDate
     * @param maximumDate
     * @returns {*}
     */
    isSingleDateInRange(date, minimumDate, maximumDate) {
        // Onko päivämäärä Moment-objekti
        if (!moment.isMoment(date)) return false;

        //  Onko päivämäärä annettujen raja-arvojen sisällä
        if (moment.isMoment(date)) {
            const isBeforeMinimumDate = (minimumDate !== null ? date.isBefore(minimumDate) : false);
            const isAfterMaximumDate = (maximumDate !== null ? date.isAfter(maximumDate) : false);

            if (isBeforeMinimumDate || isAfterMaximumDate) return false;
        }

        // Tarkistukset menivät läpi
        return true;
    },

    /**
     * Tarkistaa yksittäisen päivämääräarvon raja-arvot päivän tarkkuudella.
     * @param date
     * @param minimumDate
     * @param maximumDate
     * @returns {*}
     */
    isSingleDateInRangeDays(date, minimumDate, maximumDate) {
        // Onko päivämäärä Moment-objekti
        if (!moment.isMoment(date)) return false;

        //  Onko päivämäärä annettujen raja-arvojen sisällä
        if (moment.isMoment(date)) {
            const isBeforeMinimumDate = (minimumDate !== null ? date.isBefore(minimumDate, 'day') : false);
            const isAfterMaximumDate = (maximumDate !== null ? date.isAfter(maximumDate, 'day') : false);

            if (isBeforeMinimumDate || isAfterMaximumDate) return false;
        }

        // Tarkistukset menivät läpi
        return true;
    },

    /**
     * Onko yksittäinen päivämäärä "kiellettyjen" aikavälien sisällä.
     * @param value
     * @param unavailableDateRanges
     * @returns {*|boolean}
     */
    isDateUnavailable(value, unavailableDateRanges) {
        const isInUnavailableRange = unavailableDateRanges.length > 0
            ? unavailableDateRanges.some((unavailableDateRange) => (
                this.isSingleDateInRange(value, unavailableDateRange.start, unavailableDateRange.end)
            ))
            : false;

        return ! isInUnavailableRange;
    },

    /**
     * Ovatko annetut päivämäärät sallittujen rajojen sisällä.
     * @param values
     * @param minimumDate
     * @param maximumDate
     * @returns {boolean}
     */
    inDateRange(values, minimumDate = null, maximumDate = null) {
        // Älä jatka jos ei ole mitään mitä vasten tarkistaa
        if (minimumDate === null && maximumDate === null) return true;

        const startDateStr = _.get(values, 'start', null);

        // Jos aloituspäivämäärä on tyhjä on kyseessä
        // mitä ilmeisimmin yksittäinen päivämääräkenttä.
        if (startDateStr === null) {
            const date = utils.convertISOToMoment(values);
            return validators.isSingleDateInRange(date, minimumDate, maximumDate);
        }
        const endDateStr = _.get(values, 'end', null);

        const startDate = utils.convertISOToMoment(startDateStr);

        // Inifinity == fixedEndDate eli päättymispäivämäärälle on annettu
        // jokin arvo ennalta (kuten "Toistaiseksi")
        const endDate = (endDateStr === Infinity ? endDateStr : utils.convertISOToMoment(endDateStr));

        const isStartDateInRange = validators.isSingleDateInRange(startDate, minimumDate, maximumDate);

        const isEndDateInRange = (endDate === Infinity || validators.isSingleDateInRange(endDate, minimumDate, maximumDate));

        return isStartDateInRange && isEndDateInRange;
    },

    /**
     * Tutkii onko valittu aikaväli sallimattomien ulkopuolella.
     * @param values
     * @param unavailableDateRanges
     * @returns {boolean}
     */
    isDateRangeUnavailable(values, unavailableDateRanges) {
        const isInUnavailableRange = unavailableDateRanges.length > 0
            ? unavailableDateRanges.some(({ start, end }) => {
                const dateRange = moment.range(values.start, values.end);
                const unavailableDateRange = moment.range(start, end);

                return (
                    moment.isRange(dateRange)
                    && moment.isRange(unavailableDateRange)
                    && dateRange.overlaps(unavailableDateRange, { adjacent: true })
                );
            })
            : false;

        return ! isInUnavailableRange;
    },

    /**
     * Onko päivämäärä liian kaukana menneisyydessä.
     */
    isDateTooFarInThePast(value, yearLimit = 5) {
        const date = moment(value);
        return date.isValid() && moment().year() - date.year() <= yearLimit;
    },

    /**
     * Onko päivämäärä liian kaukana tulevaisuudessa.
     */
    isDateTooFarInTheFuture(value, yearLimit = 5) {
        const date = moment(value);
        return date.isValid() && date.year() - moment().year() <= yearLimit;
    },

    /**
     * Onko annettu arvo aikaväli jossa alku on MM/YYYY ja loppu MM/YYYY.
     * @param values
     * @returns {boolean}
     */
    isMonthSpan(values = {}) {
        if (! validators.isYearAndMonth(values.start)) {
            return false;
        }
        const [startYear, startMonth] = parseYearAndMonth(values.start);
        // JS:ssä kuukaudet alkaa nollasta. Huikeaa.
        const start = new Date(startYear, startMonth - 1);

        // Loppu voi olla Infinity = toistaiseksi
        if (start && values.end === Infinity) return true;

        if (! validators.isYearAndMonth(values.end)) {
            return false;
        }
        const [endYear, endMonth] = parseYearAndMonth(values.end);
        const end = new Date(endYear, endMonth - 1);

        // Onko alku ja loppu validi sekä alku ennen loppua.
        return start && end && start.getTime() <= end.getTime();
    },

    /**
     * Tutkii onko valittu kuukausiaikaväli sallimattomien ulkopuolella.
     * @param values
     * @param unavailableMonthSpans
     * @returns {boolean}
     */
    isMonthSpanUnavailable(values = {}, unavailableMonthSpans = []) {
        const [startYear, startMonth] = parseYearAndMonth(values.start);
        const start = new Date(startYear, startMonth - 1);
        const [endYear, endMonth] = parseYearAndMonth(values.end);
        const end = new Date(endYear, endMonth - 1);
        const monthSpan = moment.range(start, end);

        const isInUnavailableSpan = unavailableMonthSpans.length > 0
            ? unavailableMonthSpans.some(({ start, end }) => {
                const [startYear, startMonth] = parseYearAndMonth(start);
                const unavailableStart = new Date(startYear, startMonth - 1);
                const [endYear, endMonth] = parseYearAndMonth(end);
                const unavailableEnd = new Date(endYear, endMonth - 1);
                const unavailableMonthSpan = moment.range(unavailableStart, unavailableEnd);

                return (
                    moment.isRange(monthSpan)
                    && moment.isRange(unavailableMonthSpan)
                    && monthSpan.overlaps(unavailableMonthSpan, { adjacent: true })
                );
            })
            : false;

        return ! isInUnavailableSpan;
    },

    /**
     * Accepts date if value > values[otherField].
     *
     * @param values
     * @param value
     * @param otherField
     * @returns {boolean}
     */
    isGTDate(values, value, otherField) {
        if (!_.isUndefined(values[otherField]) && values[otherField] != '') {
            return moment(value, dateInputFormat).unix() > moment(values[otherField], dateInputFormat).unix();
        } else {
            return true;
        }
    },

    /**
     * Accepts date if value < values[otherField].
     *
     * @param values
     * @param value
     * @param otherField
     * @returns {boolean}
     */
    isLTDate(values, value, otherField) {
        if (!_.isUndefined(values[otherField]) && values[otherField] != '') {
            return moment(value, dateInputFormat).unix() < moment(values[otherField], dateInputFormat).unix();
        } else {
            return true;
        }
    },

    /**
     * Accepts date if value > moment() (now)
     *
     * @param value
     * @returns {boolean}
     */
    isGTENowDate(value) {
        return moment(value, dateInputFormat).isAfter(moment().subtract(1, 'days'));
    },

    isHolidayBonusAndDayCount(values, value, dayCountInputName)
    {
        const holidayBonusAmount = value;
        const holidayDayCount = +_.get(values, dayCountInputName, 0);

        return holidayDayCount == 0 ? holidayBonusAmount == 0 : true;
    },

    isEarnedAndSpentHolidayDayCount(values, value, inputName)
    {
        const currentEarnedHolidayDays = (value || '').replace(',', '.');
        const currentSpentHolidayDays = +_.get(values, inputName);

        const diff = currentEarnedHolidayDays - currentSpentHolidayDays;

        return diff >= -0.5;
    },

    isHolidayDayCountAndFullWorkingYear(values, value, hasFullWorkingYear) {
        return hasFullWorkingYear ? true : Math.round(value) == Math.floor(value);
    },

    /**
     * Maps response from backend to validationErrors
     * Expect response to be JSON and contain keys errors.children
     */
    mapExternalErrors(json)
    {
        const errors = {};

        if (json.hasOwnProperty('errors')) {
            if (json.errors.hasOwnProperty('children')) {
                for (var child in json.errors.children) {
                    if (json.errors.children.hasOwnProperty(child)) {
                        const value = json.errors.children[child];
                        if (value.hasOwnProperty('errors')) {
                            errors[child] = Translator.trans(value.errors[0]);
                        }
                    }
                }
                return errors;
            }
        }
        return null;
    },

    isHolidayDayDay(day) {
        return /^-?(\d+?)([,.][5])?$/.test(day);
    },

    isHolidayDayMonth(month)
    {
        return /^(\d{4}(0[1-9]|1[0-2]))$/.test(month);
    },

    isHolidayDayYear(year) {
        return /^(\d{4})$/.test(year);
    },

    isEmptyOrNull(value) {
        return null === value || '' === value;
    },

    isChecked(value) {
        return value === true;
    },

    isTaxPercentInRange(value) {
        value = parseFloat(value, 2);
        return value >= 0 && value <= 60;
    },

    isEuroOrPercentage(value) {
        // eslint-disable-next-line security/detect-unsafe-regex
        return !_.isUndefined(value) && value.match(/^\d+( \d+)*([,]\d+)? ?[eE€%]$/);
    },

    isCommaSeparatedAlphaNumeric(value) {
        if (value === undefined) {
            return true;
        }
        const pattern = /^[\da-zA-ZåäöÅÄÖ,]*$/;
        return !_.isUndefined(value) && pattern.test(value);
    },

    isCommaSeparatedAlphaNumericWithDelimeters(value) {
        if (value === undefined) {
            return true;
        }
        const pattern = /^[\da-zA-ZåäöÅÄÖ,_-]*$/;
        return !_.isUndefined(value) && pattern.test(value);
    },

    matchRegexp(value, regex) {
        // eslint-disable-next-line security/detect-non-literal-regexp
        return !_.isUndefined(value) && value.match(new RegExp(regex));
    },

    isPhoneNumber(value) {
        return /^\+?([0-9][0-9]*)$/.test(value) || _.isUndefined(value);
    },

    isNullOrDecimal(value) {
        if (!value) {
            return true;
        } else if (this.isDecimal(value) && this.isNonNegative(value)) {
            if (String(value).replace(',', '.') > 0) {
                return true;
            }
        }
        return false;
    },

    /**
     * Onko arvo muodossa MM/YYYY tai tyhjä.
     * @param value
     * @returns {boolean}
     */
    isMonthAndYear(value) {
        const [month, year] = parseMonthAndYear(value);
        return !value || (month >= 1 && month <= 12 && ! isNaN(year));
    },

    /**
     * Onko arvo muodossa MM/YYYY tai YYYY-MM tai tyhjä.
     * @param value
     * @returns {boolean}
     */
    isMonthAndYearOrYearAndMonth(value) {
        // eslint-disable-next-line security/detect-unsafe-regex
        const pattern = /^(\d{1,2}[/-]\d{4}|\d{4}[/-]\d{1,2})(, ?(\d{1,2}[/-]\d{4}|\d{4}[/-]\d{1,2}))*$/;
        return ! value || pattern.test(value);
    },

    /**
     * Onko arvo muodossa MM/YYYY tai tyhjä.
     * @param value
     * @returns {boolean}
     */
    isYearAndMonth(value) {
        const [year, month] = parseYearAndMonth(value);
        return !value || (month >= 1 && month <= 12 && ! isNaN(year));
    },

    isNotRingInsuranceNumber(value) {
        return !value || ! ['821-0721357', '923575', '40-436-022-8', '40-436-023-6'].includes(value);
    },

    isJson(value) {
        try {
            JSON.parse(value);
            return true;
        } catch (e) {
            return false;
        }
    },

    isNotEmptyArray(value) {
        const filteredArray = value.filter((val) => val);
        return filteredArray.length === value.length && filteredArray.length > 0;
    },
};

export const errorMessages = {
    atLeastOne: Translator.trans('Valitse vähintään yksi.', {}, 'common'),
    isRequired: Translator.trans('Kenttä on pakollinen. ', {}, 'common'),
    isNotRequired: Translator.trans('Kentän on oltava tyhjä.', {}, 'common'),
    isSsn: Translator.trans('Anna oikeanmuotoinen henkilötunnus. ', {}, 'common'),
    isSocialSecurityNumber: Translator.trans('Anna oikeanmuotoinen henkilötunnus. ', {}, 'common'),
    isValidIban: Translator.trans('Tilinumero ei ole kelvollinen IBAN-tilinumero. ', {}, 'common'),
    isYTunnus: Translator.trans('Anna oikeanmuotoinen Y-tunnus. ', {}, 'common'),
    isEmail: Translator.trans('Anna oikeanmuotoinen sähköpostiosoite. ', {}, 'common'),
    isPostCode: Translator.trans('Anna oikeanmuotoinen postinumero. ', {}, 'common'),
    noUmlauts: Translator.trans('Syöte ei saa sisältää ääkkösiä. ', {}, 'common'),
    isInteger: Translator.trans('Anna kokonaisluku. ', {}, 'common'),
    isDecimal: Translator.trans('Anna numeerinen arvo. ', {}, 'common'),
    isPositiveDecimal: Translator.trans('Anna positiivinen numeerinen arvo. ', {}, 'common'),
    isNumeric: Translator.trans('Anna numeerinen arvo. ', {}, 'common'),
    isAlphanumeric: Translator.trans('Syöte saa sisältää vain kirjaimia ja numeroita.', {}, 'common'),
    isNonNegative: Translator.trans('Anna positiivinen arvo. ', {}, 'common'),
    isNegative: Translator.trans('Anna negatiivinen arvo. ', {}, 'common'),
    minLength: Translator.trans('Vähimmäismäärää ei saavutettu. ', {}, 'common'),
    maxLength: Translator.trans('Enimmäismäärä ylitetty. ', {}, 'common'),
    isLT: Translator.trans('Anna pienempi arvo. ', {}, 'common'),
    isLTx: (max) => Translator.trans(`Anna arvo joka on pienempi kuin %max%.`, { max }, 'common'),
    isLTE: Translator.trans('Anna pienempi arvo. ', {}, 'common'),
    isLTEx: (max) => Translator.trans(`Anna arvo joka on yhtä pieni tai pienempi kuin %max%.`, { max }, 'common'),
    isGT: Translator.trans('Anna suurempi arvo. ', {}, 'common'),
    isGTx: (min) => Translator.trans(`Anna arvo joka on suurempi kuin %min%.`, { min }, 'common'),
    isGTE: Translator.trans('Anna suurempi arvo. ', {}, 'common'),
    isGTEx: (min) => Translator.trans(`Anna arvo joka on yhtä suuri tai suurempi kuin %min%.`, { min }, 'common'),
    inRange: Translator.trans('Arvo ei ole sallittujen rajojen sisällä.', {}, 'common'),
    isEqual: Translator.trans('Arvot eivät täsmää. ', {}, 'common'),
    isDate: Translator.trans('Anna oikeanmuotoinen päivämäärä. ', {}, 'common'),
    isDateUnavailable: Translator.trans('Valittu päivämäärä ei ole sallittu.', {}, 'common'),
    isISODate: Translator.trans('Anna oikeanmuotoinen päivämäärä. ', {}, 'common'),
    isDateRange: Translator.trans('Anna oikeanmuotoinen aikaväli. ', {}, 'common'),
    inDateRange: Translator.trans('Aikaväli ei ole sallittujen päivämäärien sisällä.', {}, 'common'),
    isDateRangeUnavailable: Translator.trans('Valittu päivämäärä ei ole sallittu.', {}, 'common'),
    isDateTooFarInThePast: (yearLimit = 5) => Translator.trans(`Päivämäärä voi olla enintään %yearLimit% vuotta menneisyydessä.`, { yearLimit }, 'common'),
    isDateTooFarInTheFuture: (yearLimit = 5) => Translator.trans(`Päivämäärä voi olla enintään %yearLimit% vuotta tulevaisuudessa.`, { yearLimit }, 'common'),
    isMonthSpan: Translator.trans('Anna kuukaudet muodossa MM/YYYY. Loppukuukausi ei voi olla ennen alkukuukautta.', {}, 'common'),
    isMonthSpanUnavailable: Translator.trans('Valitut kuukaudet eivät ole saatavilla.', {}, 'common'),
    isGTDate: Translator.trans('Anna myöhempi päivämäärä. ', {}, 'common'),
    isLTDate: Translator.trans('Anna aikaisempi päivämäärä. ', {}, 'common'),
    isGTNowDate: Translator.trans('Anna myöhempi päivämäärä. ', {}, 'common'),
    holidayBonusAndDayCount: Translator.trans('Lomarahaa ei voida maksaa mikäli aiemmalta lomavuodelta tuodaan ainoastaan lomakorvaus.', {}, 'common'),
    earnedAndSpentHolidayDayCount: Translator.trans('Käytettyjen lomapäivien määrä saa ylittää ansaittujen lomapäivien määrän enintään 0,5:llä', {}, 'common'),
    holidayDayCountAndFullWorkingYear: Translator.trans('Työntekijälle ei ole kertynyt täyttä työvuotta. Tuo lomavuodelle enintään 2 lomapv. / kuukausi. Loput lomapäivät kerrytetään automaattisesti työvuoden tullessa täyteen.', {}, 'common'),
    isHolidayDayDay: Translator.trans('Arvo voi olla joko n tai n.5 (esim 2 tai 5.5)', {}, 'common'),
    isHolidayDayMonth: Translator.trans('Syötä arvo muodossa YYYY-MM', {}, 'common'),
    isHolidayDayYear: Translator.trans('Syötä arvo muodossa YYYY', {}, 'common'),
    isDecimalOrEmpty: Translator.trans('Anna numeerinen arvo tai jätä kenttä tyhjäksi. ', {}, 'common'),
    isHolidayDayDayOrEmpty: Translator.trans('Arvo voi olla joko n tai n.5 (esim 2 tai 5.5) tai jätä kenttä tyhjäksi. ', {}, 'common'),
    isDefaultRequiredValue: Translator.trans('Kenttä ei saa olla tyhjä. ', {}, 'common'),
    isLegalSideAccepted: Translator.trans('Sinun tulee hyväksyä käyttöehdot ja rekisteriseloste.', {}, 'common'),
    isEuroOrPercentage: Translator.trans('Syötä joko euromääräinen tai prosentuaalinen arvo.', {}, 'common'),
    isCommaSeparatedAlphaNumeric: Translator.trans('Arvot saavat sisältää vain numeroita ja kirjaimia. Erottele arvot pilkulla.', {}, 'common'),
    passwordsMatch: Translator.trans('Salasanat eivät täsmää.', {}, 'common'),
    sameAsOldPassword: Translator.trans('Uusi salasana ei voi olla sama kuin vanha.', {}, 'common'),
    isGDPRAccepted: Translator.trans('Sinun tulee hyväksyä REC30-palvelun palvelukuvaus ja tietosuojasopimusliite.', {}, 'common'),
    isRf: Translator.trans('RF-viitenumero ei ole kelvollinen', {}, 'common'),
    isReferenceNumber: Translator.trans('Viitenumero ei ole kelvollinen.', {}, 'common'),
    isMonthAndYear: Translator.trans('Syötä kuukaudet muodossa mm/yyyy, mm/yyyy', {}, 'common'),

    // Erikoiskäännökset. Käytä oikeissa kentissä. Käyttö validaattorina esim.: isAge: validators.isNumeric
    isAge: Translator.trans('Anna ikä. ', {}, 'common'),
    isTaxPercent: Translator.trans('Anna veroprosentti. ', {}, 'common'),
    isTaxPercentInRange: Translator.trans('Anna veroprosentti väliltä 0-60. ', {}, 'common'),
    isValidStartDate: Translator.trans('Anna oikeanmuotoinen aloituspäivämäärä. ', {}, 'common'),
    isValidEndDate: Translator.trans('Anna oikeanmuotoinen päättymispäivämäärä. ', {}, 'common'),
    isSalary: Translator.trans('Anna oikeanmuotoinen valuutta. ', {}, 'common'),
    isPhoneNumber: Translator.trans('Anna oikeanmuotoinen puhelinnumero.', {}, 'common'),
    isUniqueEmail: Translator.trans('Sähköpostiosoite on jo käytössä.', {}, 'common'),
    isUniqueEmailNotIdentified: Translator.trans('Sähköposti on jo käytössä käyttäjällä, joka ei ole tunnistautunut', {}, 'common'),
    isEmailValid: Translator.trans('Sähköpostiosoite ei ole kelvollinen', {}, 'common'),
    isInsuranceMaterialAccepted: Translator.trans('Sinun on hyväksyttävä OP:n työtapaturmavakuutuksen sisältö ja vakuutusta koskevat tiedot.', {}, 'common'),
    isNotRingInsuranceNumber: Translator.trans('Oiman hallinnoiman vakuutuksen numero ei kelpaa.', {}, 'common'),
    isNotEmptyArray: Translator.trans('Kenttä on pakollinen'),
    isUniqueIban: Translator.trans('Et voi tallentaa tilinumeroa, joka on käytössä jo kahdella tulonsaajalla. Ota tarvittaessa yhteys Oiman asiakaspalveluun.', {}, 'common'),
    isIbanValid: Translator.trans('Tilinumero ei ole kelvollinen', {}, 'common')
};

export default validators;
