import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { select } from '@rematch/select';
import { Value } from 'shared/components/Value';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { jobDescriptionMap } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Description';

/**
 * Työtehtävän kuvaus valitsin.
 * Jos tehtävänkuvauksia (listalta) ei valittuna, näytetään description.
 * Muutoin mapataan valitut tehtävänkuvaukset ja mahdollinen description mikäli valittu "other".
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const Description = ({ value, label, attributes }) => {
    const options = attributes?.options;
    const jobDescriptionTypes = useSelector(select.contract.getJobDescriptionTypes);
    const jobDescriptions = useMemo(() => (
        jobDescriptionTypes
            .map((jobDescriptionType) => {
                const jobDescription = (options ?? jobDescriptionMap).find(({ value }) => value === jobDescriptionType?.toString());
                if (jobDescription?.value === 'other') return value;

                // Voi olla että pohjaan tehty muutoksia valinnoille jotka ei aiemmin luoduilla soppareilla ole enää
                // käytössä joten tarkistellaan että ylipäätään löytyy valinta
                return jobDescription?.label
                    ? _transObj(jobDescription?.label)
                    : null;
            })
            .filter(Boolean)
    ), [options, jobDescriptionTypes, value]);

    return (
        <SummaryListItem term={label}>
            <Value>
                {jobDescriptionTypes.length === 0
                    ? value
                    : (
                        <ul className={classNames('u-margin-bottom-none', {
                            'o-list-bare': jobDescriptions.length === 1,
                        })}>
                            {jobDescriptions.map((jobDescription, key) => (
                                <li key={key}>
                                    {jobDescription}
                                </li>
                            ))}
                        </ul>
                    )}
            </Value>
        </SummaryListItem>
    );
};

Description.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default Description;
