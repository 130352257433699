import React, { Fragment, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import { useBooleanToggle } from '@mantine/hooks';
import { useGet } from 'shared/hooks/useApi';
import { DropzoneUploader } from 'shared/containers/DropzoneUploader';
import { FormField, Form, ChoiceField, MaxTextAreaField } from 'shared/components/Formik';
import { ActionBar, Feedback, SubmitButton } from 'shared/components';
import { alert } from 'shared/utils';
import { isPayPeriodInOpenState } from 'shared/constants/payPeriodStates';
import { documentsCategoryTypes } from 'shared/DocumentsCategories/constants/documentsCategoryTypes';
import { DocumentExpireDateInput } from 'shared/DocumentsCategories/components/DocumentExpireDateInput';
import {
    FAMILY_CARE_START_COMPENSATION,
    FAMILY_CARE_ADDITIONAL_COMPENSATION,
    FAMILY_OR_RELATIVE_CARE_TRAVELLING_COMPENSATION,
    SUPPORTER_TRAVELLING_COMPENSATION,
    AUXILIARY_CUSTODY_TRAVELLING_COMPENSATION,
    SUPPORTER_COST_REIMBURSEMENT,
    AUXILIARY_CUSTODY_COST_REIMBURSEMENT,
    COMPENSATION_EARNER_DOCUMENT_TYPES,
    FAMILY_OR_RELATIVE_CARE_DOCUMENT_TYPES,
} from 'TimeRegistration/pay-period-data/PayPeriodDataType';
import { Value } from 'shared/components/Value';
import { MaxInputField } from 'shared/components/Formik/MaxInputField';
import GoBackLink from 'shared/components/GoBackLink';
import { isCommune } from 'shared/utils/commonUtils';

const inputNameToLabelMap = {
    name: _trans('Dokumentin nimi', {}, 'documents'),
    category: _trans('Kategoria', {}, 'documents'),
    expireDate: _trans('Viimeinen voimassaolopäivä', 'common'),
    description: _trans('Kuvaus', {}, 'common'),
    fileName: _trans('Tiedostonimi', {}, 'common'),
};

const translatePayPeriodDataType = (type) => {
    switch (type) {
        case FAMILY_CARE_START_COMPENSATION: return _trans('Käynnistämiskorvaus', {}, 'documents');
        case FAMILY_CARE_ADDITIONAL_COMPENSATION: return _trans('Erilliskorvaus', {}, 'documents');
        case FAMILY_OR_RELATIVE_CARE_TRAVELLING_COMPENSATION: return _trans('Matkakustannusten korvaus', {}, 'documents');
        case SUPPORTER_TRAVELLING_COMPENSATION: return _trans('Tukihenkilön Matkakustannusten korvaus', {}, 'documents');
        case AUXILIARY_CUSTODY_TRAVELLING_COMPENSATION: return _trans('Oheishuoltajan matkakustannustenkorvaus', {}, 'documents');
        case SUPPORTER_COST_REIMBURSEMENT: return _trans('Tukihenkilön kulukorvaus', {}, 'extract');
        case AUXILIARY_CUSTODY_COST_REIMBURSEMENT: return _trans('Oheishuoltajan kulukorvaus (ei toimeksiantosopimusta)', {}, 'extract');
    }
};

export const DocumentsAttachmentForm = ({ isCompensationEarnerContract, categoryType, relatedEntityId, document, onSuccess, goBackTo }) => {
    const dispatch = useDispatch();
    const isLoadingUserMetadata = useSelector((state) => state.loading.effects.userMetadata.fetchUserMetadata);
    const categories = useSelector(select.documents.getCategories);
    const dropzoneRef = useRef();
    const isOperator = useSelector(select.userMetadata.isOperator);
    const isEmployee = useSelector(select.userMetadata.isEmployee);
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const [isPending, setPending] = useBooleanToggle(false);
    const [{ data, isLoading }, fetchPayPeriod] = useGet(`/api/v2/payperiods/${relatedEntityId}/state`, { lazy: true });

    const isNewDocument = document === null;
    const isFormDisabled = ! isPayPeriodInOpenState(data?.data) && categoryType === documentsCategoryTypes.PAY_PERIOD && ! isNewDocument;

    useEffect(() => {
        if (categoryType === documentsCategoryTypes.JOB_CONTRACT) {
            dispatch.documents.fetchContractCategories(relatedEntityId);
        } else if (categoryType === documentsCategoryTypes.PAY_PERIOD) {
            dispatch.documents.fetchPayPeriodCategories(relatedEntityId);
            fetchPayPeriod();
        } else {
            dispatch.documents.fetchCategories([categoryType]);
        }
    }, [categoryType, relatedEntityId, fetchPayPeriod]);

    const initialValues = isNewDocument
        ? {
            name: '',
            expireDate: '',
            description: '',
            category: '',
            payPeriodDataType: '',
        }
        : {
            ...document,
            category: document.category?.uuid,
        };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                if (isNewDocument) {
                    setPending(true);
                    dropzoneRef.current.processQueue();
                } else if (isOperator) {
                    onSuccess(values);
                }
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required(_trans('Dokumentille on annettava aihe', {}, 'documents')),
                expireDate: Yup.string().when('category', {
                    is: (category) => ! categories.find((c) => c.uuid === category)?.isExpiryAfterContractTerminating,
                    then: Yup.string().required('Viimeinen voimassaolopäivä on annettava', {}, 'common'),
                }),
                description: Yup.string().max(200).required('Dokumentin kuvaus on annettava', {}, 'common'),
                category: Yup.string().required('Kategoria on valittava', {}, 'common'),
            })}
        >
            {({ values }) => {
                const category = isNewDocument
                    ? categories.find((c) => c.uuid === values?.category)
                    : document.category;
                return (
                    <Form hasDebugButton>
                        {isFormDisabled && (
                            <Feedback type="info" modifierClass="u-margin-bottom">
                                {_transMd('Dokumenttia ei voi enää muokata sillä [jakso](%payPeriodUrl%), jolle se on liitetty, on jo hyväksytty.', {
                                    payPeriodUrl: isCommune() ? `/commune/timeregistration-by-pay-period/${relatedEntityId}`: `/home/timeregistration-by-pay-period/${relatedEntityId}`,
                                }, 'documents')}
                            </Feedback>
                        )}
                        <FormField
                            name="category" label={inputNameToLabelMap.category}
                            tooltip={categories.length > 1 ? _trans('Valitse kategoria perustuen dokumentin käyttötarkoitukseen', {}, 'documents') : null}
                            isContentFormField={isNewDocument}
                        >
                            {isNewDocument
                                ? (
                                    <ChoiceField
                                        options={categories.map((c) => ({
                                            value: c.uuid,
                                            label: c.name,
                                        }))}
                                        disabled={isFormDisabled}
                                    />
                                )
                                : (
                                    <Value ariaDescribedBy="category">{document.category?.name ?? '-'}</Value>
                                )}

                        </FormField>
                        <FormField name="name" label={inputNameToLabelMap.name}>
                            <MaxInputField maxLength={100} modifierClass="u-1/1" disabled={isFormDisabled} />
                        </FormField>
                        {category?.isExpiryAfterContractTerminating
                            ? (
                                <FormField
                                    label={inputNameToLabelMap.expireDate}
                                    isContentFormField={false}
                                >
                                    <Value>
                                        {_trans('Sopimuksen päättymisen jälkeen %year% vuotta ja %month% kuukautta', {
                                            year: category.defaultExpiryYear,
                                            month: category.defaultExpiryMonth,
                                        }, 'documents')}
                                    </Value>
                                </FormField>
                            ) : (
                                <DocumentExpireDateInput
                                    isDisabled={isFormDisabled}
                                    label={inputNameToLabelMap.expireDate}
                                    isInputHidden={isEmployee || isEmployer || isLoadingUserMetadata}
                                    categoryToExpiryMap={categories.reduce((map, category) => ({ ...map, [category.uuid]: category.defaultExpiryDate }), {})}
                                />
                            )}
                        <FormField name="description" label={inputNameToLabelMap.description}>
                            <MaxTextAreaField maxLength={200} rows={2} modifierClass="u-1/1" disabled={isFormDisabled} />
                        </FormField>
                        {category?.useSmartScan && (
                            <Fragment>
                                <FormField label={_trans('Valitse lisäkorvauslaji', {}, 'documents')} name="payPeriodDataType">
                                    <ChoiceField
                                        disabled={isFormDisabled}
                                        options={category.allowedPayPeriodDataTypes.filter((type) => {
                                            // Jos laji ei kuulu kumpaankaan, näytetään valinta
                                            if (! COMPENSATION_EARNER_DOCUMENT_TYPES.includes(type) && ! FAMILY_OR_RELATIVE_CARE_DOCUMENT_TYPES.includes(type)) {
                                                return true;
                                            }

                                            return isCompensationEarnerContract
                                                ? COMPENSATION_EARNER_DOCUMENT_TYPES.includes(type)
                                                : FAMILY_OR_RELATIVE_CARE_DOCUMENT_TYPES.includes(type);

                                        }).map((type) => ({
                                            value: type.toString(),
                                            label: translatePayPeriodDataType(type),
                                        }))}
                                    />
                                </FormField>
                                <Feedback modifierClass="u-margin-bottom-small" type="info">
                                    {_trans('Tallennutusta dokumentista yritetään lukea kokonaissumma sekä päivämäärä, jotka esitäytetään lisäkorvauslomakkeelle.', {}, 'documents')}
                                </Feedback>
                            </Fragment>
                        )}
                        {(isNewDocument && ! isFormDisabled) && (
                            <DropzoneUploader
                                onSuccess={(resp) => {
                                    dispatch.documents.fetchDocuments({ categoryType, relatedId: relatedEntityId });
                                    setPending(false);
                                    const json = JSON.parse(resp.xhr.response);
                                    onSuccess(json.document, json.isSmartScan ? { ...json.smartScanResult, description: json.description } : null);
                                }}
                                onFailure={(file, message) => {
                                    setPending(false);

                                    // Jos xhr ei ole, niin ei olla aloitettu edes uploadausta, vaan virheellinen tiedosto
                                    if (! file.xhr) {
                                        dropzoneRef.current.removeAllFiles();
                                        alert(<p>{message}</p>);

                                        return;
                                    }
                                    // Poista tiedosto ja lisää uudestaan jonoon => antaa mahdollisuuden korjailla.
                                    dropzoneRef.current.removeAllFiles();
                                    dropzoneRef.current.addFile(file);
                                    const error = JSON.parse(file.xhr.response).error;
                                    alert(
                                        <Fragment>
                                            <p>
                                                {_trans('Dokumentin tallentaminen ei onnistunut.', {}, 'documents')}
                                            </p>
                                            <ul className="u-margin-bottom-none">
                                                {error === 'error.maxsize' && <li>{_trans('Tiedostokoko on liian suuri. Maksimi 5MB', {}, 'documents')}</li>}
                                                {/* Muodostaa 'fieldName:errorMessage;fieldName2:errorMessage2' -> [[fieldName, errorMessage], ...] */}
                                                {error?.split(';').map((error) => error.split(':')).map(([fieldLabel, message], key) => (
                                                    <li key={key}>
                                                        <b>{inputNameToLabelMap[fieldLabel]}</b>
                                                        <br/>{message}
                                                    </li>
                                                ))}
                                            </ul>
                                        </Fragment>
                                    );
                                }}
                                maxFiles={1}
                                uploadUrl="/_uploader/document/upload"
                                id="documents"
                                acceptedFiles="application/pdf,image/*"
                                customParameters={{
                                    folder: `/documents/${categoryType}`,
                                    relatedEntityId,
                                    ...values,
                                }}
                                ref={dropzoneRef}
                                isAutoProcessQueue={false}
                            />
                        )}
                        <ActionBar alignItems={goBackTo ? 'justify' : 'right'} modifierClass="u-margin-top-small u-padding-bottom-large@small">
                            {goBackTo && (
                                <GoBackLink to={goBackTo} />
                            )}
                            <SubmitButton
                                isPending={isPending || isLoading}
                                disabled={isFormDisabled}
                                mdIcon="check"
                            >
                                {_trans('Tallenna', {}, 'common')}
                            </SubmitButton>
                        </ActionBar>
                    </Form>
                );
            }}
        </Formik>
    );
};

DocumentsAttachmentForm.propTypes = {
    isCompensationEarnerContract: PropTypes.bool,
    categoryType: PropTypes.string.isRequired,
    relatedEntityId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func.isRequired,
    document: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
    goBackTo: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
};

DocumentsAttachmentForm.defaultProps = {
    isCompensationEarnerContract: false,
    document: null,
    goBackTo: null,
};
