import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import UserBlock from 'shared/components/UserBlock';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';

/**
 * Työnantaja palikka lomakkeella
 */
const ReportingFormsEmployer = ({ label, attributes } ) => {
    const { isEmailHidden, isSsnVisible, isPhoneNumberHidden, isAddressHidden } = attributes;
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isLoading = useSelector((state) => state.loading.effects.loggedUser.fetchUserData);
    const employer = useSelector(select.contract.getEmployer);

    const details = [
        // Näytetään sähköposti listalla jos valittu että hetu näytetään (näkyy osana UserBlockia)
        [!isEmailHidden && !!isSsnVisible ? employer.email : ''],
        [!isAddressHidden ? employer.fullAddress : ''],
        [!isPhoneNumberHidden ? employer.phoneNumber : ''],
    ].filter((detail) => detail.toString().trim() !== '');

    return (
        <SummaryListItem term={label}>
            <div className="u-margin-bottom-tiny">
                <UserBlock
                    user={employer}
                    isLoading={isLoading}
                    size="small"
                    // HA pääsee työnantajan tietoihin. OPH-puolella luonnollisesti ei.
                    hasLinkToDetails={isCommune}
                    relationType="employer"
                    isInfoVisible={!!isSsnVisible || !isEmailHidden}
                    isSsnVisible={!!isSsnVisible}
                />
            </div>
            {isCommune && (
                <div>
                    {details.map((detail, key) => (
                        <Fragment key={key}>
                            {detail}<br/>
                        </Fragment>
                    ))}
                </div>
            )}
        </SummaryListItem>
    );
};

ReportingFormsEmployer.propTypes = {
    label: PropTypes.string,
    attributes: PropTypes.shape({
        isSsnVisible: PropTypes.bool,
        isPhoneNumberHidden: PropTypes.bool,
        isAddressHidden: PropTypes.bool,
        isEmailHidden: PropTypes.bool,
    }).isRequired,
};

ReportingFormsEmployer.defaultProps = {
    label: '',
};

export default ReportingFormsEmployer;
