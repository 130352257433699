import { Fragment, useMemo, useState } from 'react';
import { select } from '@rematch/select';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from 'shared/components/ActionBar';
import ConfirmButton from 'shared/components/ConfirmButton';
import { DataTable } from 'shared/containers/DataTable7';
import { needActionsToResolve, todoTypes } from 'shared/constants/todoTypes';
import { useApi } from 'shared/hooks/request';
import { customEvents, dispatchCustomEvent } from 'shared/utils/customEvents';

const UserDataProposalTodos = () => {
    const dispatch = useDispatch();
    const { makeRequest } = useApi();
    const [isConfirming, setIsConfirming] = useState(false);
    const [selectedTodos, setSelectedTodos] = useState([]);

    const todos = useSelector(select.todos.getTodos)
        .filter(({ type }) => [todoTypes.USER_SELF_EDIT_DATA_REQUIRE_CONFIRMATION, todoTypes.USER_SELF_EDIT_DATA].includes(type));

    const filterOptions = useMemo(() => {
        const todoList = todos.map((todo) => todo.message);
        return [...new Set(todoList)].map((message) => ({
            value: message,
            label: message,
        }));
    }, [todos]);

    return (
        <Fragment>
            <ActionBar alignItems="right">
                <ConfirmButton
                    title={_trans('Kuittaa valitut %count%', { count: selectedTodos.length }, 'todo')}
                    text={_trans('Kuittaa', {}, 'todo')}
                    confirmText={_trans('Haluatko varmasti kuitata %count% huomiota?', { count: selectedTodos.length }, 'todo')}
                    mdIcon="check"
                    primary
                    disabled={selectedTodos.length === 0}
                    onClick={() => {
                        setIsConfirming(true);
                        const requests = selectedTodos.map((selectedTodoId) => makeRequest('post', `/api/v2/todos/${selectedTodoId}/resolve`, {}));
                        Promise.all(requests).then(() => {
                            setIsConfirming(false);
                            dispatch.todos.fetchTodos();
                            setSelectedTodos([]);
                            dispatchCustomEvent(customEvents.UPDATE_NOTIFICATIONS);
                            dispatch.notifications.addSuccess(_trans('Kaikki valitut huomiot kuitattu.', {}, 'todo'));
                        });
                    }}
                >
                    {selectedTodos.length > 0
                        ? _trans('Kuittaa valitut %count%', { count: selectedTodos.length }, 'todo')
                        : _trans('Kuittaa valitut', {}, 'todo')}
                </ConfirmButton>
            </ActionBar>
            <DataTable
                data={todos}
                isSelectable
                resolveIsCheckboxRowDisabled={( value ) => needActionsToResolve.includes(value.type)}
                selectedRows={selectedTodos}
                selectedRowKey="id"
                onSelect={(value) => {
                    if (value.length !== selectedTodos.length && ! isConfirming) {
                        setSelectedTodos(value);
                    }
                }}
                isTotalVisible={false}
                columns={[
                    {
                        Header: _trans('Viesti', {}, 'notifications'),
                        accessor: 'message',
                        type: 'link',
                        route: ({ original: { url } }) => url,
                        filterType: 'checkbox',
                        filterOptions,
                    }
                ]}
            />
        </Fragment>
    );
};
export default UserDataProposalTodos;
