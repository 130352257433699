import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { StatusIndicator } from 'shared/components/StatusIndicator';

/**
 * Tehtävälista.
 * Käytetään esimerkiksi tilanteissa joissa halutaan viestiä käyttäjälle ne kohdat jotka on vielä tehtävä.
 */
export const TodoList = ({ list, modifierClass }) => (
    <ul className={classNames('o-list-bare', modifierClass)}>
        {list
            .filter((item) => item?.isVisible ?? true)
            .map((item, key) => {
                const isTaskDone = item?.isDone ?? false;
                return (
                    <li key={key} className="o-list-bare__item">
                        <StatusIndicator message={item?.task ?? '-'} status={isTaskDone ? 'success' : 'error'} />
                    </li>
                );
            })}
    </ul>
);

TodoList.propTypes = {
    /**
     * Todo list
     */
    list: PropTypes.arrayOf(PropTypes.shape({
        isDone: PropTypes.bool,
        task: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        isVisible: PropTypes.bool,
    })).isRequired,
    /**
     * CSS modification class.
     */
    modifierClass: PropTypes.string,
};

TodoList.defaultProps = {
    modifierClass: '',
};
