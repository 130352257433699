import React from 'react';
import PropTypes from 'prop-types';
import DateRangeInput from './DateRangeInput';

/**
 * Yhden päivämäärän kalenterivalinta.
 */
const DateInput = ({ placeholder, ...restProps }) => (
    <DateRangeInput
        {...restProps}
        hasSingleInput
        startDatePlaceholder={placeholder}
    />
);

DateInput.propTypes = {
    placeholder: PropTypes.string,
};

DateInput.defaultProps = {
    placeholder: _trans('date_range.start'),
};

export default DateInput;
