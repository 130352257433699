/**
 * @deprecated Use MaxTextAreaField
 */
import React from 'react';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import { MaxTextArea } from 'shared/components/index';

const FormikMaxTextArea = ({ name, maxLength, formik, ...rest }) => {
    const { setFieldValue, values } = formik;
    return (
        <MaxTextArea
            onChange={(value) => setFieldValue(name, value)}
            value={_.get(values, name)}
            maxLength={maxLength}
            {...rest}
        />
    );
};

FormikMaxTextArea.propTypes = {
    name: PropTypes.string.isRequired,
    formik: PropTypes.object,
    maxLength: PropTypes.number,
};

FormikMaxTextArea.defaultProps = {
    formik: {},
    maxLength: 250,
};

export default connect(FormikMaxTextArea);
