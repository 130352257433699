//testi
export const occupationalHealth = [
    { name:'Terveystalo Oy', id: 1 },
    { name: 'Pihlajalinna Oy', id: 2 },
    { name: 'Mehiläinen oy', id: 3 },
    { name: 'Heltti oy', id: 4 },
    { name:  'Täsmä Työterveys Oy', id: 5 },
    { name:  'Pirte Oy', id: 6 },
    { name: 'Forssan Oiva Työterveys Oy', id: 7 },
    { name: 'Lappeenrannan Työterveys Oy', id: 8 },
    { name: 'Oulunkaaren työterveys oy', id: 9 },
    { name: 'Työterveys Lappica Oy', id: 10 },
    { name: 'Terppa Työterveys ', id: 11 },
    { name: 'Norre työterveys', id: 12 },
    { name: 'TT Botnia oy', id: 13 },
    { name: 'Lääkärikeskus Aava oy', id: 14 },
    { name: 'Salpausselän terveys oy', id: 15 },
    { name: 'muu, mikä?', id: 999 },
];

export const occupationalHealthOptions =
    [...occupationalHealth
        .sort((a, b) => a?.id !== 999 ? a.name.localeCompare(b.name) : 1),
    ];

