import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { userSelectors } from 'shared/UserDetails/stores/user';
import { CheckboxField, Form, FormField, StreetAddressAutoCompleteField, TextField } from 'shared/components/Formik';
import { ActionBar, Button, Page, Panel, SubmitButton } from 'shared/components';

export const AdditionalAddressForm = ({ toggleForm, address, setAddress }) => {
    const user = useSelector(userSelectors.getUser);
    const dispatch = useDispatch();

    const isNew = Object.values(address).length === 0;

    const initialValues = isNew
        ? {
            firstName: '',
            lastName: '',
            streetAddress: '',
            postCode: '',
            town: '',
            label: '',
            isInvoicingAddress: false,
        } : {
            ...address
        };

    return (
        <Page>
            <Panel
                heading={isNew ? _trans('Lisää uusi osoite', {}, 'additional_address') : _trans('Muokkaa osoitetta', {}, 'additional_address')}
            >
                <div className="c-header__back-button u-margin-bottom@medium">
                    <Button
                        onClick={() => {
                            setAddress({});
                            toggleForm();
                        }}
                        flat mdIcon="arrow_back" modifierClass="c-button--go-back"
                    >
                        {_trans('Peruuta', {}, 'common')}
                    </Button>
                </div>
                <Formik
                    initialValues = {initialValues}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string(),
                        lastName: Yup.string(),
                        streetAddress: Yup.string().required(),
                        postCode: Yup.string().max(5).required(),
                        town: Yup.string().required(),
                        label: Yup.string().required(),
                        isInvoicingAddress: Yup.bool().required(),
                    })}
                    onSubmit={( model ) => {
                        model = {
                            ...model,
                            firstName: model.firstName ? model.firstName : null,
                            lastName: model.lastName ? model.lastName : null,
                            userId: user.toJS().userId,
                        };
                        if (isNew) {
                            dispatch.additionalAddresses.postAdditionalAddress(model).then((json) => {
                                if (json.status === 'ok') {
                                    setAddress({});
                                    toggleForm();
                                }
                            });
                        } else {
                            dispatch.additionalAddresses.updateAdditionalAddress(model).then((json) => {
                                if (json.status === 'ok') {
                                    setAddress({});
                                    toggleForm();
                                }
                            });
                        }
                    }}
                >
                    {({ setFieldValue }) => (
                        <Form>
                            <FormField name="label" label={_trans('Tunniste', {}, 'common')}>
                                <TextField/>
                            </FormField>
                            <FormField name="firstName" label={_trans('Etunimi', {}, 'common')}>
                                <TextField/>
                            </FormField>
                            <FormField name="lastName" label={_trans('Sukunimi', {}, 'common')}>
                                <TextField/>
                            </FormField>
                            <FormField name="streetAddress" label={_trans('Osoite', {}, 'common')}>
                                <StreetAddressAutoCompleteField
                                    onSelect={({ postCode, city }) => {
                                        setFieldValue('postCode', postCode);
                                        setFieldValue('town', city);
                                    }}
                                />
                            </FormField>
                            <FormField name="postCode" label={_trans('Postinumero', {}, 'common')}>
                                <TextField/>
                            </FormField>
                            <FormField name="town" label={_trans('Postitoimipaikka', {}, 'common')}>
                                <TextField/>
                            </FormField>
                            <FormField name="isInvoicingAddress" label={_trans('Laskutusosoite', {}, 'common')}>
                                <CheckboxField/>
                            </FormField>
                            <ActionBar alignItems="right" modifierClass="u-margin-top-small">
                                <SubmitButton
                                    mdIcon="check"
                                >
                                    {_trans('Tallenna', {}, 'common')}
                                </SubmitButton>
                            </ActionBar>
                        </Form>
                    )}
                </Formik>
            </Panel>
        </Page>
    );
};

AdditionalAddressForm.propTypes = {
    toggleForm: PropTypes.func.isRequired,
    address: PropTypes.object.isRequired,
    setAddress: PropTypes.func.isRequired,
};
