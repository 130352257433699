import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { push } from 'connected-react-router';
import { Party } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Parties/Party';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';
import commonUtils, { isCommune } from 'shared/utils/commonUtils';
import resolveUrl from 'shared/utils/resolveUrl';
import { getPartyQueryByRelation } from 'ContractV3/utils/getPartyQueryByRelation';
import { generateRoutes } from 'ContractV3/constants/routes';
import { metadataNames } from 'ContractV3/constants/metadataNames';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { relationTypes } from 'shared/constants/communeCustomerRelationTypes';

const routes = generateRoutes(isCommune());

/**
 * Yksittäisen työntekijän valitsin.
 * Tätä käyttää Employee-palikka joka tukee tämän kautta montaa hoitajaa.
 * Voi myös antaa "readonlynä" jolloin arvo on esivalittu eikä sitä voi vaihtaa.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
export const SingleEmployee = (props) => {
    const dispatch = useDispatch();
    const { name, rootName, placeholder, attributes: { translations }, isSsnQuery, asyncEndPoint, onRemoveParty, label } = props;

    const [field,,helpers] = useField(name);
    const benefitDecisionId = useParams()?.benefitDecisionId;
    const [employeeAccessField, employeeAccessMeta, employeeAccessHelpers] = useField(metadataNames.IS_EMPLOYEE_NOT_USING_OIMA);
    const employeesNotUsingOima = useSelector(select.contract.isEmployeeNotUsingOima);
    const templateId = useSelector(select.contract.getTemplateId);
    const userRole = useSelector(select.userMetadata.getUserRole);
    const employees = useSelector(select.employees.getEmployees);
    const contractEmployees = useSelector(select.contract.getEmployees);
    const contractEmployeeUserIds = (contractEmployees ?? []).map((employee) => parseInt(employee?.userId, 10));

    const [employeeIdField] = useField(fieldNames.EMPLOYEE);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const isNewContract = useSelector(select.contract.isNewContract);
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    const isCompensationEarnerContract = useSelector(select.contract.isCompensationEarnerContract);

    const selectedEmployees = (employeeIdField.value ?? [])
        .filter((employee) => employee !== '')
        .map((employeeUserId) => parseInt(employeeUserId, 10));

    const omittedUserIds = (props.omittedUserIds ?? [])
        .concat(selectedEmployees)
        .map((id) => parseInt(id, 10));

    // Etsi mahdollista lisättyä työntekijää suoraan sopparilta tai jos käyttäjäid:tä ei löydy sieltä, suoraan employees-storesta
    const employee = useSelector((state) => (isNewContract && ! contractEmployeeUserIds.includes(parseInt(field.value, 10)))
        ? select.employees.getEmployeeById(state, field.value)
        : select.contract.getEmployeeById(state, field.value));

    // Lomittajan tapauksessa on listalta filtteröitävä pois toisaalla valittu hoitaja
    const filteredEmployees = omittedUserIds.length > 0
        ? employees.filter((employee) =>! omittedUserIds.includes(parseInt(employee.userId, 10)))
        : employees;

    const isLoading = useSelector((state) => (
        state.loading.effects.employees.fetchEmployees
        || state.loading.effects.userMetadata.fetchUserMetadata
    ));

    useEffect(() => {
        if (! isNaN(benefitDecisionId) && employee?.userId) {
            if (isEmployer) {
                dispatch.employeeWorkExperience.fetchEmployeeWorkExperienceByBenefitDecision(employee.userId, benefitDecisionId);
            } else {
                dispatch.employeeWorkExperience.fetchEmployeeWorkExperience(employee.userId);
            }
        }
    }, [benefitDecisionId]);

    // Tutkitaan ovatko kaikki TT:n tiedot oikein kun TT valitaan.
    useEffect(() => {
        const relationType = companyUserRelationTypes.RELATION_EMPLOYEE;

        // ohitetaan työntekijän tilinro vaatimus kun on kerran käynyt muokkauksen aikana käyttäjän lomakkeella,
        const allowEmptyIban = employee?.attributes?.allowEmployerWithoutAccountNumber ?? false;
        if (employee && !commonUtils.checkEmployeeReadiness(employee, false, allowEmptyIban)) {
            const errorMessage = employee?.socialSecurityNumber
                ? _trans('contract.notifications.error.employee_missing_data', {})
                : _trans('userForm.notifications.error.employee.socialSecurityNumber');

            if (isNewContract) {
                // Resetoidaan valinta ja ohjataan käyttäjän muokkaukseen
                helpers.setValue('');
                dispatch.notifications.addError(errorMessage, 5000);
                dispatch(push(`${resolveUrl(routes.NEW_CONTRACT, { benefitDecisionId })}?template=${templateId}${getPartyQueryByRelation(relationType, employee?.userId ?? '')}`));
            } else if (isAcceptedContract) {
                const routeBase = isCompany
                    ? 'company'
                    : '';

                // Jos jo hyväksytty soppari ohjataan muuttamaan henkilön tietoja omasta näkymästään.
                // Näkymään mennessä lomake on valmiiksi editointitilassa.
                dispatch.notifications.addError(`${errorMessage} ${_trans('Voit muuttaa henkilön tietoja [henkilön omista tiedoista](%url%).', { 
                    url: `${resolveUrl(`/${routeBase}/users/show/:userId`, { userId: employee.userId })}?type=${relationType}&edit=1`, 
                }, 'jobContract')}`);
            }
        }
    }, [employee, isCompany, isNewContract, isAcceptedContract, isCompensationEarnerContract]);

    useEffect(() => {
        // Asetetaan TT:n Oimaan pääsy sen mukaan löytyykö sähköpostiosoitetta.
        if (employeeAccessMeta.touched === false && employee && ! isAcceptedContract) {
            const currentValue = employeeAccessField.value ?? [];
            const isEmployeeNotUsingOima = (employeesNotUsingOima ?? []).includes(employee?.userId?.toString());
            employeeAccessHelpers.setValue((! employee?.email || isEmployeeNotUsingOima)
                ? [...new Set(currentValue.concat(employee.userId?.toString()))]
                : currentValue.filter((userId) => parseInt(userId, 10) !== employee.userId));
        }
    }, [employee, employeeAccessMeta.touched, isAcceptedContract, employeesNotUsingOima]);

    return (
        <Party
            asyncEndPoint={asyncEndPoint}
            isSsnQuery={isSsnQuery}
            onRemoveParty={onRemoveParty}
            onSelectParty={(party) => {
                // Lisää uusi osapuoli
                dispatch.employees.addEmployee(party);
            }}
            // Listalta pois jo lisätyt
            filterResults={(results) => results
                .filter((employee) => ! selectedEmployees.includes(employee.userId))}
            fieldProps={{ name, rootName, label }}
            isLoading={isLoading}
            selectedParty={employee}
            parties={filteredEmployees}
            userRole={userRole}
            placeholder={placeholder}
            translations={translations}
            relationType={isCommune ? relationTypes.RELATION_EMPLOYEE : companyUserRelationTypes.RELATION_EMPLOYEE}
        />
    );
};

SingleEmployee.propTypes = {
    name: PropTypes.string.isRequired,
    rootName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    attributes: PropTypes.object,
    omittedUserIds: PropTypes.array,
    isSsnQuery: PropTypes.bool,
    asyncEndPoint: PropTypes.string,
    onRemoveParty: PropTypes.func.isRequired,
};

SingleEmployee.defaultProps = {
    placeholder: '',
    attributes: {},
    omittedUserIds: [],
    isSsnQuery: false,
    asyncEndPoint: '',
};
