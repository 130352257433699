import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useParams } from 'react-router-dom';
import UserBlock from 'shared/components/UserBlock';
import { FormField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';

/**
 * Työnantaja palikka lomakkeella
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const ReportingFormsEmployer = (props) => {
    const { label, attributes } = props;
    const { isEmailHidden, isSsnVisible, isPhoneNumberHidden, isAddressHidden } = attributes;
    const benefitDecisionId = useSelector(select.contract.getBenefitDecisionId);
    const paramBenefitDecisionId = useParams()?.benefitDecisionId;
    const activeBenefitDecisions = useSelector(select.benefitDecisions.getActiveBenefitDecisions);
    const isNewContract = useSelector(select.contract.isNewContract);
    const isCloningContract = useSelector(select.contract.isCloningContract);
    const isBuildMode = useSelector((state) => Object.hasOwn(state, 'contractBuilder'));
    const isCommune = useSelector(select.userMetadata.isCommune);
    const allowAssignmentContracts = useSelector(select.userMetadata.allowAssignmentContracts);
    const resolvedBenefitDecisionId = useMemo(() => {
        if (isBuildMode) return activeBenefitDecisions[0]?.id;
        return (isNewContract && ! isCloningContract)
            ? paramBenefitDecisionId
            : benefitDecisionId;
    }, [isBuildMode, paramBenefitDecisionId, benefitDecisionId, activeBenefitDecisions, isNewContract, isCloningContract]);

    // Rakentelumoodissa haetaan vaan eka aktiivinen asiakkuus
    const isLoading = useSelector((state) => allowAssignmentContracts
        ? state.loading.effects.loggedUser.fetchUserData
        : state.loading.effects.benefitDecision.fetchBenefitDecision);

    const isLoadingActiveBenefitDecisions = useSelector((state) => state.loading.effects.benefitDecisions.fetchActiveBenefitDecisions);

    // Muutetaan myöhemmin queryn kautta haettavaksi
    const employer = useSelector(resolvedBenefitDecisionId
        ? select.contract.getEmployer
        : select.loggedUser.getUser);

    if (isBuildMode) {
        return (
            <FormField isContentFormField={false} label={label}>
                <b className="o-form__text">Tiedot eivät näkyvissä editorissa.</b>
            </FormField>
        );
    }

    const details = [
        // Näytetään sähköposti listalla jos valittu että hetu näytetään (näkyy osana UserBlockia)
        [!isEmailHidden && !!isSsnVisible ? employer.email : ''],
        [!isAddressHidden ? employer.fullAddress : ''],
        [!isPhoneNumberHidden ? employer.phoneNumber : ''],
    ].filter((detail) => detail.toString().trim() !== '');


    return (
        <FormField {...getFieldProps(props)} isContentFormField={false}>
            <UserBlock
                user={employer ?? {}}
                isLoading={isLoading || (isBuildMode && isLoadingActiveBenefitDecisions || ! employer)}
                size="small"
                hasLinkToDetails={isCommune}
                relationType="employer"
                isInfoVisible={!!isSsnVisible || !isEmailHidden}
                isSsnVisible={!!isSsnVisible}
            />
            {isCommune && (
                <div className="o-form__text">
                    {details.map((detail, key) => (
                        <Fragment key={key}>
                            {detail}<br/>
                        </Fragment>
                    ))}
                </div>
        
            )}
        </FormField>
    );

};
ReportingFormsEmployer.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
        isSsnVisible: PropTypes.bool,
        isEmailHidden: PropTypes.bool,
        isPhoneNumberHidden: PropTypes.bool,
        isAddressHidden: PropTypes.bool,
    }).isRequired
};

export default ReportingFormsEmployer;
