import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { SignatureOnPaper } from 'shared/ReForm/components/SignatureOnPaper';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { templateSubTypes } from 'ContractV3/Builder/constants/templateTypes';
import { userSelectors } from 'shared/UserDetails/stores/user';

/**
 * Lomakkeen allekirjoittaja hoitajan/työntekijän puolelta.
 * @returns {JSX.Element}
 * @constructor
 */
const ReportingFormsSignaturesOnPaperEmployee = ({ label, templateSubType }) => {
    const isBuildMode = useSelector((state) => state.hasOwnProperty('contractBuilder'));

    const resolvedEmployees = useSelector((state) => {
        if (isBuildMode) return [];

        if (templateSubType === templateSubTypes.FAMILY_CARER) {
            return userSelectors.getUser(state).toJS();
        }
        return select.contract.getEmployees(state);
    });
    const employees = Array.isArray(resolvedEmployees) ? resolvedEmployees : [resolvedEmployees];

    return (
        <Fragment>
            {employees.map((employee, key) => (
                <SummaryListItem term={label} key={key}>
                    <strong className="u-visibility-screen">{employee?.fullName ?? '-'}</strong>
                    <SignatureOnPaper key={key} fullName={employee?.fullName ?? '-'} />
                </SummaryListItem>
            ))}
        </Fragment>
    );
};

ReportingFormsSignaturesOnPaperEmployee.propTypes = {
    label: PropTypes.string.isRequired,
    templateSubType: PropTypes.oneOf([templateSubTypes.CONTRACT, templateSubTypes.FAMILY_CARER]).isRequired,
};

export default ReportingFormsSignaturesOnPaperEmployee;
