import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import Page from 'shared/components/Page';
import { DataTable } from 'shared/containers/DataTable7';
import { ActionBar, Button, LinkIcon } from 'shared/components';
import { resolveUrl } from 'shared/utils';
import {
    documentsCategoryTypes,
    translateCategoryType
} from 'shared/DocumentsCategories/constants/documentsCategoryTypes';
import confirm from 'shared/utils/confirm';

export const CategoryList = ({ formRoute }) => {
    const dispatch = useDispatch();
    const categories = useSelector(select.documents.getCategories);
    const isLoading = useSelector((state) => state.loading.effects.documents.fetchCategories);
    const isAssignmentContract = useSelector(select.userMetadata.allowAssignmentContracts);
    const isCommune = useSelector(select.userMetadata.isCommune);

    useEffect(() => {
        dispatch.documents.fetchCategories();
    }, []);


    return (
        <Page
            heading={_trans('Dokumenttien kategoriat', {}, 'documents')}
            maxWidth="huge"
            actionItems={(
                <ActionBar alignItems="right">
                    <LinkIcon
                        modifierClass="c-button c-button--primary"
                        mdIcon="add"
                        to={resolveUrl(formRoute, { categoryId: 'new' })}
                    >
                        {_trans('Lisää kategoria', {}, 'documents')}
                    </LinkIcon>
                </ActionBar>
            )}
        >
            <DataTable
                isLoading={isLoading}
                actionsColumn={<ActionColumn formRoute={formRoute} dispatch={dispatch}/>}
                actionsColumnWidth="200"
                data={categories}
                columns={[
                    {
                        Header: _trans('Nimi', {}, 'common'),
                        accessor: 'name',
                    },
                    {
                        Header: _trans('Tyyppi', {}, 'common'),
                        accessor: (r) => translateCategoryType(r.categoryType, isCommune),
                        filterType: 'checkbox',
                        filterOptions: Object.values(documentsCategoryTypes)
                            .filter((type) => type === documentsCategoryTypes.BENEFIT_DECISION ? isCommune : true)
                            .map((type) => ({
                                value: translateCategoryType(type, isCommune),
                                label: translateCategoryType(type, isCommune),
                            })),
                    },
                    {
                        Header: _trans('Dokumentin lataus', {}, 'common'),
                        accessor: (r) => {
                            if (isAssignmentContract) {
                                if (r.allowEmployees) {
                                    return _trans('Hoitaja', {}, 'common');
                                }
                            } else {
                                if (r.allowEmployees && r.allowEmployers) {
                                    return _trans('Työntekijä', {}, 'common') + ', ' + _trans('Työnantaja', {}, 'common');
                                } else if (r.allowEmployees) {
                                    return _trans('Työntekijä', {}, 'common');
                                } else if (r.allowEmployers) {
                                    return _trans('Työnantaja', {}, 'common');
                                }
                            }

                            return '-';
                        }
                    },
                    {
                        Header: _trans('Dokumentin tallennus', {}, 'common'),
                        accessor: (r) => {
                            if (isAssignmentContract) {
                                if (r.allowEmployeesUpload) {
                                    return _trans('Hoitaja', {}, 'common');
                                }
                            } else {
                                if (r.allowEmployeesUpload && r.allowEmployersUpload) {
                                    return _trans('Työntekijä', {}, 'common') + ', ' + _trans('Työnantaja', {}, 'common');
                                } else if (r.allowEmployeesUpload) {
                                    return _trans('Työntekijä', {}, 'common');
                                } else if (r.allowEmployersUpload) {
                                    return _trans('Työnantaja', {}, 'common');
                                }
                            }

                            return '-';
                        }
                    },
                    {
                        Header: _trans('Oletusvanhenemispäivä', {}, 'documents'),
                        accessor: (r) => `${r.defaultExpiryYear} vuotta ja ${r.defaultExpiryMonth} kuukausi`,
                    }
                ]}
            />
        </Page>
    );
};

CategoryList.propTypes = {
    formRoute: PropTypes.string.isRequired,
};


const ActionColumn = ({ original, formRoute }) => {
    const dispatch = useDispatch();

    return (
        <ActionBar canWrap>
            <Button ghost mdIcon="edit" onClick={() => dispatch(push(resolveUrl(formRoute, { categoryId: original.uuid })))}>
                {_trans('Muokkaa', {}, 'common')}
            </Button>
            <Button
                mdIcon="delete_forever"
                negative
                modifierClass={'u-margin-left-small'}
                onClick={() => {
                    confirm(_trans('Haluatko varmasti poistaa kategorian?', {}, 'documents'), {
                        proceedLabel: _trans('Poista', {}, 'common'),
                        alert: true,
                    }).then(() => {
                        dispatch.documents.deleteCategory(original.uuid)
                            .then((json) => {
                                if (json.status === 'error') {
                                    dispatch.notifications.addError(_trans('Poistaminen ei onnistunut, kategoria sisältää liitteitä', {}, 'documents'));
                                } else {
                                    dispatch.documents.fetchCategories();
                                }
                            });
                    });
                }}
            >
                {_trans('Poista', {}, 'common')}
            </Button>
        </ActionBar>
    );
};

ActionColumn.propTypes = {
    original: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
    }),
    formRoute: PropTypes.string.isRequired,
};

ActionColumn.defaultProps = {
    original: {},
};


