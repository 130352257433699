import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { FormField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import UserBlock from 'shared/components/UserBlock';
import { metadataNames } from 'ContractV3/constants/metadataNames';
import {
    PreviousEmployerSignature
} from 'shared/ReForm/containers/CustomBlocks/ContractV3/components/Signatures/PreviousEmployerSignature';

const SignaturesBenefitDecisionEmployer = (props) => {
    const { name } = props;
    const [,,helpers] = useField(name);
    const isLoading = useSelector((state) => state.loading.effects.benefitDecision.fetchBenefitDecision);
    // Muutetaan myöhemmin queryn kautta haettavaksi
    const employer = useSelector(select.benefitDecision.getEmployer);
    const isSelfEmployed = useSelector(select.benefitDecision.isSelfEmployed);
    const isCommuneControlled = useSelector(select.benefitDecision.isCommuneControlled);
    const isIdentified = useSelector(select.contract.isBeneficiaryIdentified);
    const canEmployerSign = isSelfEmployed && isIdentified && ! isCommuneControlled;

    useEffect(() => {
        helpers.setValue(employer?.userId);
    }, [employer]);

    const [isReSignatureNeededField] = useField(metadataNames.IS_RE_SIGNATURE_NEEDED);
    const isReSignatureNeeded = isReSignatureNeededField.value;

    const hasPreviousSignatures = useSelector(select.contract.hasPreviousSignatures);

    // Ei vaadita uusia allekirjoituksia. Kerrotaan milloin viimeksi allekirjoitettu ja kenen toimesta.
    if (hasPreviousSignatures && isReSignatureNeeded === false) {
        return (
            <FormField
                {...getFieldProps(props)}
                label={_trans('Työntekijä', {}, 'jobContract')}
                isContentFormField={false}
            >
                <PreviousEmployerSignature isEmployerNotUsingOima={! canEmployerSign} />
            </FormField>
        );
    }

    return (
        <FormField {...getFieldProps(props)} isContentFormField={false}>
            <UserBlock
                user={employer}
                isLoading={isLoading}
                size="small"
                secondaryInfo="email"
            />
            {! canEmployerSign && (
                <div className="o-form__text">
                    {_trans('Työnantaja ei käytä Oimaa. Allekirjoitus tehdään sopimuksen paperiseen versioon.', {}, 'jobContract')}
                </div>
            )}
        </FormField>
    );
};

SignaturesBenefitDecisionEmployer.propTypes = {
    name: PropTypes.string.isRequired,
};

export default SignaturesBenefitDecisionEmployer;
