import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'shared/components/Button';
import { ActionBar, MDSpinner, Page } from 'shared/components';
import { userActions, userSelectors } from 'shared/UserDetails/stores/user';
import EmptyState from 'shared/components/EmptyState';
const UpdatedUserData = () => {
    const dispatch = useDispatch();
    const isUserDataUpdated = useSelector(select.userDataProposal.getIsUserDataUpdated);
    const user = useSelector(userSelectors.getUser).toJS();
    const isFetchingUser = useSelector(userSelectors.isFetchingUser);
    const { userId } = useParams();
    const userProposedData = useSelector(select.userDataProposal.getUserDataProposal);
    const isLoading = useSelector((state) => state.loading.effects.userMetadata.fetchUserMetadata);
    const isLoadingUserDataProposal = useSelector((state) => state.loading.effects.userDataProposal.fetchUserDataProposal);

    useEffect(() => {
        dispatch.userDataProposal.fetchUserDataProposal(userId);

    }, [dispatch, userId, isUserDataUpdated]);

    const userProposalDataFields = [
        { label: _trans('Etunimi', {}, 'common'), key: 'firstName' },
        { label: _trans('Sukunimi', {}, 'common'), key: 'lastName' },
        { label: _trans('Katuosoite', {}, 'common'), key: 'streetAddress' },
        { label: _trans('Postinumero', {}, 'common'), key: 'postCode' },
        { label: _trans('Postitoimipaikka', {}, 'common'), key: 'town' },
        { label: _trans('Puhelinumero', {}, 'common'), key: 'phoneNumber' },
        { label: _trans('Sähköposti', {}, 'common'), key: 'email' },
        { label: _trans('Tilinumero', {}, 'common'), key: 'accountNumber' },
    ];

    if (isLoading && (isLoadingUserDataProposal || isFetchingUser)) {
        return <MDSpinner wrapped />;
    }

    if (!isUserDataUpdated){
        return (
            <Page
                maxWidth="huge"
                heading={_trans('Käyttäjän muuttamat tiedot', {}, 'settings_new')}
            >
                <div className="o-stack o-stack--vertical o-stack--center u-text-center u-padding-small">
                    <EmptyState message={_trans('Ei muutoksia', {}, 'settings_new')}/>
                </div>
            </Page>
        );
    }
    return (
        <Page
            maxWidth="large"
            heading={_trans('Käyttäjän muuttamat tiedot', {}, 'settings_new')}
        >
            <ActionBar alignItems="right">
                <div className="o-stack u-margin-bottom">
                    <Button
                        type="submit"
                        mdIcon="clear"
                        onClick={() => {
                            dispatch.userDataProposal.rejectUserDataProposal(userId)
                                .then((response) => {
                                    if (response.status === 'ok') {
                                        dispatch.userDataProposal.fetchUserDataProposal(userId);
                                        dispatch.notifications.addInfo(_trans('Muutokset hylätty.', {}, 'settings_new'));
                                        window.location.hash = '#user_form';
                                    }
                                }).catch((error) => {
                                    console.error('Failed to reject user data proposal:', error);
                                    dispatch.notifications.addError(_trans('Muutosten hylkääminen epäonnistui.', {}, 'settings_new'));
                                });
                        }}

                    >
                        {_trans('Hylkää muutokset', {},'common')}
                    </Button>
                    <Button
                        primary
                        mdIcon="check"
                        onClick={() => {
                            dispatch.userDataProposal.acceptUserDataProposal(userId)
                                .then((json) => {
                                    if (json.status === 'ok') {
                                        dispatch.userDataProposal.fetchUserDataProposal(userId);
                                        dispatch(userActions.fetchUserDetails(userId));
                                        dispatch.notifications.addSuccess(_trans('Muutokset vahvistettu.', {}, 'settings_new'));
                                        window.location.hash = '#user_form';
                                    }
                                }).catch((error) => {
                                    console.error('Failed to accept user data proposal:', error);
                                    dispatch.notifications.addError(_trans('Muutosten vahvistaminen epäonnistui.', {}, 'settings_new'));
                                });
                        }}
                    >
                        {_trans('Vahvista muutokset', {},'common')}
                    </Button>
                </div>
            </ActionBar>
            <table className="o-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>{_trans('Vanha tieto', {}, 'settings_new')}</th>
                        <th>{_trans('Uusi tieto', {}, 'settings_new')}</th>
                        <th>{_trans('Muutettu tieto', {}, 'settings_new')}</th>
                    </tr>
                </thead>
                <tbody>
                    {userProposalDataFields.map((field, index) => {
                        const oldValue = user[field.key];
                        const newValue = userProposedData[field.key];
                        const isChanged = oldValue !== newValue;
                        return (
                            <tr key={index}>
                                <th>{field.label}</th>
                                <td>{oldValue}</td>
                                <td>
                                    {isChanged ? <ins>{newValue}</ins> : newValue}
                                </td>
                                <td>
                                    {isChanged ? (
                                        userProposedData
                                            ? _toLocaleDate(userProposedData.editTime)
                                            : _trans('Tuntematon aika', {}, 'settings_new')
                                    ) : (
                                        <span className="u-muted">{_trans('Ei muutoksia' , {}, 'settings_new')}</span>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

        </Page>
    );
};

export default UpdatedUserData;
