import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { MaxInput } from 'shared/components/MaxInput';

export const MaxInputField = ({ maxLength, ...rest }) => {
    const name = rest.name;
    const [field, , helpers] = useField(name);

    return (
        <MaxInput
            {...rest}
            {...field}
            onChange={(value) => helpers.setValue(value)}
            maxLength={maxLength}
        />
    );
};

MaxInputField.propTypes = {
    maxLength: PropTypes.number,
};

MaxInputField.defaultProps = {
    maxLength: 250,
};
