import _ from 'lodash';
import api from 'api';

/**
 * Kunnan asetukset.
 */
export const communeSettings = {
    state: {
        communeSettings: {},
        communeHourlyWage: 0,
        isMultipleWorkExpLimitsApplied: false,
    },

    reducers: {
        setCommuneSettings: (state, communeSettings) => ({ ...state, communeSettings }),
        setCommuneHourlyWage: (state, communeHourlyWage) => ({ ...state, communeHourlyWage }),
        setIsMultipleWorkExpLimitsApplied: (state, isMultipleWorkExpLimitsApplied) => ({ ...state, isMultipleWorkExpLimitsApplied })
    },

    selectors: {
        getCommuneSettings: (state) => state.communeSettings ?? {},
        getCostCenters: (state) => (state.cost_center ?? '').split(',').filter(Boolean),
        getCommuneHourlyWage: (state) => state.communeHourlyWage,
        isMultipleWorkExpLimitsApplied: (state) => state.isMultipleWorkExpLimitsApplied ?? false,
    },

    effects: (dispatch) => ({
        /**
         * Hakee ilmoitukset.
         */
        async fetchCommuneSettings(communeId) {
            try {
                const url = communeId
                    ? `/commune/api/v1/communes/${communeId}/settings`
                    : '/commune/api/v1/communes/current';

                const response = await api.get(url);
                if (! response) {
                    dispatch.notifications.addError(_trans('commune_settings.notifications.fetch_error'));
                }

                // Kokoa asetukset kahdesta arraysta
                // TODO: lodashit pois
                const settingsModel = _.zipObject(_.map(response, 'name'), _.map(response, 'value'));

                this.setCommuneSettings(settingsModel);
            } catch (error) {
                console.error(error);
                dispatch.notifications.addError(_trans('commune_settings.notifications.fetch_error'));
            }
        },

        async fetchCommuneHourlyWage({ benefitDecisionId }) {
            try {
                const response = await api.get(`/api/contracts/v1/benefits/${benefitDecisionId}/settings/commune_hourly_wage`);
                if (response.status === 'ok') {
                    this.setCommuneHourlyWage(response.data);
                } else {
                    dispatch.notifications.addError('Asetusta ei voitu hakea.');
                }
            } catch (error) {
                console.error(error);
                dispatch.notifications.addError('Asetusta ei voitu hakea.');
            }
        },

        async fetchIsMultipleWorkExpLimitsApplied() {
            try {
                const response = await api.get('/api/v1/work-experience/meta');
                if (response.status === 'ok') {
                    this.setIsMultipleWorkExpLimitsApplied(response?.data.isMultipleWorkExpLimitsApplied);
                }
            } catch (e) {
                console.error(e);
            }
        },
    })
};
