import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { FormField } from 'shared/components/Formik';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { metadataNames } from 'ContractV3/constants/metadataNames';
import UserBlock from 'shared/components/UserBlock';
import { templateSubTypes } from 'ContractV3/Builder/constants/templateTypes';

export const getTranslations = (isCareTaker) => ({
    waitingForSignature: isCareTaker
        ? _trans('Odottaa hoitajan %signatureIndex%/%signatureCount% allekirjoitusta', {}, 'jobContract')
        : _trans('Odottaa työntekijän %signatureIndex%/%signatureCount% allekirjoitusta', {}, 'jobContract'),

    isNotUsingOima: isCareTaker
        ? _trans('Hoitaja ei käytä Oimaa.', {}, 'jobContract')
        : _trans('Työntekijä ei käytä Oimaa.', {}, 'jobContract'),
});

/**
 * Työntekijän allekirjoitus.
 * Jos työntekijää ei ole vielä valittu, näyttää suoran "linkin" jonka kautta
 * hyppää työntekijän valitsimeen jos työntekijöitä ylipäätään löytyy.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const ReportingFormsSignaturesEmployee = (props) => {
    const { templateSubType } = props;

    const allowAssignmentContracts = useSelector(select.userMetadata.allowAssignmentContracts);
    const isFamilyCarer = useSelector(select.userMetadata.isFamilyCarer) || allowAssignmentContracts;

    const isOperator = useSelector(select.userMetadata.isOperator);

    const translations = getTranslations(isFamilyCarer);
    const { waitingForSignature } = translations;
    const [employeeAccessField,,employeeAccessHelpers] = useField(metadataNames.IS_EMPLOYEE_NOT_USING_OIMA);
    const isLoading = useSelector((state) => templateSubType === templateSubTypes.CONTRACT
        ? state.loading.effects.employees.fetchEmployees
        : false);

    const isBuildMode = useSelector((state) => state.hasOwnProperty('contractBuilder'));

    const isProposalReportingForm = useSelector(select.reportingForms.isProposalReportingForm);

    const employees = useSelector((state) => {
        if (isBuildMode) return [];

        if ([templateSubTypes.FAMILY_CARER, templateSubTypes.EMPLOYEE, templateSubTypes.EMPLOYER].includes(templateSubType)) {
            return [state.user?.toJS()];
        }
        return select.contract.getEmployees(state);
    });
    console.log(employees);

    const { label, ...restFieldProps } = getFieldProps(props);
    const resolveLabel = (index) => isFamilyCarer
        ? _trans('Hoitaja %signatureIndex%/%signatureCount%', {
            signatureIndex: index + 1,
            signatureCount: employees.length
        }, 'jobContract')
        : _trans('Työntekijä', {}, 'jobContract');

    if (isBuildMode) {
        return (
            <FormField label={label} isContentFormField={false}>
                <b>Ei näkyvissä editorissa</b>
            </FormField>
        );
    }

    return employees
        .map((employee, index) => {
            const hasEmployeeEmail = employee?.email ?? false;
            const defaultChecked = Array.isArray(employeeAccessField.value) && employeeAccessField.value.includes(employee?.userId?.toString());

            if (! hasEmployeeEmail) {
                return (
                    <FormField
                        {...restFieldProps}
                        label={label ?? resolveLabel(index)}
                        name={`employeeSignature.${index}`}
                        isContentFormField={false}
                        key={index}
                    >
                        <div className="o-form__text">
                            <strong className="u-display-block">{employee?.fullName ?? '-'}</strong>
                            <p>
                                {_trans('Sähköpostiosoite puuttuu. Lomake allekirjoitetaan paperiseen versioon.', {}, 'jobContract')}
                            </p>
                        </div>
                    </FormField>
                );
            }

            return (
                <FormField
                    {...restFieldProps}
                    label={label ?? resolveLabel(index)}
                    name={`employeeSignature.${index}`}
                    isContentFormField={false}
                    key={index}
                >
                    <div aria-describedby="employeeSignature" className="o-form__text" key={index}>
                        <UserBlock user={employee} size="small" isLoading={isLoading} secondaryInfo="email" />
                    </div>
                    {isOperator && (
                        <Fragment>
                            {(employeeAccessField.value === false && isProposalReportingForm) && (
                                <div className="u-muted">
                                    {_trans(waitingForSignature, {
                                        signatureIndex: index + 2,
                                        signatureCount: employees.length + 1
                                    })}
                                </div>
                            )}
                            <label className="o-form__text">
                                <input
                                    type="checkbox"
                                    defaultChecked={defaultChecked}
                                    onChange={(event) => {
                                        const currentValue = employeeAccessField.value ?? [];
                                        const employeeUserId = employee.userId?.toString();

                                        employeeAccessHelpers.setValue((event.target.checked)
                                            ? currentValue.concat(employeeUserId)
                                            : currentValue.filter((userId) => userId !== employeeUserId)
                                        );
                                    }}
                                />
                                {`${translations.isNotUsingOima} ${_trans('Allekirjoitus tehdään sopimuksen paperiseen versioon.', {}, 'jobContract')}`}
                            </label>
                        </Fragment>
                    )}
                </FormField>
            );
        });
};

ReportingFormsSignaturesEmployee.propTypes = {
    name: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
    templateSubType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ReportingFormsSignaturesEmployee.defaultProps = {
    templateSubType: null,
};

export default ReportingFormsSignaturesEmployee;
