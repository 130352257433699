/* eslint-disable react/boolean-prop-naming */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _map from 'lodash/map';
import MDIcon from './MDIcon';

import { sizes } from 'shared/constants.js';

export const badgeTypes = {
    PRIMARY: 'primary',
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
    YELLOW: 'yellow',
    NEUTRAL: 'neutral',
    NOTICE: 'notice',
    HOLLOW: 'hollow',
};

function Badge({
    value,
    type,
    animate,
    overlap,
    stroke,
    isBall,
    size,
    modifierClass,
    mdIcon,
    ...rest
}) {
    const isEmptyBadge = value === '';

    const badgeClass = classNames('c-badge u-text-truncate', {
        'c-badge--text': value.length > 3 || !isBall,
        'c-badge--empty': isEmptyBadge,

        'c-badge--overlap': overlap === true,
        'c-badge--animate': animate === true,

        'c-badge--primary': type === badgeTypes.PRIMARY,
        'c-badge--positive': type === badgeTypes.POSITIVE,
        'c-badge--negative': type === badgeTypes.NEGATIVE,
        'c-badge--yellow': type === badgeTypes.YELLOW,
        'c-badge--neutral': type === badgeTypes.NEUTRAL,
        'c-badge--hollow': type === badgeTypes.HOLLOW,
        'c-badge--notice': type === badgeTypes.NOTICE,

        'c-badge--small': size === sizes.SMALL && !isEmptyBadge,
        'c-badge--large': size === sizes.LARGE && !isEmptyBadge,
        'c-badge--huge': size === sizes.HUGE && !isEmptyBadge,

        'c-badge--stroke': stroke === true,

        [modifierClass]: modifierClass !== '',
    });
    if (type === null) {
        return <span />;
    }

    return (
        <span className={badgeClass} {...rest}>
            <div className="u-text-truncate">
                { mdIcon && <MDIcon size="tiny" icon={mdIcon} modifierClass="u-margin-right-tiny u-align-middle" /> }
                <span className="u-align-middle">{value}</span>
            </div>
        </span>
    );
}

Badge.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    type: PropTypes.oneOf(_map(badgeTypes)),

    // Onko badge animoitu?
    animate: PropTypes.bool,

    // Meneekö badge tekstin päälle?
    overlap: PropTypes.bool,

    // Reunat
    stroke: PropTypes.bool,

    // Badgen koko
    size: PropTypes.oneOf(_map(sizes)),

    // Onko badge pallon muotoinen
    isBall: PropTypes.bool,

    // CSS-muokkausluokka
    modifierClass: PropTypes.string,

    // Ikoni
    mdIcon: PropTypes.string,
};

Badge.defaultProps = {
    value: '',
    type: badgeTypes.POSITIVE,
    animate: false,
    overlap: false,
    stroke: false,
    isBall: true,
    size: sizes.SMALL,
    modifierClass: '',
    mdIcon: null,
};

export default Badge;
