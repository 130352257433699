import React from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import { noTaxCardTypes, taxCardTypes, translateTaxCardType } from 'shared/constants/taxCardTypes';
import { types } from 'shared/constants/contract';
import { Card, CardBody, Layout } from 'shared/components';

// Funkkarit, jotta käännös domaini ehtii vaihtua OPH:ssa.
const jobContractOptions = () => [
    {
        id: taxCardTypes.TYPE_B,
        description: _trans('tax_card.types.type_b.description'),
    },
    {
        id: taxCardTypes.TAX_AT_SOURCE,
        description: _trans('tax_card.types.tax_at_source.description'),
    },
    {
        id: taxCardTypes.NO_TAX_CARD,
        description: _trans('tax_card.types.no_tax_card.description'),
    },
];

const familyCareAssignmentContractOptions = () => [
    {
        id: taxCardTypes.COMPENSATION_TAX_CARD_FAMILY_CARE,
        description: _trans('Perhehoidon toimeksiantosuhteissa käytetty verokortti', {}, 'extract'),
    },
    {
        id: taxCardTypes.TAX_AT_SOURCE,
        description: _trans('tax_card.types.tax_at_source.description'),
    },
    {
        id: taxCardTypes.COMPENSATION_NO_TAX_CARD_FAMILY_CARE,
        description: _trans('tax_card.types.no_tax_card.description'),
    },
];

const relativeCareAssignmentContractOptions = () => [
    {
        id: taxCardTypes.COMPENSATION_TAX_CARD_RELATIVE_CARE,
        description: _trans('Omaishoidon toimeksiantosuhteissa käytetty verokortti', {}, 'extract'),
    },
    {
        id: taxCardTypes.TAX_AT_SOURCE,
        description: _trans('tax_card.types.tax_at_source.description'),
    },
    {
        id: taxCardTypes.COMPENSATION_NO_TAX_CARD_RELATIVE_CARE,
        description: _trans('tax_card.types.no_tax_card.description'),
    },
];

const compensationEarnerContractOptions = () => [
    {
        id: taxCardTypes.COMPENSATION_TAX_CARD_PERSONAL,
        description: _trans('Palkkiosopimuksen verokortti', {}, 'extract'),
    },
    {
        id: taxCardTypes.TAX_AT_SOURCE,
        description: _trans('tax_card.types.tax_at_source.description'),
    },
    {
        id: taxCardTypes.COMPENSATION_NO_TAX_CARD_PERSONAL,
        description: _trans('tax_card.types.no_tax_card.description'),
    },
];

const resolveOptions = (contractType, taxCardTypeOverride) => {
    if (taxCardTypeOverride) {
        switch (taxCardTypeOverride) {
            case taxCardTypes.COMPENSATION_TAX_CARD_RELATIVE_CARE:
                contractType = types.ASSIGNMENT_CONTRACTS_RELATIVE_CARE;
                break;
            case taxCardTypes.COMPENSATION_TAX_CARD_FAMILY_CARE:
                contractType = types.ASSIGNMENT_CONTRACTS_FAMILY_CARE;
                break;
            case taxCardTypes.COMPENSATION_TAX_CARD_PERSONAL:
                contractType = types.ASSIGNMENT_CONTRACTS_COMPENSATION_EARNER;
                break;
        }
    }

    switch (contractType) {
        case types.JOB_CONTRACT:
            return jobContractOptions();
        case types.ASSIGNMENT_CONTRACTS_COMPENSATION_EARNER:
            return compensationEarnerContractOptions();
        case types.ASSIGNMENT_CONTRACTS_FAMILY_CARE:
            return familyCareAssignmentContractOptions();
        case types.ASSIGNMENT_CONTRACTS_RELATIVE_CARE:
            return relativeCareAssignmentContractOptions();
        default:
            return [];
    }
};

export const TaxCardTypeSelector = () => {
    const { values, setFieldValue } = useFormikContext();
    const taxCardTypeOverride = useSelector(select.taxCards.getTaxCardTypeOverride);
    const contractType = useSelector(select.taxCards.getContractType);
    const selectedTaxCardId = useSelector(select.taxCards.getSelectedTaxCardId);
    const isVeroFetched = useSelector((state) => select.taxCards.isVeroFetchedTaxCard(state, selectedTaxCardId));

    return (
        resolveOptions(contractType, taxCardTypeOverride).map((type) => (
            <Layout.Item key={type.id} medium="1/3">
                <Card
                    onClick={() => {
                        // Jos on verosta haettu kortti, niin vain prosentin muuttaminen on mahdollista
                        if (! isVeroFetched) {
                            setFieldValue('type', type.id);
                            // Asetetaan perusarvot veroprosentiin tyypin mukaan
                            // Pitää asettaa lisäprosentti nollaan (lähde/ei verokorttia),
                            // koska yup validointi ei tue nested when validointia
                            if (type.id === taxCardTypes.TAX_AT_SOURCE) {
                                setFieldValue('taxDeduction1', 35);
                                setFieldValue('taxDeduction2', '');
                            } else if (noTaxCardTypes.includes(type.id)) {
                                setFieldValue('taxDeduction1', 60);
                                setFieldValue('taxDeduction2', '');
                            } else {
                                setFieldValue('taxDeduction1', '');
                            }
                        }
                    }}
                    isHoverable={isVeroFetched ? type.id === taxCardTypes.TYPE_B : true}
                    modifierClass={classNames('u-padding-small c-card--tax-card-form u-display-block u-1/1', {
                        'c-card--is-selected': type.id === values.type,
                        'u-muted': isVeroFetched ? type.id !== taxCardTypes.TYPE_B : false,
                    })}
                >
                    <CardBody>
                        <div className="u-text-center">
                            <strong>
                                {translateTaxCardType(type.id)}
                            </strong>
                            <div>
                                {type.description}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Layout.Item>
        ))

    );
};
