import React, { useEffect } from 'react';
import { useBooleanToggle } from '@mantine/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { ActionBar, Button, Page } from 'shared/components';
import { DocumentsAttachmentForm } from 'shared/Attachment/containers/DocumentsAttachmentForm';
import { userSelectors } from 'shared/UserDetails/stores/user';
import { documentsCategoryTypes } from 'shared/DocumentsCategories/constants/documentsCategoryTypes';
import { DocumentsAttachmentList } from 'shared/Attachment/containers/DocumentsAttachmentList';

export const UserDocuments = () => {
    const [showForm, toggleForm] = useBooleanToggle(false);
    const userId = useSelector(userSelectors.getUserId);
    const categories = useSelector(select.documents.getCategories);
    const dispatch = useDispatch();

    useEffect(() => {
        if (categories.length === 0) {
            dispatch.documents.fetchCategories([documentsCategoryTypes.USER]);
        }
    }, [categories.length]);


    const documentProps = {
        relatedEntityId: userId,
        categoryType: documentsCategoryTypes.USER,
    };

    if (showForm) {
        return (
            <Page
                heading={_trans('Tallenna dokumentti', {}, 'documents')}
            >
                <div className="c-header__back-button u-margin-bottom@medium">
                    <Button onClick={() => toggleForm()} flat mdIcon="arrow_back" modifierClass="c-button--go-back">
                        {_trans('link.back')}
                    </Button>
                </div>
                <DocumentsAttachmentForm
                    onSuccess={() => toggleForm()}
                    {...documentProps}
                />
            </Page>
        );
    }

    return (
        <Page
            maxWidth="huge"
            heading={_trans('Dokumentit', {}, 'documents')}
            actionItems={(
                <ActionBar alignItems="right">
                    {categories.length > 0 && (
                        <Button onClick={() => toggleForm()} mdIcon="cloud_upload" primary>
                            {_trans('Tallenna uusi dokumentti', {}, 'documents')}
                        </Button>)
                    }

                </ActionBar>
            )}
        >
            <DocumentsAttachmentList {...documentProps} />
        </Page>
    );
};
