/**
 * Palauttaa suoraan FormFieldille menevät propit.
 * @param name
 * @param label
 * @param inputLabel
 * @param placeholder
 * @param tooltip
 * @param helpText
 * @param isRequired
 * @returns {{isRequired, helpText, name, tooltip, label}}
 */
export const getFieldProps = ({ name, label, inputLabel, placeholder, tooltip, helpText, isRequired, style }) => ({
    name,
    label,
    inputLabel,
    placeholder,
    tooltip,
    helpText,
    isRequired,
    style,
});
