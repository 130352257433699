import PropTypes from 'prop-types';
import IconText from 'shared/components/IconText';
import { statusTypes } from 'shared/constants/statusTypes';

const iconByStatus = {
    [statusTypes.ERROR]: 'clear',
    [statusTypes.SUCCESS]: 'check_circle',
};

const modifierClassByStatus = {
    [statusTypes.ERROR]: 'u-color-negative',
    [statusTypes.SUCCESS]: 'u-color-positive',
};

export const StatusIndicator = ({ status, message }) => (
    <IconText
        mdIcon={iconByStatus[status]}
        text={message}
        isIconFilled
        iconSize="small"
        iconModifierClass={modifierClassByStatus[status]}
    />
);

StatusIndicator.propTypes = {
    status: PropTypes.oneOf([statusTypes.SUCCESS, statusTypes.ERROR]).isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
