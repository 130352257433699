import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SignatureOnPaper } from 'shared/ReForm/components/SignatureOnPaper';

const ReportingFormsSignaturesOnPaperEmployer = ({ value }) => (
    <Fragment>
        <strong className="u-visibility-screen">{value}</strong>
        <SignatureOnPaper fullName={value} />
    </Fragment>
);

ReportingFormsSignaturesOnPaperEmployer.propTypes = {
    value: PropTypes.string.isRequired,
};

export default ReportingFormsSignaturesOnPaperEmployer;
