import { documentsCategoryTypes } from 'shared/DocumentsCategories/constants/documentsCategoryTypes';
import { isCommune } from 'shared/utils/commonUtils';

export const resolveDocumentsUrl = (relatedEntityId, category) => {
    switch (category.categoryType) {
        case documentsCategoryTypes.USER:
            return [
                isCommune() ? `/commune/users/show/${relatedEntityId}` : `/company/users/show/${relatedEntityId}`,
                _trans('Henkilötietoihin', {}, 'common')
            ];
        case documentsCategoryTypes.BENEFIT_DECISION:
            return [
                `/commune/benefits/${relatedEntityId}`,
                _trans('Asiakkuudelle', {}, 'common')
            ];
        case documentsCategoryTypes.JOB_CONTRACT:
            return [
                isCommune() ? `/commune/contract/open/${relatedEntityId}` : `/home/contract/${relatedEntityId}/summary`,
                _trans('Sopimukselle', {}, 'common')
            ];
        case documentsCategoryTypes.PAY_PERIOD:
            return [
                isCommune() ? `/commune/timeregistration-by-pay-period/${relatedEntityId}`: `/home/timeregistration-by-pay-period/${relatedEntityId}`,
                isCommune() ? _trans('Avaa palkkajakso', {}, 'common') : _trans('Avaa hoitojakso', {}, 'common')
            ];
    }
};
