import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import { DataTable } from 'shared/containers/DataTable7';
import { Button, ConfirmButton } from 'shared/components';

// AccesstoDeletion nyt implementoitu vain TimeRegistrationiin niin muut toimii vielä kuten aiemminkin
// eslint-disable-next-line react/prop-types
export const DocumentsAttachmentList = ({ categoryType, relatedEntityId, isEditable, deleteButtonTitle, hasAccessToDeletion = true }) => {
    const dispatch = useDispatch();
    const documents = useSelector(select.documents.getDocuments);
    const isLoading = useSelector((state) => state.loading.effects.documents.fetchDocuments);
    const userId = useSelector(select.userMetadata.getUserId);
    const isOperator = useSelector(select.userMetadata.isOperator);

    useEffect(() => {
        dispatch.documents.fetchDocuments({ categoryType, relatedId: relatedEntityId });
    }, [categoryType, relatedEntityId]);

    const uploaderOptions = useMemo(() => [...new Set(
        documents
            .map(({ uploaderFullName = null }) => uploaderFullName))]
        .filter(Boolean)
        .map((fullName) => ({ value: fullName, label: fullName })),
    [documents]);

    const categoryOptions = useMemo(() => [...new Set(documents
        .map(({ category }) => category?.name))]
        .map((categoryName) => ({ value: categoryName, label: categoryName })),
    [documents]);

    const updatedByOptions = useMemo(() => [...new Set(
        documents
            .map(({ updatedByFullName = null }) => updatedByFullName))]
        .filter(Boolean)
        .map((fullName) => ({ value: fullName, label: fullName })),
    [documents]);

    return (
        <DataTable
            isLoading={isLoading}
            emptyDataMessage={_trans('Ei dokumentteja', {}, 'documents')}
            data={documents}
            actionsColumn={(
                <ActionColumn
                    isEditable={isEditable}
                    onFetch={() => dispatch.documents.fetchDocuments({ categoryType, relatedId: relatedEntityId })}
                    onDelete={(uuid) => dispatch.documents.deleteDocument(uuid)}
                    deleteButtonTitle={deleteButtonTitle}
                    hasAccessToDeletion={hasAccessToDeletion}
                    userId={userId}
                />
            )}
            actionsColumnWidth={350}
            columns={[
                {
                    Header: _trans('Nimi', {}, 'common'),
                    accessor: 'name',
                    width: 240,
                },
                {
                    Header: _trans('Kuvaus', {}, 'common'),
                    accessor: 'description',
                    hasWordWrap: true,
                    isTextTruncated: false,
                    width: 240,
                },
                {
                    Header: _trans('Lisännyt (pvm)', {}, 'documents'),
                    accessor: 'uploaderFullName',
                    filterType: 'checkbox',
                    filterOptions: uploaderOptions,
                    // eslint-disable-next-line react/prop-types
                    Cell: ({ value, row: { original: { created } } }) => (
                        <div>
                            {value}
                            <div className="o-help">
                                {_toLocaleDate(created)}
                            </div>
                        </div>
                    ),
                    show: isOperator,
                },
                {
                    Header: _trans('Muokannut (pvm)', {}, 'documents'),
                    accessor: 'updatedByFullName',
                    filterType: 'checkbox',
                    filterOptions: updatedByOptions,
                    // eslint-disable-next-line react/prop-types
                    Cell: ({ value, row: { original: { updated } } }) => (
                        <div>
                            {value}
                            <div className="o-help">
                                {_toLocaleDate(updated)}
                            </div>
                        </div>
                    ),
                    show: isOperator,
                },
                {
                    Header: _trans('Vanhenee', {}, 'common'),
                    accessor: (r) => r.expireDate === undefined && r.category.isExpiryAfterContractTerminating
                        ? _trans('Sopimuksen päättymisen jälkeen %year% vuotta ja %month% kuukautta', {
                            year: r.category.defaultExpiryYear,
                            month: r.category.defaultExpiryMonth,
                        }, 'documents')
                        : _toLocaleDate(r.expireDate),
                    // type: 'date',
                    // defaultValue: '-',
                },
                {
                    Header: _trans('Kategoria', {}, 'documents'),
                    accessor: 'category.name',
                    filterType: 'checkbox',
                    filterOptions: categoryOptions,
                    hasWordWrap: true,
                    isTextTruncated: false,
                },
            ]}
        />
    );
};

// eslint-disable-next-line react/prop-types
const ActionColumn = ({ original, onDelete, onFetch, isEditable, deleteButtonTitle, hasAccessToDeletion, userId }) => (
    <div>
        <div className="o-stack o-stack--justify">
            <div className="u-margin-right-small">
                <Button
                    href={`/api/v3/documents/${original.uuid}/download`}
                    target="_blank"
                    mdIcon="cloud_download"
                    title={_trans('Avaa dokumentti', {}, 'documents')}
                >
                    {_trans('Avaa', {}, 'common')}
                </Button>
            </div>
            <div>
                <ConfirmButton
                    disabled={!isEditable || !(hasAccessToDeletion || original.uploaderId === userId)}
                    title={!isEditable && deleteButtonTitle ? deleteButtonTitle : ''}
                    mdIcon="delete_forever"
                    confirmText={_trans('Haluatko varmasti poistaa %documentName% dokumentin?', { documentName: original.name }, 'documents')}
                    negative
                    text={_trans('Poista', {}, 'common')}
                    onClick={() => onDelete(original.uuid).then(() => onFetch())}
                />
            </div>
        </div>
        {original.isPayPeriodDataGenerated && isEditable && (
            <div className="u-margin-top-tiny u-muted">
                {_trans('Voit poistaa dokumentin myöskin poistamalla lisäkorvauksen.', {}, 'documents')}
            </div>
        )}
    </div>
);


ActionColumn.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onFetch: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    original: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        uploaderId: PropTypes.number.isRequired,
        isPayPeriodDataGenerated: PropTypes.bool.isRequired,
    }),
};

ActionColumn.defaultProps = {
    original: {},
};

DocumentsAttachmentList.propTypes = {
    categoryType: PropTypes.string.isRequired,
    relatedEntityId: PropTypes.number.isRequired,
    isEditable: PropTypes.bool,
};
DocumentsAttachmentList.defaultProps = {
    isEditable: true
};

