import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import { PreviousEmployerSignature } from 'shared/ReForm/containers/CustomBlocks/ContractV3/components/Signatures/PreviousEmployerSignature';
import { AutoCompleteField, FormField, HiddenField } from 'shared/components/Formik';
import { useGet } from 'shared/hooks/useApi';
import UserBlock from 'shared/components/UserBlock';
import Button from 'shared/components/Button';
import { metadataNames } from 'ContractV3/constants/metadataNames';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { FormikErrors } from 'shared/components/Formik';
import { scrollToError } from 'shared/utils/formUtils';
import { signatureTypes } from 'shared/constants/signatureTypes';
import { Placeholder } from 'shared/components';

const resolveNoSignatoriesText = (isCommune) => isCommune
    ? _trans('Allekirjoituksen vahvistajia ei löytynyt.', {}, 'jobContract')
    : _trans('Allekirjoitusoikeudellisia edustajia ei löytynyt.', {}, 'jobContract');

/**
 * Työnantajan allekirjoitus.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const SignaturesEmployer = (props) => {
    const dispatch = useDispatch();
    const { name, placeholder, apiEndPoint, employerSignatureType, isEmployer } = props;
    const [field,,helpers] = useField(name);
    const [hasFetched, setHasFetched] = useState(false);
    const { label, ...restProps } = getFieldProps(props);
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    const [isReSignatureNeededField] = useField(metadataNames.IS_RE_SIGNATURE_NEEDED);
    const isReSignatureNeeded = isReSignatureNeededField.value;
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isOperator = isCompany || isCommune;

    const employerDataKey = employerSignatureType === signatureTypes.TYPE_COMMUNE_USER_SPECIFIC
        ? 'customer'
        : 'userData';

    // HA-puolella haetaan HA-operaattori
    const previousSignature = useSelector(employerDataKey === 'customer'
        ? select.contract.getPreviousCommuneOperatorSignature
        : select.contract.getPreviousEmployerSignature);
    const hasPreviousSignatures = useSelector(select.contract.hasPreviousSignatures);

    // Pääkäyttäjä tahi prokura
    const canAuthorizeUsers = useSelector(select.userMetadata.canAuthorizeUsers);
    const [{ isLoading, data }, makeRequest] = useGet(apiEndPoint, { lazy: true });

    useEffect(() => {
        if (isOperator && ! hasFetched) {
            makeRequest().then(() => {
                setHasFetched(true);
            });
        }
    }, [hasFetched, isOperator]);

    const employers = (data?.data ?? []).map((employer) => employer[employerDataKey] ?? {});
    const activeEmployer = employers.find((employer) => employer?.userId === parseInt(previousSignature?.signerId, 10));

    // Jos TA-allekirjoittajaa ei valittuna vedetään kirjautuneesta käyttäjästä.
    const employerId = parseInt(field.value, 10);
    const employer = employers.find((employer) => employer.userId === employerId);

    useEffect(() => {
        // Hyvinvointialueen allekirjoittaja poistettu.
        // Valittava uusi mikäli halutaan allekirjoitukset tai asetetaan aiempi allekirjoittaja.
        if (hasFetched && ! activeEmployer && isAcceptedContract && hasPreviousSignatures && ! field.value) {
            if (isReSignatureNeeded) {
                setTimeout(() => {
                    helpers.setValue('');
                    helpers.setTouched(true);
                    dispatch.notifications.addWarning(_trans('Hyvinvointialueen allekirjoittavaa edustajaa ei löydy. Valitse uusi allekirjoittaja.', {}, 'jobContract'));
                    // Scrollataan virhekohtaan
                    scrollToError({ [name]: '' });
                }, 150);

            } else if (field.value !== previousSignature?.signerId) {
                // Aiempi allekirjoittaja takas
                helpers.setValue(previousSignature?.signerId ?? '');
            }

        }
    }, [isAcceptedContract, activeEmployer, hasFetched, hasPreviousSignatures, isReSignatureNeeded, name, field.value, previousSignature?.signerId]);

    // Mennään tähän vain ekalla kerralla jos myöhemmin
    // joudutaan päivittämään allekirjoitusoikeudellinen lista => UI ei pompi
    if (isLoading && hasFetched === false) {
        return (
            <FormField
                label={label ?? _trans('Hyvinvointialueen edustaja', {}, 'jobContract')}
                {...restProps}
                isContentFormField={false}
            >
                <Placeholder isPending>
                    <div>Lorem ipsum dolor sit amet</div>
                </Placeholder>
            </FormField>
        );
    }

    // Ei vaadita uusia allekirjoituksia. Kerrotaan milloin viimeksi allekirjoitettu ja kenen toimesta.
    if (hasPreviousSignatures && isReSignatureNeeded === false) {
        return (
            <FormField label={_trans('Hyvinvointialueen edustaja', {}, 'jobContract')} {...restProps}>
                <PreviousEmployerSignature employerSignatureType={employerSignatureType} />
                <HiddenField name={name} value={previousSignature?.signerId}/>
            </FormField>
        );
    }

    // Valittu allekirjoittaja. Näytä tiedot ja napit vaihtoon ja allekirjoitukseen.
    if (employer) {
        return (
            <FormField label={label ?? _trans('Hyvinvointialueen edustaja', {}, 'jobContract')} {...restProps}>
                <div aria-labelledby={name}>
                    <div className="u-margin-bottom-small">
                        <UserBlock
                            user={employer}
                            size="small"
                            secondaryInfo="email"
                        />
                        <HiddenField name={name} value={employerId} />
                    </div>
                    <Button
                        outline
                        mdIcon="swap_horiz"
                        onClick={() => helpers.setValue('')}
                        modifierClass="u-1/1"
                    >
                        {_trans('Vaihda', {}, 'common')}
                    </Button>
                </div>
            </FormField>
        );
    }

    return (
        <FormField
            label={label ?? _trans('Hyvinvointialueen edustaja', {}, 'jobContract')}
            {...restProps}
            isContentFormField={employers.length > 0}
        >
            {employers.length === 0
                ? (
                    <div aria-labelledby={name} className="o-form__text">
                        {isEmployer && (
                            <strong>
                                {_trans('Hyvinvointialue valitsee.', {}, 'jobContract')}
                            </strong>
                        )}
                        {! isEmployer && resolveNoSignatoriesText(isCommune)}{' '}

                        {/*Ohjataan yrityksen asetuksiin vain jos sinne on oikeudet*/}
                        {(canAuthorizeUsers || isCommune) && (
                            <Fragment>
                                {isCommune
                                    ? _transMd('Voit lisätä allekirjoituksen vahvistajia [käyttäjätilien hallinnasta](%url%).', { url: '/commune/user-accounts-manager' }, {
                                        targetBlank: true,
                                        useParagraphs: true,
                                    })
                                    : _transMd('Voit lisätä allekirjoitusoikeuksia [yrityksen asetuksista](%url%).', { url: '/company/settings/users' }, {
                                        targetBlank: true,
                                        useParagraphs: true,
                                    })}
                                {_trans('Lisättyäsi oikeudet ', {}, 'jobContract')}<Button mdIcon="refresh" size="small" iconSize="small" inProgress={isLoading} onClick={() => makeRequest()}>{_trans('päivitä lista', {}, 'common')}</Button>
                            </Fragment>
                        )}
                        <FormikErrors name={name} />
                    </div>
                )
                : (
                    <AutoCompleteField
                        name={name}
                        items={employers}
                        labelKey="fullName"
                        valueKey="userId"
                        placeholder={placeholder}
                    />
                )}
        </FormField>
    );
};

SignaturesEmployer.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    attributes: PropTypes.object,
    apiEndPoint: PropTypes.string,
    employerSignatureType: PropTypes.string,
    isEmployer: PropTypes.bool,
};

SignaturesEmployer.defaultProps = {
    placeholder: _trans('Valitse hyvinvointialueen allekirjoittaja', {}, 'jobContract'),
    attributes: {},
    apiEndPoint: '/company/api/v1/operators/contract-signing-access',
    employerSignatureType: signatureTypes.TYPE_EMPLOYER_SPECIFIC,
    isEmployer: false
};

export default SignaturesEmployer;
