import React, { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import Button from 'shared/components/Button';
import MDIcon from 'shared/components/MDIcon';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import Switch from 'shared/components/Switch';
import { SignatureOnPaper } from 'shared/ReForm/components/SignatureOnPaper';

/**
 * Työntekijän allekirjoitus.
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const ReportingFormsSignaturesEmployee = () => {
    const dispatch = useDispatch();
    const [seenSignatures, setSeenSignatures] = useState([]);

    const isSigning = useSelector((state) => state.loading.effects.reportingForms.postSignature);

    const allowAssignmentContracts = useSelector(select.userMetadata.allowAssignmentContracts);
    const isFamilyCarer = useSelector(select.userMetadata.isFamilyCarer) || allowAssignmentContracts;

    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isOperator = isCommune || isCompany;
    const isFullySigned = false;//useSelector(select.reportingForms.isFullySigned);
    const hasEmployerSigned = useSelector(select.reportingForms.hasEmployerSigned);
    const hasCommuneUserSigned = useSelector(select.reportingForms.hasCommuneUserSigned);

    const signatures = useSelector(select.reportingForms.getEmployeeSignatures);
    const hasCommuneUserSignature = useSelector(select.reportingForms.hasCommuneUserSignature);

    const hasOperatorSigned = isCompany
        ? hasEmployerSigned
        // 1. HA:n operaattori on allekirjoittanut
        // 2. Pohjasta ei löydy operaattorin allekirjoituspalikkaa
        : (hasCommuneUserSigned || ! hasCommuneUserSignature);
    const reportingFormId = useSelector(select.reportingForms.getReportingFormId);

    const userId = useSelector(select.userMetadata.getUserId)?.toString();
    const isEmployee = useSelector(select.userMetadata.isEmployee);
    const isProposalReportingForm = useSelector(select.reportingForms.isProposalReportingForm);
    const isBuildMode = useSelector((state) => state.hasOwnProperty('contractBuilder'));

    const employeesNotUsingOima = useSelector(select.reportingForms.getEmployeesNotUsingOima);

    const signatureCount = (signatures ?? []).length;

    // Allekirjoitusnappi
    const SignButton = useCallback(({
        buttonText = _trans('Allekirjoita', {}, 'jobContract'),
        isSigningOnBehalf = false,
        signerId = null,
    }) => (
        <Button
            inProgress={isSigning}
            positive={!isBuildMode}
            mdIcon="check"
            disabled={isBuildMode}
            modifierClass="u-1/1 u-margin-top-small"
            onClick={() => {
                console.log(signerId);
                dispatch.reportingForms.postSignature({
                    reportingFormId,
                    isSigningOnBehalf,
                    ...((isSigningOnBehalf && signerId) && { signerId }),
                })
                    .then(({ status, message }) => {
                        if (status === 'ok') {
                            if (signatures.every((signature) => signature.isSigned)) {
                                window.scrollTo(0, 0);
                            }
                        } else {
                            // Joko virheviesti jos löytyy tai geneerinen käsienlevittelyviesti
                            dispatch.notifications.addError(message ?? _trans('Sopimusta ei voitu allekirjoittaa.', {}, 'jobContract'));
                        }
                    });
            }}
        >
            {buttonText}
        </Button>
    ), [isBuildMode, isSigning, reportingFormId, signatures]);

    return (
        <Fragment>
            {signatures.map((signature, index) => {
                const familyCarerLabel = signatureCount > 1
                    ? _trans('Hoitaja %signatureIndex%/%signatureCount%', {
                        signatureIndex: index + 1,
                        signatureCount,
                    }, 'common')
                    : _trans('Hoitaja', {}, 'common');

                const label = isFamilyCarer
                    ? familyCarerLabel
                    : _trans('Työntekijä', {}, 'jobContract');

                const isSigned = signature?.isSigned ?? false;
                const signTime = signature?.signTime;
                const signerId = signature?.signerId?.toString();
                const hasSeenSignature = seenSignatures.includes(signerId);
                const hasEmail = signature?.signerDetails?.email.trim() !== '' ?? false;

                const isEmployeeNotUsingOima = (
                    signature?.verifiedByName
                    || employeesNotUsingOima.find((employeeUserId) => employeeUserId?.toString() === signerId)
                    || signature?.signerDetails?.email?.toString().trim() === ''
                );
                const canEmployeeSign = (! employeesNotUsingOima.includes(userId)) && userId === signerId;

                const isNotUsingOimaText = isFamilyCarer
                    ? _trans(`Hoitaja ei käytä Oimaa. Odottaa hyvinvointialueen edustajan allekirjoitusta.`, {
                        signatureCount,
                    }, 'jobContract')
                    : _trans(`Työntekijä ei käytä Oimaa. Odottaa hyvinvointialueen edustajan allekirjoitusta.`, {}, 'jobContract');

                if (isEmployee && ! canEmployeeSign && ! isSigned && isEmployeeNotUsingOima) {
                    return (
                        <SummaryListItem term={label} key={index}>
                            <strong className="u-display-block">
                                {signature?.signerDetails.fullName}
                            </strong>
                            <span className="u-muted">
                                {_trans('Odottaa allekirjoitusta paperiseen versioon.', {}, 'jobContract')}
                            </span>
                        </SummaryListItem>
                    );
                }

                return (
                    <SummaryListItem term={label} key={index}>
                        <Fragment>
                            {(isProposalReportingForm && canEmployeeSign && ! isSigned) && (
                                <SignButton />
                            )}
                            {/* Allekirjoitus OK. */}

                            {isSigned && (
                                <div>
                                    <MDIcon icon="check_circle" size="small" modifierClass="u-color-positive u-align-middle u-margin-right-tiny" isFilled />
                                    <span className="u-align-middle">
                                        {isEmployeeNotUsingOima
                                            ? (
                                                <Fragment>
                                                    <strong>
                                                        {signature?.signerDetails.fullName}
                                                    </strong>{'. '}
                                                    {_trans('Allekirjoitus sopimuksen paperisessa versiossa.', {}, 'jobContract')}{' '}
                                                    {_transMd('%signer% vahvisti nähneensä paperisen sopimuksen **%signTime%** ja arkistoineensa sen.', {
                                                        signer: isEmployer
                                                            ? _trans('Hyvinvointialue', {}, 'common')
                                                            : signature?.verifiedByName ?? '-',
                                                        signTime: _toLocaleDate(signTime, '', _dateTimeFormat)
                                                    }, 'jobContract')}
                                                </Fragment>
                                            )
                                            : (
                                                _transMd(_trans('**%signer%** allekirjoitti **%signTime%**', {
                                                    signer: signature?.signerDetails.fullName ?? '-',
                                                    signTime: _toLocaleDate(signTime, '', _dateTimeFormat)
                                                }))
                                            )}
                                    </span>
                                </div>
                            )}

                            {/* Ehdotusta ei ole allekirjoitettu mutta TT pääsee Oimaan */}
                            {(! isSigned && isProposalReportingForm) && (
                                <Fragment>
                                    {/* TT pääsee Oimaan */}
                                    {! isEmployeeNotUsingOima && (
                                        <div className="u-visibility-screen">
                                            {/*Näytetään nimi vain jos ei itse olla allekirjoittamassa*/}
                                            {! canEmployeeSign && (
                                                <Fragment>
                                                    <strong className="u-display-block">
                                                        {signature?.signerDetails.fullName}
                                                    </strong>
                                                    <span>{signature?.signerDetails.email}</span>
                                                </Fragment>
                                            )}
                                            {! canEmployeeSign && (
                                                <div className="u-muted">
                                                    {_trans('Odottaa allekirjoitusta.', {}, 'jobContract')}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {/* TT ei pääse Oimaan. TA ei ole allekirjoittanut. */}
                                    {(isEmployeeNotUsingOima && ! hasOperatorSigned) && (
                                        <Fragment>
                                            <div className="u-visibility-screen">
                                                <strong className="u-display-block">
                                                    {signature?.signerDetails.fullName}
                                                </strong>
                                                <div>
                                                    {hasEmail
                                                        ? isNotUsingOimaText
                                                        : _trans('Sähköpostiosoite puuttuu. Lomake allekirjoitetaan paperiseen versioon. Odottaa hyvinvointialueen edustajan allekirjoitusta.', {}, 'reporting_forms')}
                                                </div>
                                            </div>
                                            {/*  <div className="u-visibility-print">
                                                {_trans('Odottaa sopimuksen osapuolen 1/2 allekirjoitusta.', {}, 'jobContract')}
                                            </div>*/}
                                        </Fragment>
                                    )}

                                    {/* TT ei pääse Oimaan. TA:lla on allekirjoittanut. */}
                                    {(isEmployeeNotUsingOima && hasOperatorSigned && ! isOperator) && (
                                        <div className="u-visibility-screen">
                                            <strong className="u-display-block">
                                                {signature?.signerDetails.fullName}
                                            </strong>
                                            <div className="u-margin-bottom-small">
                                                <p>
                                                    {isFamilyCarer
                                                        ? _trans('Hoitaja ei käytä Oimaa.', {}, 'jobContract')
                                                        : _trans('Työntekijä ei käytä Oimaa.', {}, 'jobContract')}
                                                    {' '}{_trans('Odottaa allekirjoitusta paperiseen versioon.', {}, 'jobContract')}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {(isEmployeeNotUsingOima && hasOperatorSigned && isOperator) && (
                                        <div className="u-visibility-screen">
                                            <strong className="u-display-block">
                                                {signature?.signerDetails.fullName}
                                            </strong>
                                            <div className="u-margin-bottom-small">
                                                <p>
                                                    {isFamilyCarer
                                                        ? _trans('Hoitaja ei käytä Oimaa.', {}, 'jobContract')
                                                        : _trans('Työntekijä ei käytä Oimaa.', {}, 'jobContract')}
                                                </p>
                                                {` ${_trans('Tulosta sopimus ja pyydä siihen allekirjoitus. Nähtyäsi allekirjoituksen voit vahvistaa sopimuksen Oimassa.', {}, 'jobContract')}`}
                                            </div>
                                            <Switch
                                                id="hasSeenSignature"
                                                onChange={(isOn) => {
                                                    setSeenSignatures(isOn
                                                        ? seenSignatures.concat(signerId)
                                                        : seenSignatures.filter((userId) => userId !== signerId)
                                                    );
                                                }}
                                                modifierClass="u-margin-bottom-small"
                                            >
                                                {_trans('Olen nähnyt paperisen sopimuksen allekirjoituksen ja arkistoinut sen.', {}, 'jobContract')}
                                            </Switch>

                                            {hasSeenSignature && (
                                                <SignButton
                                                    signerId={signerId}
                                                    signatureReportingFormId={signature?.signerDetails?.reportingFormId}
                                                    isSigningOnBehalf
                                                    buttonText={_trans('Vahvista', {}, 'common')}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Fragment>
                            )}
                            {/* Paikka, aika ja allekirjoitus. Vain tulosteissa / PDF. */}
                            {(isProposalReportingForm && hasOperatorSigned && isEmployeeNotUsingOima && ! isFullySigned && isOperator) && (
                                <SignatureOnPaper fullName={signature?.signerDetails?.fullName} />
                            )}
                            {/* Tieto sopimuksen vahvistuksesta. */}
                            {(! isSigned && ! isProposalReportingForm) && (
                                <Fragment>
                                    {isEmployeeNotUsingOima && (
                                        <div>
                                            <strong>{signature?.signerDetails.fullName}</strong><br/>
                                            <span>{signature?.signerDetails.email}</span>
                                            <div>
                                                {isFamilyCarer
                                                    ? _trans('Hoitaja ei käytä Oimaa.', {}, 'jobContract')
                                                    : _trans('Työntekijä ei käytä Oimaa.', {}, 'jobContract')}
                                                {' '}{_trans('Allekirjoitus tehdään sopimuksen paperiseen versioon.', {}, 'jobContract')}
                                            </div>
                                        </div>
                                    )}
                                    {! isEmployeeNotUsingOima && (
                                        <div>
                                            <strong>{signature?.signerDetails.fullName}</strong><br/>
                                            <span>{signature?.signerDetails.email}</span>
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    </SummaryListItem>
                );
            })}
        </Fragment>
    );
};

export default ReportingFormsSignaturesEmployee;
