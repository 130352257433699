import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { userSelectors } from 'shared/UserDetails/stores/user';
import { Button, ConfirmButton, MDIcon, Page, Panel } from 'shared/components';
import { DataTable } from 'shared/containers/DataTable7';
import { UserAddress } from 'shared/UserDetails/containers/shared/AdditionalAddress/UserAddress';

export const AdditionalAddressList = ({ toggleForm, setAddress }) => {
    const user = useSelector(userSelectors.getUser);
    const additionalAddresses = useSelector(select.additionalAddresses.getAdditionalAddresses);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch.additionalAddresses.fetchAdditionalAddresses(user.toJS().userId);
    }, []);

    return (
        <Page heading={_trans('Lisäosoitteet', {}, 'additional_address')} actionItems={<Button onClick={() => toggleForm()} primary>{_trans('Lisää osoite', {}, 'additional_address')}</Button>
        }>
            <UserAddress user={user.toJS()} />
            <Panel
                heading={_trans('Lisäosoitteet', {}, 'additional_address')}
            >
                <DataTable
                    actionsColumn={<ActionColumn toggleForm={toggleForm} setAddress={setAddress} />}
                    isFilterable={false}
                    data={additionalAddresses}
                    isTotalVisible={false}
                    actionsColumnWidth={300}
                    emptyDataMessage={_trans('Ei osoitteita', {}, 'additional_address')}
                    columns={[
                        {
                            Header: _trans('Tunniste', {}, 'common'),
                            accessor: 'label',
                        },
                        {
                            Header: _trans('Etunimi', {}, 'common'),
                            accessor: 'firstName'
                        },
                        {
                            Header: _trans('Sukunimi', {}, 'common'),
                            accessor: 'lastName',
                        },
                        {
                            Header: _trans('Osoite', {}, 'common'),
                            accessor: 'streetAddress',
                        },
                        {
                            Header: _trans('Postinumero', {}, 'common'),
                            accessor: 'postCode',
                        },
                        {
                            Header: _trans('Postitoimipaikka', {}, 'common'),
                            accessor: 'town',
                        },
                        {
                            Header: _trans('Laskutusosoite', {}, 'common'),
                            accessor: 'isInvoicingAddress',
                            Cell: (props) => (
                                <MDIcon
                                    icon={props.value ? 'check' : 'cancel'}
                                    modifierClass={props.value ? 'u-color-positive' : 'u-color-negative'}
                                />
                            )
                        },
                    ]}
                />
            </Panel>
        </Page>
    );
};

const ActionColumn = ({ original, setAddress, toggleForm }) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelectors.getUser);

    return (
        <div className="o-stack--justify">
            <Button
                onClick={() => {
                    setAddress(original);
                    toggleForm();
                }}
            >
                {_trans('Muokkaa', {}, 'common')}
            </Button>
            <ConfirmButton
                text={_trans('Poista', {}, 'common')}
                confirmText={_trans('Haluatko varmasti poistaa tämän osoitteen?', {}, 'common')}
                modifierClass={'u-margin-left-small'}
                onClick={() => {
                    dispatch.additionalAddresses.deleteAdditionalAddress(original.id).then((json) => {
                        if (json.status === 'error') {
                            dispatch.notifications.addError(_trans('Poistaminen ei onnistunut.', {}, 'common'));
                        } else {
                            dispatch.additionalAddresses.fetchAdditionalAddresses(user.toJS().userId);
                        }
                    });
                }}
            />
        </div>
    );
};

AdditionalAddressList.propTypes = {
    toggleForm: PropTypes.func.isRequired,
    // eslint-disable-next-line react/boolean-prop-naming
    value: PropTypes.bool,
    setAddress: PropTypes.func.isRequired,
    address: PropTypes.object.isRequired,
};

AdditionalAddressList.defaultProps = {
    value: false,
};

ActionColumn.propTypes = {
    original: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    setAddress: PropTypes.func.isRequired,
    toggleForm: PropTypes.func.isRequired,
};
