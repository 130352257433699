export const bankTransactionStates = {
    /**
     * Raakdata suoraan pankin vastauksesta.
     *
     * Otetana yksinään ensin talteen, niin teoriassa ei pitäisi tippua viestejä
     * parsintaongelmiin. Ja parsintaa voi korjata ja parsia uudestaan kun on
     * raakadata tallessa.
     */
    STATE_RAW: 1,

    /**
     * Uusi kohdistamaton tapahtuma
     */
    STATE_NEW: 2,
    /**
     * Kohdistettu tapahtuma
     */
    STATE_ALLOCATED: 10,

    /**
     * Kohdistusvirhe
     */
    STATE_ALLOCATION_ERROR: 90,
};

export const bankTransactionStateMap = {
    [bankTransactionStates.STATE_RAW]: 'Raaaka',
    [bankTransactionStates.STATE_NEW]: 'Uusi kohdistamaton',
    [bankTransactionStates.STATE_ALLOCATED]: 'Kohdistettu',
    [bankTransactionStates.STATE_ALLOCATION_ERROR]: 'Kohdistusvirhe',
};

export const translateBankTransactionState = (state) =>
    state in bankTransactionStateMap ? bankTransactionStateMap[state] : 'Tuntematon';
