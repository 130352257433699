import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { CollectiveAgreementChoiceField } from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/shared/CollectiveAgreementChoiceField';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { salaryTypes } from 'shared/constants/index';
import { translateSalaryType } from 'shared/constants/salaryTypes';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { FormField } from 'shared/components/Formik';
import { Value } from 'shared/components/Value';
import { HelpText } from 'shared/components';
import { tesTypes } from 'shared/constants/tesTypes';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { isValidDate } from 'shared/utils/dateUtils';
import Feedback from 'shared/components/Feedback';
import { scrollToElementWithOffset } from 'shared/utils/formUtils';
import { getCustomComponentByFieldName } from 'ContractV3/utils/getCustomComponentByFieldName';
import _transObj from 'shared/utils/_transObj';

/**
 * Palkkatyyppi.
 * @param props
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const SalaryType = (props) => {
    const { name, attributes } = props;
    const hiddenSalaryTypes = attributes?.hiddenSalaryTypes ?? [];

    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    const isAssignmentContract = useSelector(select.contract.isAssignmentContract);
    const [field] = useField(name);
    const [contractTypeField] = useField(fieldNames.CONTRACT_TYPE);

    // Heta-spesialiteetti
    //const communeSettings = useSelector(select.communeSettings.getCommuneSettings);
    const [,,weeklyWorkHoursHelpers] = useField(fieldNames.HAS_WEEKLY_WORK_HOURS);
    const [,,partTimePercentageHelpers] = useField(fieldNames.PART_TIME_PERCENTAGE);
    const firstSalaryIndex = `${fieldNames.SALARIES}.0`;
    const [,,isTableSalaryInUseHelpers] = useField(resolveArrayName(firstSalaryIndex, fieldNames.IS_TABLE_SALARY_IN_USE));
    //const [,,initialSalaryHelpers] = useField(resolveArrayName(firstSalaryIndex, fieldNames.INITIAL_SALARY));
    const hasMinimumTableSalary = useSelector(select.tableSalary.isMinimumTableSalaries);
    const collectiveAgreementBaseType = useSelector(select.contract.getCollectiveAgreementBaseType);

    // Kloonatulla sopparilla huudellaan ja hoksautetaan valitsemaan ensin aloituspäivä
    const isCloningContract = useSelector(select.contract.isCloningContract);
    const [startDateField] = useField(fieldNames.START_DATE);
    const startDate = new Date(startDateField.value);
    const startDateBlock = getCustomComponentByFieldName(fieldNames.START_DATE);
    const startDateLabel = startDateBlock?.label
        ? _transObj(startDateBlock.label)
        : _trans('Aloituspäivä', {}, 'jobContract');

    const salaryType = parseInt(field.value, 10);

    useEffect(() => {
        if (salaryType !== salaryTypes.PER_MONTH) {
            // Jos asetetaan muuksi kuin kuukausiliksaksi, asetetaan osa-aika prosentti nollaan,
            // jottei tule palkanlaskentaa mitää sivuoireita
            partTimePercentageHelpers.setValue(0);
        }
        if (salaryType === salaryTypes.PER_MONTH) {
            // Jos asetetaan kuukausipalkkaiseksi, asetetaan
            // "Käytä säännöllistä työaikaa arkipyhäkorvauksien laskennassa" falseksi
            // Ei vaikuta kk- palkan laskentaa vaikka olisi päällä
            weeklyWorkHoursHelpers.setValue(false);

            /*if (salaries.size > 1) {
                // Jos palkkioita on enemmän kuin yksi ja muutetaan kk liksalle, niin
                // raa'asti slicetään vain ensimmäinne
                dispatch(actions.change('contract.salary.salaries', salaries.slice(0, 1)));
            }*/
        }

        if (salaryType=== salaryTypes.PER_HOUR && collectiveAgreementBaseType !== tesTypes.COMMUNE_HETA) {
            /*dispatch(
                actions.change(
                    'contract.salary.salaries.0.initialSalary',
                    hetaHourlyWages.get(communeSettings.COMMUNE_HOURLY_WAGE)
                )
            );*/
        }

        if (hasMinimumTableSalary) {
            if (salaryType === salaryTypes.PER_HOUR) {
                // Miten heetta specialiteetti, ettei kk liksalla näytetä?
                isTableSalaryInUseHelpers.setValue(true);
            }

            // Jos kk liksa ja minimitaulukot, niin astetaan taulukot pois (HETA-specificistä)
            if (salaryType === salaryTypes.PER_MONTH) {
                // Miten heetta specialiteetti, ettei kk liksalla näytetä?
                isTableSalaryInUseHelpers.setValue(false);
            }
        }

        /*setCommuneNonHetaHourlyWage(value);
        handleTableSalaryOnMinimumTableSalaries(tableSalaryData, value);*/
    }, [salaryType, collectiveAgreementBaseType, hasMinimumTableSalary]);

    // Hyväksytyltä sopimukselta ei voi enää vaihtaa palkkatyyppiä.
    if (isAcceptedContract) {
        return (
            <FormField {...getFieldProps(props)} isContentFormField={false}>
                <Value>{translateSalaryType(salaryType)}</Value>
                <HelpText
                    text={isAssignmentContract
                        ? _trans('Palkkiotyyppiä ei voi muuttaa jälkeenpäin. Mikäli palkkiotyyppiä halutaan muuttaa, tulee tehdä uusi sopimus.', {}, 'jobContract')
                        : _trans('Palkkatyyppiä ei voi muuttaa jälkeenpäin. Mikäli palkkatyyppiä halutaan muuttaa, tulee tehdä uusi sopimus.', {}, 'jobContract')}
                    id="fixedStartDate"
                />
            </FormField>
        );
    }

    return (
        <Fragment>
            <CollectiveAgreementChoiceField
                primaryType={contractTypeField.value}
                restrictionKey="allowedContractTypesAndSalaryTypes"
                translator={translateSalaryType}
                fieldProps={props}
                filteredValues={hiddenSalaryTypes}
            />
            {isCloningContract && ! isValidDate(startDate) && (
                <FormField isContentFormField={false}>
                    <Feedback type="info">
                        {_trans('Valitse ensiksi', {}, 'jobContract')}{' '}<button className="u-color-link u-font-weight-bold" onClick={(event) => {
                            event.preventDefault();
                            const startDateElement = document.getElementById(fieldNames.START_DATE);
                            if (startDateElement) {
                                scrollToElementWithOffset(startDateElement);
                            }
                        }}>{startDateLabel}</button>.
                    </Feedback>
                </FormField>
            )}
        </Fragment>
    );
};

SalaryType.propTypes = {
    name: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
        hiddenSalaryTypes: PropTypes.array,
    }).isRequired,
};

export default SalaryType;
