import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import ReportingFormsSignaturesEmployer from './ReportingFormsSignaturesEmployer';
import { signatureTypes } from 'shared/constants/signatureTypes';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import { Value } from 'shared/components/Value';
import { isNumber } from 'shared/utils/commonUtils';

const ReportingFormsSignaturesCommuneOperator = ({ attributes, value }) => {
    const hasCommuneSigner = isNumber(value);
    const isCommune = useSelector(select.userMetadata.isCommune);

    if (! hasCommuneSigner && isCommune) {
        return (
            <SummaryListItem term={_trans('Hyvinvointialueen edustaja', {}, 'jobContract')}>
                <Value>
                    {_transMd('Hyvinvointialueen edustajaa ei vielä valittu.\nMuokkaa sopimusta ja lisää allekirjoittaja.', {}, 'jobContract')}
                </Value>
            </SummaryListItem>
        );
    }

    return (
        <ReportingFormsSignaturesEmployer
            attributes={attributes}
            value={value}
            apiEndPoint="/commune/api/v1/commune/reporting-form-signing-access"
            employerSignatureType={signatureTypes.TYPE_COMMUNE_USER_SPECIFIC}
        />
    );
};

ReportingFormsSignaturesCommuneOperator.propTypes = {
    attributes: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
};

export default ReportingFormsSignaturesCommuneOperator;
