export const relationTypes = {
    /**
     * Asiakas on avustettava. Jos avustettava toimii itse työnantajana tulee hänelle myös
     * työnantaja rooli.
     */
    RELATION_BENEFICIARY: 1,

    /**
     * Asiakas on työnantaja.
     */
    RELATION_EMPLOYER: 2,

    /**
     * Asiakas on työntekijä
     */
    RELATION_EMPLOYEE: 3,

    /**
     * Käyttäjä on kunnan operatttori eli kunnan käyttöliittymän käyttäjä
     */
    RELATION_OPERATOR: 50,

    /**
     * Asiakas on syötetty järjestelmään määrittelemättä roolia
     */
    RELATION_GENERIC: 99,
};

export const communeUserAccessRights = {
    ACCESS_RIGHT_SIGN_CONTRACTS: 'accessRightSignContracts',
    ACCESS_RIGHT_SIGN_REPORTING_FORMS: 'accessRightSignReportingForms',
};

export const communeUserAccessRightsOwnAccount = {
    ACCESS_RIGHT_SIGN_CONTRACTS: 'accessRightSignContracts',
    ACCESS_RIGHT_SIGN_REPORTING_FORMS: 'accessRightSignReportingForms',
    ACCESS_RIGHT_LOCK_PAYMENT: 'accessRightLockPayment',
    ACCESS_RIGHT_CONFIRM_PAYMENT: 'accessRightConfirmPayment',
    ACCESS_RIGHT_BANK_TRANSACTIONS: 'accessRightBankTransactions',
    //ACCESS_RIGHT_TRANSACTION_DETAILS: 'accessRightTransactionDetails',
};

export const communeUserDefaultAccessRights = {
    [communeUserAccessRights.ACCESS_RIGHT_SIGN_CONTRACTS]: false,
};

const relationTypeTransMap = {
    [relationTypes.RELATION_BENEFICIARY]: 'Avustettava',
    [relationTypes.RELATION_EMPLOYER]: 'Työnantaja',
    [relationTypes.RELATION_EMPLOYEE]: 'Työntekijä',
    [relationTypes.RELATION_OPERATOR]: 'Operaattori',
    [relationTypes.RELATION_GENERIC]: 'Yleinen',
};

export function translateCommuneCustomerRelationType(communeCustomerRelationType) {
    return communeCustomerRelationType in relationTypeTransMap ? relationTypeTransMap[communeCustomerRelationType] : 'Tuntematon!';
}
